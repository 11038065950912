import React, { useContext, useState, useRef, useEffect } from "react";
import { Dropdown, Card, Row, Col } from "react-bootstrap";
import { connect, useDispatch, useSelector } from "react-redux";
import { ThemeContext } from "../../context/ThemeContext";

import {
  loadingToggleAction,
  registerUserAction,
} from "../../store/actions/AuthActions";

const RegisterUser = (props) => {
  const [data, setData] = useState(
    document.querySelectorAll(".email-right-box .email-list .message")
  );
  const sort = 8;
  const activePag = useRef(0);
  const [test, settest] = useState(0);

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  let errorsObj = { email: "", password: "" };
  const [errors, setErrors] = useState(errorsObj);
  const [password, setPassword] = useState("");
  const dispatch = useDispatch();
  const [selectedTrack, setSelectedTrack] = useState("Data Science");
  const [selectedBatch, setSelectedBatch] = useState("Batch 1");

  const {
    changeBackground,
    changePrimaryColor,
    changeSecondaryColor,
    chnageSidebarColor,
    chnageHaderColor,
    changeNavigationHader,
  } = useContext(ThemeContext);

  const darkModeData = useSelector((state) => state.one.darkMode);

  useEffect(() => {
    changeBackground({
      value: props.reduxCurrentState.one.darkMode ? "dark" : "light",
      label: "Dark",
    });

    changePrimaryColor("color_11");
    changeSecondaryColor("color_1");
    chnageSidebarColor(
      props.reduxCurrentState.one.darkMode ? "dark" : "color_11"
    );
    changeNavigationHader(
      props.reduxCurrentState.one.darkMode ? "dark" : "color_11"
    );
  }, [darkModeData]);

  // Active data
  const chageData = (frist, sec) => {
    for (var i = 0; i < data.length; ++i) {
      if (i >= frist && i < sec) {
        data[i].classList.remove("d-none");
      } else {
        data[i].classList.add("d-none");
      }
    }
  };

  const handleSelect = (eventKey) => {
    setSelectedTrack(eventKey);
  };

  const handleSelectBatch = (event) => {
    setSelectedBatch(event.target.value);
  };

  const handleSelectTrack = (event) => {
    setSelectedTrack(event.target.value);
  };

  function onSubmit(e) {
    e.preventDefault();
    let error = false;
    const errorObj = { ...errorsObj };
    if (name === "") {
      errorObj.name = "Name is Required";
      error = true;
    }
    if (email === "") {
      errorObj.email = "Email is Required";
      error = true;
    }
    if (password === "") {
      errorObj.password = "Password is Required";
      error = true;
    }

    setErrors(errorObj);
    if (error) {
      return;
    }
    if (props.reduxCurrentState.one.role == "Student") {
      dispatch(loadingToggleAction(true));
      dispatch(
        registerUserAction(
          name,
          email,
          password,
          selectedTrack,
          selectedBatch,
          props.history
        )
      );
    }
  }

  // use effect
  useEffect(() => {
    setData(document.querySelectorAll(".email-right-box .email-list .message"));
    //chackboxFun();
  }, [test]);
  // Active pagginarion
  activePag.current === 0 && chageData(0, sort);
  // paggination
  let paggination = Array(Math.ceil(data.length / sort))
    .fill()
    .map((_, i) => i + 1);

  // Active paggination & chage data
  const onClick = (i) => {
    activePag.current = i;
    chageData(activePag.current * sort, (activePag.current + 1) * sort);
    settest(i);
  };

  const chackbox = document.querySelectorAll(".message input");
  const motherChackBox = document.querySelector("#checkbox1");
  const chackboxFun = (type) => {
    for (let i = 0; i < chackbox.length; i++) {
      const element = chackbox[i];
      if (type === "all") {
        if (motherChackBox.checked) {
          element.checked = true;
        } else {
          element.checked = false;
        }
      } else {
        if (!element.checked) {
          motherChackBox.checked = false;
          break;
        } else {
          motherChackBox.checked = true;
        }
      }
    }
  };

  return (
    <>
      {props.reduxCurrentState.one.role == "Student" && (
        <div className="row justify-content-center ">
          <div className="col-0 col-md-6">
            <div className="sign-in-your">
              {props.errorMessage && (
                <div className="bg-red-300 text-red-900 border border-red-900 p-1 my-2">
                  {props.errorMessage}
                </div>
              )}

              {props.successMessage && (
                <div className="bg-green-300 text-green-900 border border-green-900 p-1 my-2">
                  {props.successMessage}
                </div>
              )}

              <form onSubmit={onSubmit}>
                <div className="mb-3">
                  <label className="mb-1">
                    <strong>Name</strong>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Please enter full name"
                    onChange={(e) => setName(e.target.value)}
                  />
                  {errors.name && (
                    <div className="text-danger fs-12">{errors.name}</div>
                  )}
                </div>
                <br></br>

                <div className="mb-3">
                  <label className="mb-1">
                    <strong>Email ID</strong>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Please enter an email ID"
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  {errors.email && (
                    <div className="text-danger fs-12">{errors.email}</div>
                  )}
                </div>
                <br></br>
                <div className="mb-3">
                  <label className="mb-1">
                    <strong>Set Password</strong>
                  </label>
                  <input
                    type="password"
                    className="form-control"
                    placeholder="Set new password"
                    onChange={(e) => setPassword(e.target.value)}
                  />
                  {errors.password && (
                    <div className="text-danger fs-12">{errors.password}</div>
                  )}
                </div>
                <br></br>

                <div className="row">
                  <div className="form-group mb-4 col-md-6">
                    <select
                      id="inputState"
                      className="form-control"
                      value={selectedTrack}
                      onChange={handleSelectTrack}
                    >
                      <option value="Data Science">Data Science</option>
                      <option value="Digital Marketing">Digital Marketing</option>
                      <option value="Cyber Security">Cyber Security</option>
                      <option value="SQL">SQL</option>

                    </select>

                  </div>
                  <div className="form-group mb-4 col-md-6">
                    <select
                      id="inputState"
                      className="form-control"
                      value={selectedBatch}
                      onChange={handleSelectBatch}
                    >
                      <option value="Batch 1">Batch 1</option>
                      <option value="Batch 2">Batch 2</option>
                      <option value="Batch 3">Batch 3</option>
                      <option value="Batch 4">Batch 4</option>
                      <option value="Batch 5">Batch 5</option>
                      <option value="Batch 6">Batch 6</option>
                      <option value="Batch 7">Batch 7</option>
                      <option value="Batch 8">Batch 8</option>
                      <option value="Batch 9">Batch 9</option>
                      <option value="Batch 10">Batch 10</option>
                      <option value="Batch 11">Batch 11</option>
                      <option value="Batch 12">Batch 12</option>
                      <option value="Batch 13">Batch 13</option>

                    </select>
                  </div>
                </div>

                <br></br>

                <div className="text-center">
                  <button type="submit" className="btn btn-primary btn-block">
                    Add New User
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    reduxCurrentState: state,
  };
};

export default connect(mapStateToProps)(RegisterUser);
