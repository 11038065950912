import React, { useContext, useState, useRef, useEffect } from "react";
import { Dropdown, Card, Row, Col } from "react-bootstrap";
import { connect, useDispatch, useSelector } from "react-redux";
import DatePicker from "react-datepicker";
import TimePicker from "react-time-picker";
import Dropzone from "react-dropzone-uploader";
import { ThemeContext } from "../../context/ThemeContext";
import swal from "sweetalert";

import {
  loadingToggleAction,
  registerUserAction,
} from "../../store/actions/AuthActions";

import "react-dropzone-uploader/dist/styles.css";
import Drop from "react-dropzone-uploader";

import Select from "react-select";

const colourOptions = [
  { value: "Beginner", label: "Beginner" },
  { value: "Data", label: "Data" },
  { value: "Programming", label: "Programming" },
  { value: "Analysis", label: "Analysis" },
  { value: "Understanding", label: "Understanding" },
  { value: "Exploration", label: "Exploration" },
  { value: "Mid level", label: "Mid level" },
  { value: "Easy", label: "Easy" },
];

const tags = [
  "Beginner",
  "Data",
  "Programming",
  "Analysis",
  "Understanding",
  "Exploration",
  "Mid level",
  "Easy",
];

const CustomClearText = () => "clear all";
const ClearIndicator = (props) => {
  const {
    children = <CustomClearText />,
    getStyles,
    innerProps: { ref, ...restInnerProps },
  } = props;
  return (
    <div
      {...restInnerProps}
      ref={ref}
      style={getStyles("clearIndicator", props)}
    >
      <div style={{ padding: "0px 5px" }}>{children}</div>
    </div>
  );
};

const ClearIndicatorStyles = (base, state) => ({
  ...base,
  cursor: "pointer",
  color: state.isFocused ? "blue" : "black",
});

function CustomClearIndicator({ onTagsChange }) {
  const [selectedTags, setSelectedTags] = useState([]);

  const handleTagChange = (selectedOptions) => {
    setSelectedTags(selectedOptions);

    onTagsChange(selectedOptions);
  };

  return (
    <Select
      closeMenuOnSelect={false}
      components={{ ClearIndicator }}
      styles={{ clearIndicator: ClearIndicatorStyles }}
      value={selectedTags}
      onChange={handleTagChange}
      isMulti
      options={colourOptions}
    />
  );
}

const AddAssignment = (props) => {
  const [data, setData] = useState(
    document.querySelectorAll(".email-right-box .email-list .message")
  );
  const sort = 8;
  const activePag = useRef(0);
  const [test, settest] = useState(0);

  const [isLoading, setIsLoading] = useState(false);

  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");

  let errorsObj = { title: "", description: "" };
  const [errors, setErrors] = useState(errorsObj);

  const dispatch = useDispatch();

  const [selectedTrack, setSelectedTrack] = useState("Data Science");
  const [selectedBatch, setSelectedBatch] = useState("All");

  const [maxCoins, setMaxCoins] = useState(5);
  const [level, setLevel] = useState("Easy");
  const [selectedTags, setSelectedTags] = useState([]);

  const handleTagsChange = (selectedTags) => {
    setSelectedTags(selectedTags);
  };

  const {
    changeBackground,
    changePrimaryColor,
    changeSecondaryColor,
    chnageSidebarColor,
    chnageHaderColor,
    changeNavigationHader,
  } = useContext(ThemeContext);

  const darkModeData = useSelector((state) => state.one.darkMode);

  useEffect(() => {
    changeBackground({
      value: props.reduxCurrentState.one.darkMode ? "dark" : "light",
      label: "Dark",
    });

    changePrimaryColor("color_11");
    changeSecondaryColor("color_1");
    chnageSidebarColor(
      props.reduxCurrentState.one.darkMode ? "dark" : "color_11"
    );
    changeNavigationHader(
      props.reduxCurrentState.one.darkMode ? "dark" : "color_11"
    );
  }, [darkModeData]);

  const onClickUploadSubmit = async (e) => {
    e.preventDefault();

    let error = false;
    const errorObj = { ...errorsObj };
    if (title === "") {
      errorObj.title = "Title is Required";
      error = true;
    }
    if (description === "") {
      errorObj.description = "description is Required";
      error = true;
    }

    setErrors(errorObj);
    if (error) {
      return;
    }

    setIsLoading(true);

    try {
      const response = await fetch(
        "https://kmkm84urcd.execute-api.ap-south-1.amazonaws.com/dev/api/",
        {
          method: "post",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            method: "addassignment",
            web_auth_token_id: props.reduxCurrentState.one.webAuthTokenId,
            title: title,
            description: description,
            track: selectedTrack,
            batch: selectedBatch,
            maxCoins: maxCoins,
            tags: selectedTags,
          }),
        }
      );

      const responseJson = await response.json();

      if (!responseJson["error"]) {
        console.log(responseJson);
        swal("Done", responseJson["message"], "success");
        setTitle("");
        setDescription("");
        setIsLoading(false);
      } else {
        const error = responseJson["message"];
        setIsLoading(false);
        swal("Oops", error, "error");
      }
    } catch (error) {
      console.log("request failed", error);
      alert("Backend error, try again or reach out to us.");
    }
  };

  // Active data
  const chageData = (frist, sec) => {
    for (var i = 0; i < data.length; ++i) {
      if (i >= frist && i < sec) {
        data[i].classList.remove("d-none");
      } else {
        data[i].classList.add("d-none");
      }
    }
  };

  const handleSelectBatch = (event) => {
    setSelectedBatch(event.target.value);
  };

  const handleSelectTrack = (event) => {
    setSelectedTrack(event.target.value);
  };

  // use effect
  useEffect(() => {
    setData(document.querySelectorAll(".email-right-box .email-list .message"));
    //chackboxFun();
  }, [test]);
  // Active pagginarion
  activePag.current === 0 && chageData(0, sort);
  // paggination
  let paggination = Array(Math.ceil(data.length / sort))
    .fill()
    .map((_, i) => i + 1);

  // Active paggination & chage data
  const onClick = (i) => {
    activePag.current = i;
    chageData(activePag.current * sort, (activePag.current + 1) * sort);
    settest(i);
  };
  const chackbox = document.querySelectorAll(".message input");
  const motherChackBox = document.querySelector("#checkbox1");
  const chackboxFun = (type) => {
    for (let i = 0; i < chackbox.length; i++) {
      const element = chackbox[i];
      if (type === "all") {
        if (motherChackBox.checked) {
          element.checked = true;
        } else {
          element.checked = false;
        }
      } else {
        if (!element.checked) {
          motherChackBox.checked = false;
          break;
        } else {
          motherChackBox.checked = true;
        }
      }
    }
  };

  const handleTextareaChange = (event) => {
    setDescription(event.target.value);
  };

  const handleMaxCoins = (e) => {
    const selectedValue = parseInt(e.target.value);
    setMaxCoins(selectedValue);
  };

  const handleLevel = (e) => {
    setLevel(e.target.value);
  };

  return (
    <>
      {props.reduxCurrentState.one.role == "Student" && (
        <div className="row justify-content-center ">
          <div className="col-0 col-md-6">
            <div className="sign-in-your">
              {props.errorMessage && (
                <div className="bg-red-300 text-red-900 border border-red-900 p-1 my-2">
                  {props.errorMessage}
                </div>
              )}

              {props.successMessage && (
                <div className="bg-green-300 text-green-900 border border-green-900 p-1 my-2">
                  {props.successMessage}
                </div>
              )}

              <form onSubmit={onClickUploadSubmit}>
                <div className="mb-3">
                  <label className="mb-1">
                    <strong>Title</strong>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Please enter title"
                    onChange={(e) => setTitle(e.target.value)}
                    value={title}
                  />
                  {errors.title && (
                    <div className="text-danger fs-12">{errors.title}</div>
                  )}
                </div>

                <div className="mb-3">
                  <label className="mb-1">
                    <strong>Description</strong>
                  </label>

                  <textarea
                    className="form-control"
                    rows="3"
                    columns="20"
                    id="comment"
                    placeholder="Please add description"
                    value={description} // Set the value to the state variable
                    onChange={handleTextareaChange} // Handle changes
                  ></textarea>
                  {errors.description && (
                    <div className="text-danger fs-12">
                      {errors.description}
                    </div>
                  )}
                </div>

                <div className="row">
                  <div className="form-group mb-4 col-md-6">
                    <label className="mb-1">
                      <strong>Max coins</strong>
                    </label>
                    <select
                      className="form-control"
                      id="sel1"
                      value={maxCoins}
                      onChange={handleMaxCoins}
                    >
                      {" "}
                      <option value={5}>5</option>
                      <option value={10}>10</option>
                      <option value={15}>15</option>
                      <option value={20}>20</option>
                      <option value={30}>30</option>
                      <option value={40}>40</option>
                    </select>
                  </div>
                  <div className="form-group mb-4 col-md-6">
                    <label className="mb-1">
                      <strong>Level</strong>
                    </label>
                    <select
                      className="form-control"
                      id="sel1"
                      value={level}
                      onChange={handleLevel}
                    >
                      {" "}
                      <option value={"Easy"}>Easy</option>
                      <option value={"Medium"}>Medium</option>
                      <option value={"Hard"}>Hard</option>
                    </select>
                  </div>
                </div>

                <br></br>

                <div className="row">
                  <div className="form-group mb-4 col-md-6">
                    <select
                      id="inputState"
                      className="form-control"
                      value={selectedTrack}
                      onChange={handleSelectTrack}
                    >
                      <option value="Data Science">Data Science</option>
                      <option value="Data Analytics">Data Analytics</option>
                    </select>
                  </div>
                  <div className="form-group mb-4 col-md-6">
                    <select
                      id="inputState"
                      className="form-control"
                      value={selectedBatch}
                      onChange={handleSelectBatch}
                    >
                      <option value="All">All</option>
                      <option value="Batch 1">Batch 1</option>
                      <option value="Batch 2">Batch 2</option>
                      <option value="Batch 3">Batch 3</option>
                      <option value="Batch 4">Batch 4</option>
                    </select>
                  </div>
                </div>

                <div className="mt-4 pb-5">
                  <label className="mb-1">
                    <strong>Tags</strong>
                  </label>
                  <CustomClearIndicator onTagsChange={handleTagsChange} />
                </div>
                <br></br>
                {isLoading ? (
                  <div className="text-center">
                    <div
                      className="spinner-border text-primary text-center"
                      role="status"
                    >
                      <span className="sr-only">Loading...</span>
                    </div>
                  </div>
                ) : (
                  <button type="submit" className="btn btn-primary btn-block">
                    Submit
                  </button>
                )}
              </form>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    reduxCurrentState: state,
  };
};

export default connect(mapStateToProps)(AddAssignment);
