import React, { useContext, useState, useEffect, useRef } from "react";
import "bootstrap-daterangepicker/daterangepicker.css";
import "react-datepicker/dist/react-datepicker.css";
import { getDatasets } from "../../store/actions/AuthActions";
import { Row, Col, Card, Tab, Nav } from "react-bootstrap";

import { Link } from "react-router-dom";

import acheiv from "../../images/svg/achievement.svg";
import medal from "../../images/medal.png";
import linkedin from "./../../images/Linkedin.png";
import kaggle from "./../../images/Kaggle.png";
import { connect, useDispatch, useSelector } from "react-redux";

import { Viewer } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";
import { Worker } from "@react-pdf-viewer/core";

import { ThemeContext } from "../../context/ThemeContext";
import ML from "./../../images/ML.png";

import FilteringTable from "../pages/components/FilteringTable/FilteringTable";

const Playground = (props) => {
  const [showPdf, setShowPdf] = useState(false);
  const [showHtml, setShowHtml] = useState(false);
  const [currentPdf, setCurrentPdf] = useState(null);
  const [currentHtml, setCurrentHtml] = useState(null);
  const [isHtmlLoading, setIsHtmlLoading] = useState(false);

  const [isLoading, setIsLoading] = useState(true);
  const [activeToggle, setActiveToggle] = useState("aboutMe");
  const [showData, setShowData] = useState(false);
  const [itemData, setItemData] = useState(null);

  const dispatch = useDispatch();
  const iframeRef = useRef(null);

  const {
    changeBackground,
    changePrimaryColor,
    changeSecondaryColor,
    chnageSidebarColor,
    chnageHaderColor,
    changeNavigationHader,
  } = useContext(ThemeContext);

  const darkModeData = useSelector((state) => state.one.darkMode);

  useEffect(() => {
    changeBackground({
      value: props.reduxCurrentState.one.darkMode ? "dark" : "light",
      label: "Dark",
    });

    changePrimaryColor("color_11");
    changeSecondaryColor("color_1");
    chnageSidebarColor(
      props.reduxCurrentState.one.darkMode ? "dark" : "color_11"
    );
    changeNavigationHader(
      props.reduxCurrentState.one.darkMode ? "dark" : "color_11"
    );
  }, [darkModeData]);

  useEffect(() => {
    dispatch(getDatasets(props.reduxCurrentState.one.webAuthTokenId));
  }, []);

  const underlineStyle1 = {
    textDecoration: "underline",
    cursor: "pointer",
    color: "white",
  };

  const tabStyle = {
    display: "inline-block",
    padding: "10px 20px",
    borderRadius: "20px 20px 0 0",
    cursor: "pointer",
  };

  const selectedTabStyle = {
    ...tabStyle,
    backgroundColor: "#3a9b7e", // Color for the selected tab
  };

  const unselectedTabStyle = {
    ...tabStyle,
    backgroundColor: "#e0e0e0", // Color for the unselected tab
  };

  const onPressViewHtmlCode = (htmlLink) => {
    window.scrollTo({
      top: 0,
      behavior: "auto", // You can use 'auto' for an immediate scroll
    });
    setShowHtml(true);
    setCurrentHtml(htmlLink);
  };

  const onPressExplore = (item) => {
    setShowData(true);
    setItemData(item);
  };

  const goBack = () => {
    setShowData(false);
    setItemData(null);
  };

  return (
    <>
      <div className="row">
        {showData ? (
          <>
            <div className="row">
              <div className="d-flex align-items-center">
                <p onClick={goBack} style={underlineStyle1}>
                  Go Back
                </p>
              </div>

              <FilteringTable data={itemData}></FilteringTable>
            </div>
          </>
        ) : (
          <>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <h3 className="m-0">EDA Datasets</h3>
            </div>
            <div>
              <br></br>
            </div>

            {props.reduxCurrentState.one.edaDatasets &&
              props.reduxCurrentState.one.edaDatasets.map((item, index) => (
                <div className="col-xl-3 col-xxl-3 col-md-3" key={index}>
                  <div className="card instructors-box">
                    <div className="card-body text-center pb-3">
                      <img
                        style={{ borderRadius: "10px" }}
                        src={item.thumbnail}
                        alt=""
                        width={180}
                        height={180}
                      />
                    </div>

                    <div className="Row">
                      <div className="card-footer pt-0 border-0">
                        <div className="col-md-12">
                          <h6>{item.name}</h6>
                          <Link
                            onClick={() => onPressExplore(item)}
                            className="btn btn-secondary btn-block"
                          >
                            Explore
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}

            <br></br>
            <br></br>
            <br></br>
            <hr></hr>

            <br></br>

            <br></br>

            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <h3 className="m-0">Machine Learning Datasets</h3>
            </div>
            <div>
              <br></br>
            </div>

            {props.reduxCurrentState.one.mlDatasets &&
              props.reduxCurrentState.one.mlDatasets.map((item, index) => (
                <div className="col-xl-3 col-xxl-3 col-md-3" key={index}>
                  <div className="card instructors-box">
                    <div className="card-body text-center pb-3">
                      <img
                        style={{ borderRadius: "10px" }}
                        src={item.thumbnail}
                        alt=""
                        width={180}
                        height={180}
                      />
                    </div>

                    <div className="Row">
                      <div className="card-footer pt-0 border-0">
                        <div className="col-md-12">
                          <h6>{item.name}</h6>
                          <Link
                            onClick={() => onPressExplore(item)}
                            className="btn btn-secondary btn-block"
                          >
                            Explore
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
          </>
        )}
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    reduxCurrentState: state,
  };
};

export default connect(mapStateToProps)(Playground);
