import { format } from 'date-fns';
import { ColumnFilter } from './ColumnFilter';

export const COLUMNS = [

  
  {
    Header: 'Name',
    Footer: 'Name',
    accessor: 'name',
    Filter: ColumnFilter,
  },
  {
    Header: 'Phone Number',
    Footer: 'Phone Number',
    accessor: 'whatsapp_no',
    Filter: ColumnFilter,
  },
  {
    Header: 'Technology',
    Footer: 'Technology Interested',
    accessor: 'technology_interested',
    Filter: ColumnFilter,
  },
  {
    Header: 'Lead Source',
    Footer: 'Lead Source',
    accessor: 'lead_source',
    Filter: ColumnFilter,
  },

  {
    Header: 'Created At',
    Footer: 'Created At',
    accessor: 'created_at',
    Filter: ColumnFilter,
  },
  
  
];
