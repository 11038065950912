import React, { useContext, useState, useEffect } from "react";

import { connect, useDispatch, useSelector } from "react-redux";
import "bootstrap-daterangepicker/daterangepicker.css";
import "react-datepicker/dist/react-datepicker.css";
import { getMockTests } from "../../store/actions/AuthActions";

import pic8 from "../../images/courses/pic8.jpg";
import pic7 from "../../images/courses/pic7.jpg";
import pic6 from "../../images/courses/pic6.jpg";
import pic5 from "../../images/courses/pic5.jpg";

import swal from "sweetalert";
import { Link } from "react-router-dom";
import {
  Row,
  Col,
  Card,
  Tab,
  Nav,
  Badge,
  ProgressBar,
  ListGroup,
  Alert,
} from "react-bootstrap";

import { Button, Dropdown, Modal } from "react-bootstrap";
import linkedin from "./../../images/Linkedin.png";
import kaggle from "./../../images/Kaggle.png";

import acheiv from "../../images/svg/achievement.svg";
import medal from "../../images/medal.png";

import Check from "./../../images/vector/check.png";
import Bookmarks from "./../../images/vector/bookmarks.png";
import Bulb from "./../../images/vector/bulb.png";
import { ThemeContext } from "../../context/ThemeContext";

import dairy from "./../../images/treats/dark.png";

import Calpng from "./../../images/vector/calpng.png";
import Book from "./../../images/vector/book.png";
import Educat from "./../../images/p_class.png";

import first from "./../../images/1.png";
import second from "./../../images/2.png";
import third from "./../../images/3.png";

const MockTests = (props) => {
  const [days, setDays] = useState("--");
  const [hours, setHours] = useState("--");
  const [minutes, setMinutes] = useState("--");
  const [seconds, setSeconds] = useState("--");

  const [isCModelShown, setIsCModelShown] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [mockTestUuid, setMockTestUuid] = useState("");

  const [quizQuestions, setQuizQuestions] = useState([]);
  const [currentQuestion, setCurrentQuestion] = useState(null);
  const [questionIndex, setQuestionIndex] = useState(1);
  const [progress, setProgress] = useState(0);
  const [isCorrect, setIsCorrect] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [correctAnswer, setCorrectAnswer] = useState(null);
  const [submitted, setSubmitted] = useState(false);
  const [isModelQuizShown, setIsModelQuizShown] = useState(false);
  const [isModelLeaderboardShown, setIsModelLeaderboardShown] = useState(false);
  const [leaderboardData, setLeaderboardData] = useState([]);

  
  const [testMinutes, setTestMinutes] = useState("--");
  const [testSeconds, setTestSeconds] = useState("--");

  const dispatch = useDispatch();

  const yourText = props.reduxCurrentState.one.nextClass.class_title;
  const textParts = yourText.split(":");

  const {
    changeBackground,
    changePrimaryColor,
    changeSecondaryColor,
    chnageSidebarColor,
    chnageHaderColor,
    changeNavigationHader,
  } = useContext(ThemeContext);

  const darkModeData = useSelector((state) => state.one.darkMode);

  const reduxStateData = useSelector((state) => state.one.mockTests);

  useEffect(() => {
    changeBackground({
      value: props.reduxCurrentState.one.darkMode ? "dark" : "light",
      label: "Dark",
    });

    changePrimaryColor("color_11");
    changeSecondaryColor("color_1");
    chnageSidebarColor(
      props.reduxCurrentState.one.darkMode ? "dark" : "color_11"
    );
    changeNavigationHader(
      props.reduxCurrentState.one.darkMode ? "dark" : "color_11"
    );

    const updateTime = () => {
      if (!props.reduxCurrentState.one.mockTests.upcomingMockTests) {
        return;
      }
      const newYear =
        props.reduxCurrentState.one.mockTests.upcomingMockTests[0].start_at;

      const targetDate = new Date(newYear);
      targetDate.setTime(targetDate.getTime());

      const currentDate = new Date();
      const diff = targetDate - currentDate;
      if (diff <= 0) {
        clearInterval(interval);
        setDays("00");
        setHours("00");
        setMinutes("00");
        setSeconds("00");
        return;
      }

      const days = Math.floor(diff / (1000 * 60 * 60 * 24));
      const hours = Math.floor(
        (diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((diff % (1000 * 60)) / 1000);

      setDays(days < 10 ? "0" + days : days);
      setHours(hours < 10 ? "0" + hours : hours);
      setMinutes(minutes < 10 ? "0" + minutes : minutes);
      setSeconds(seconds < 10 ? "0" + seconds : seconds);
    };

    const interval = setInterval(updateTime, 1000);

    return () => clearInterval(interval);
  }, [reduxStateData, darkModeData]);

  useEffect(() => {
    const updateTime = () => {
      if (!props.reduxCurrentState.one.mockTests.upcomingMockTests) {
        return;
      }
      const newYear =
        props.reduxCurrentState.one.mockTests.upcomingMockTests[0].end_at;

      const targetDate = new Date(newYear);
      targetDate.setTime(targetDate.getTime());

      const currentDate = new Date();
      const diff = targetDate - currentDate;
      if (diff <= 0) {
        clearInterval(interval);

        setTestMinutes("00");
        setTestSeconds("00");
        return;
      }

      const days = Math.floor(diff / (1000 * 60 * 60 * 24));
      const hours = Math.floor(
        (diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((diff % (1000 * 60)) / 1000);

      setTestMinutes(minutes < 10 ? "0" + minutes : minutes);
      setTestSeconds(seconds < 10 ? "0" + seconds : seconds);
    };

    const interval = setInterval(updateTime, 1000);

    return () => clearInterval(interval);
  }, [reduxStateData]);

  useEffect(() => {
    dispatch(getMockTests(props.reduxCurrentState.one.webAuthTokenId));
  }, []);

  const onPressViewMyScore = (my_score_data) => {
    if (my_score_data.test_taken) {
      swal(my_score_data.total_score, "Your score", "success");
    } else {
      swal("Oops", "Your did not take this test", "error");
    }
  };

  const MockTestComponent = ({ mockTests }) => {
    return (
      <div>
        <div className="d-flex justify-content-center align-items-center">
          <h5 className="m-0">Past Mock Tests </h5>
        </div>

        {mockTests.map((item, ind) => (
          <div
            key={ind}
            style={{
              border: "1px solid #ccc",
              borderRadius: "8px",
              marginBottom: "15px",
              padding: "15px",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              borderColor: item.cardClass === "upcoming" ? "#63c2de" : "#ccc",
            }}
          >
            <div style={{ flexGrow: 1, marginLeft: "15px" }}>
              <h3>{item.title}</h3>
              <p>
                <strong>Duration:</strong> {item.duration} |{" "}
                <strong>Total Questions:</strong> {item.total_questions}
              </p>
              <p>
                <strong>Ended at:</strong> {item.end_at}
              </p>

              {/* <p>
                <strong>Total People Taken:</strong> 10
              </p> */}
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-end",
              }}
            >
              <button
                onClick={() => onPressViewMyScore(item.my_score_data)}
                style={{
                  width: "150px", // Set a fixed width for both buttons
                  backgroundColor: "#000",
                  color: "#fff",
                  padding: "5px 10px",
                  borderRadius: "5px",
                  marginBottom: "5px",
                }}

                // onClick={() => handleViewScore(item)}
              >
                View My Score
              </button>
              <button
                style={{
                  width: "150px", // Set a fixed width for both buttons
                  backgroundColor: "#000",
                  color: "#fff",
                  padding: "5px 10px",
                  borderRadius: "5px",
                }}
                onClick={() => handleViewLeaderboard(item.leaderboard_data)}
              >
                View Leaderboard
              </button>
            </div>
          </div>
        ))}
      </div>
    );
  };

  const handleViewScore = (item) => {
    // Add logic to handle "View My Score" button click
    console.log(`Viewing score for ${item.title}`);
  };

  const handleViewLeaderboard = (leaderboardData) => {
    setIsModelLeaderboardShown(true);
    setLeaderboardData(leaderboardData)
  };

  const counterStyle = {
    display: "flex",
    border: "2px solid rgba(0,0,0,1)",
    padding: "10px",
    borderRadius: 10,
    borderColor: "#b69926",
  };

  const boxStyle = {
    width: "80px",
    height: "80px",
    textAlign: "center",
    marginRight: "10px",
    position: "relative",
  };

  const h2Style = {
    fontSize: "2.5rem",
    fontWeight: "500",
    color: "#b69926",
  };

  const learningData = [
    { title: "Read each question carefully before answering." },
    { title: "Choose the most appropriate option from the given choices." },
    { title: "There is only one correct answer for each question." },
    {
      title:
        "Be mindful of the time limit. Manage your time wisely to attempt all questions.",
    },
    {
      title:
        "Your test gets saved automatically so no worries in case of restart.",
    },
  ];

  const scoringData = [
    { title: "Each question carries one point." },
    {
      title:
        "There is no negative marking, so feel free to attempt all questions.",
    },
  ];

  const onPressTakeTest = (mockTestUuid) => {
    // check if current time is > test time

    const newYear =
      props.reduxCurrentState.one.mockTests.upcomingMockTests[0].start_at;

    const targetDate = new Date(newYear);

    const currentDate = new Date();

    if (currentDate >= targetDate) {
      setIsCModelShown(true);
      setMockTestUuid(mockTestUuid);

      setQuizQuestions([]);
      setCurrentQuestion(null);
      setQuestionIndex(1);
      setProgress(0);
      setIsCorrect(false);
      setSelectedOption(null);
      setCorrectAnswer(null);
      setSubmitted(false);
    } else {
      swal("Oops", "Test cannot be taken until the start date.", "error");
    }
  };

  const handleOptionSelect = (option) => {
    if (submitted) {
      return;
    }
    setSelectedOption(option);
  };

  const onPressSkipQuestion = () => {
    if (questionIndex < quizQuestions.length) {
      setCurrentQuestion(quizQuestions[questionIndex]);
      setQuestionIndex(questionIndex + 1);
      setSelectedOption(null);
      setProgress(((questionIndex + 1) / quizQuestions.length) * 100);
      setSubmitted(false);
    } else {
      swal(
        "Done",
        "Your test has been completed. Results will be OUT soon.",
        "success"
      );
      setIsModelQuizShown(false);
    }
  };

  const handleSubmission = () => {
    if (selectedOption && currentQuestion) {
      sumbitMockTestAnswer();
    } else {
      swal("Oops", "Please choose an option", "error");
    }
  };

  const onClickGo = () => {
    fetchMockTestMcqs();
  };

  function fetchMockTestMcqs() {
    setIsLoading(true);

    fetch("https://kmkm84urcd.execute-api.ap-south-1.amazonaws.com/dev/api/", {
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        method: "getmocktestmcqs",
        web_auth_token_id: props.reduxCurrentState.one.webAuthTokenId,
        mock_test_id: mockTestUuid,
      }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (!responseJson["error"]) {
          const quizQuestions = responseJson["quiz_questions"];
          setIsLoading(false);
          setIsCModelShown(false);
          setQuizQuestions(quizQuestions);

          if (quizQuestions.length > 0) {
            setCurrentQuestion(quizQuestions[0]);
            setIsModelQuizShown(true);
            setProgress((questionIndex / quizQuestions.length) * 100);
          } else {
            swal("Oops", "You have already taken the test.", "error");
          }
        } else {
          const error = responseJson["message"];
          swal("Oops", error, "error");
          setIsLoading(false);

          setIsCModelShown(false);
        }
      })
      .catch((error) => {
        console.log("request failed", error);
        alert("Backend error, try again or reach out to us.");
      });
  }

  function sumbitMockTestAnswer() {
    fetch("https://kmkm84urcd.execute-api.ap-south-1.amazonaws.com/dev/api/", {
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        method: "submitmocktestanswer",
        web_auth_token_id: props.reduxCurrentState.one.webAuthTokenId,
        mock_test_id: mockTestUuid,
        mcq_question_id: currentQuestion.uuid,
        user_answer: selectedOption,
      }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (!responseJson["error"]) {
          onPressSkipQuestion();

          const isSubmitted = responseJson["is_sumbitted"];

          if (isSubmitted) {
            const totalScore = responseJson["total_score"];
            var message = "Your test completed. Your Score : " + totalScore;
            swal("Done", message, "success");
            setIsModelQuizShown(false);
          } else {
            onPressSkipQuestion();
          }
        } else {
          const error = responseJson["message"];
          swal("Oops", error, "error");
          setIsModelQuizShown(false);
        }
      })
      .catch((error) => {
        console.log("request failed", error);
        alert("Backend error, try again or reach out to us.");
      });
  }

  const renderModelQuiz = () => {
    return (
      <Modal
        className="fade bd-example-modal-lg"
        show={isModelQuizShown}
        size="lg"
      >
        <Modal.Header>
          <Modal.Title>Question {questionIndex}</Modal.Title>

          <div style={{ textAlign: "center" }}>
            Time Left:
            <h2 style={{ margin: "auto" }}>
              {testMinutes} : {testSeconds}
            </h2>
          </div>
        </Modal.Header>
        <Card.Body>
          <ProgressBar now={progress} variant="secondary" />
        </Card.Body>
        {currentQuestion && (
          <Modal.Body>
            <Col xl="12">
              <Card>
                <Card.Body>
                  <h5>{currentQuestion.question}</h5>
                </Card.Body>
              </Card>
            </Col>

            <Col xl="12">
              <Card>
                <Card.Body>
                  <div className="basic-list-group">
                    <ListGroup>
                      <ListGroup.Item
                        action
                        active={selectedOption === "A"}
                        style={
                          submitted && correctAnswer === "A"
                            ? { color: "white", backgroundColor: "#56c760" }
                            : {}
                        }
                        onClick={() => handleOptionSelect("A")}
                      >
                        A) {currentQuestion.A}
                      </ListGroup.Item>

                      <ListGroup.Item
                        action
                        active={selectedOption === "B"}
                        style={
                          submitted && correctAnswer === "B"
                            ? { color: "white", backgroundColor: "#56c760" }
                            : {}
                        }
                        onClick={() => handleOptionSelect("B")}
                        // disabled={submitted}
                      >
                        B) {currentQuestion.B}
                      </ListGroup.Item>
                      <ListGroup.Item
                        action
                        active={selectedOption === "C"}
                        style={
                          submitted && correctAnswer === "C"
                            ? { color: "white", backgroundColor: "#56c760" }
                            : {}
                        }
                        onClick={() => handleOptionSelect("C")}
                        // disabled={submitted}
                      >
                        C) {currentQuestion.C}
                      </ListGroup.Item>

                      <ListGroup.Item
                        action
                        active={selectedOption === "D"}
                        style={
                          submitted && correctAnswer === "D"
                            ? { color: "white", backgroundColor: "#56c760" }
                            : {}
                        }
                        onClick={() => handleOptionSelect("D")}
                        // disabled={submitted}
                      >
                        D) {currentQuestion.D}
                      </ListGroup.Item>
                    </ListGroup>
                  </div>
                </Card.Body>
              </Card>
            </Col>

            <div className="Row">
              <div className="card-footer pt-0 border-0">
                <div className="row">
                  <div className="col-md-4"></div>

                  <div className="col-md-4">
                    <a
                      onClick={() => handleSubmission()}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="btn btn-warning btn-block"
                    >
                      Submit Answer
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
        )}
      </Modal>
    );
  };

  const renderConfirmation = () => {
    return (
      <Modal className="fade bd-example-modal" show={isCModelShown}>
        <Modal.Header>
          <Modal.Title>Confirmation</Modal.Title>
          <Button
            onClick={() => setIsCModelShown(false)}
            variant=""
            className="btn-close"
          ></Button>
        </Modal.Header>
        <Modal.Body
          style={{
            display: "flex",
            justifyContent: "center",
            // alignItems: "center",
          }}
        >
          <div className="form-group mb-12 col-md-12">
            <h3>You are about to start the test 🚀</h3>
            <p>Keep your eyes on the clock 🕐 during the test. </p>
          </div>
        </Modal.Body>

        {isLoading ? (
          <Modal.Footer>
            <div class="spinner-border text-primary" role="status">
              <span class="sr-only">Loading...</span>
            </div>
          </Modal.Footer>
        ) : (
          <Modal.Footer>
            <Button
              onClick={() => setIsCModelShown(false)}
              variant="dark light"
            >
              Cancel
            </Button>

            <Button onClick={() => onClickGo()} variant="secondary">
              Let's Go
            </Button>
          </Modal.Footer>
        )}
      </Modal>
    );
  };


  const renderModelLeaderboard = () => {
    return (
      <Modal
        className="fade bd-example-modal-lg"
        show={isModelLeaderboardShown}
        size="lg"
      >
        <Modal.Header>
          <Modal.Title>Leaderboard</Modal.Title>
          <Button
            variant=""
            className="btn-close"
            onClick={() => setIsModelLeaderboardShown(false)}
          ></Button>
        </Modal.Header>

        <Modal.Body>
          <div className="col-xl-13 col-sm-12">
            <div className="card">
              <div className="card-body pt-0">
                {leaderboardData.map((item, ind) => (
                  <div>
                    {/* {ind > 2 && ( */}
                    <div className="upcomming-task-widget d-flex" key={ind}>
                      <div className="widget-column rank">
                        {ind === 0 ? (
                          <img
                            src={first}
                            alt={`Image1`}
                            className="img-fluid"
                          />
                        ) : ind === 1 ? (
                          <img
                            src={second}
                            alt={`Image2`}
                            className="img-fluid"
                          />
                        ) : ind === 2 ? (
                          <img
                            src={third}
                            alt={`Image3`}
                            className="img-fluid"
                          />
                        ) : (
                          <div>
                            <span className="fs-12">Rank</span>
                            <h5>{ind + 1}</h5>
                          </div>
                        )}
                      </div>

                      <div className="widget-column info">
                        <img src={item.pfp} alt="" />
                        <div>
                          <h5>{item.first_name}</h5>
                          <span>Roll No: {item.roll_no}</span>
                        </div>
                      </div>

                      <div className="widget-column coins">
                        <h5 className="font-w600 text-center">{item.total_score}</h5>
                        <span>Score</span>
                      </div>

                      <div className="widget-column projects">
                        <h5 className="font-w600 text-center">{item.batch_name}</h5>
                        <span>Batch</span>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    );
  };

  return (
    <>
      <div style={{ marginTop: "-25px" }}>
        <div className="d-flex justify-content-center align-items-center">
          <h5 className="m-0">Next Mock-Test starts in </h5>
        </div>

        <div
          className="widget-heading d-flex justify-content-center align-items-center"
          style={{ marginTop: 10 }}
        >
          <div style={counterStyle}>
            <div style={boxStyle}>
              <h2 id="days" style={h2Style}>
                {days}
              </h2>
              <small>Days</small>
            </div>
            <div style={boxStyle}>
              <h2 id="hours" style={h2Style}>
                {hours}
              </h2>
              <small>Hours</small>
            </div>
            <div style={boxStyle}>
              <h2 id="minutes" style={h2Style}>
                {minutes}
              </h2>
              <small>Minutes</small>
            </div>
            <div style={boxStyle}>
              <h2 id="seconds" style={h2Style}>
                {seconds}
              </h2>
              <small>Seconds</small>
            </div>
          </div>
        </div>
      </div>
      <div className="col-xl-20 col-xxl-20">
        <div className="row">
          {props.reduxCurrentState.one.mockTests.upcomingMockTests &&
          props.reduxCurrentState.one.mockTests.upcomingMockTests[0] ? (
            <div className="col-xl-12 col-xxl-12">
              <div className="widget-heading d-flex justify-content-between align-items-center">
                <h6 className="m-0">Next Mock Test</h6>
              </div>{" "}
              <div className="card dlab-join-card2 h-auto">
                <div className="card-body">
                  <div className="dlab-media d-flex justify-content-between">
                    <div className="dlab-content">
                      <h4>
                        {
                          props.reduxCurrentState.one.mockTests
                            .upcomingMockTests[0].title
                        }
                      </h4>

                      <p>
                        <strong>Total Questions:</strong>{" "}
                        {
                          props.reduxCurrentState.one.mockTests
                            .upcomingMockTests[0].total_questions
                        }{" "}
                        | <strong>Duration:</strong>{" "}
                        {
                          props.reduxCurrentState.one.mockTests
                            .upcomingMockTests[0].duration
                        }
                      </p>
                      <p>
                        <strong>Starts at:</strong>{" "}
                        {
                          props.reduxCurrentState.one.mockTests
                            .upcomingMockTests[0].start_at
                        }
                      </p>
                    </div>

                    <div className="dlab-img">
                      <img src={Educat} alt="" height={350} />
                    </div>

                    <div className="dlab-icon">
                      <img src={Calpng} alt="" className="cal-img" />
                      <img src={Book} alt="" className="book-img" />
                    </div>
                  </div>

                  <div>
                    <a
                      onClick={() =>
                        onPressTakeTest(
                          props.reduxCurrentState.one.mockTests
                            .upcomingMockTests[0].uuid
                        )
                      }
                      target="_blank"
                      style={{ backgroundColor: "#374557", color: "#ffffff" }}
                      className="btn  btn-sm"
                    >
                      Start Test
                    </a>
                  </div>
                </div>
              </div>
            </div>
          ) : null}
        </div>
      </div>

      <br></br>
      <br></br>

      <div>
        <p>
          The test consists of 30 questions that cover a range of topics and it
          is designed to assess your proficiency & skills.
        </p>
      </div>

      <div className="course-learn">
        <div className="row" style={{ display: "flex", flexDirection: "row" }}>
          <div style={{ marginRight: "20px" }}>
            <h4>Instructions:</h4>
            <ul style={{ listStyleType: "none", padding: 0 }}>
              {learningData.map((item, index) => (
                <li
                  key={index}
                  style={{
                    display: "block",
                    marginBottom: "-1px",
                    clear: "both",
                  }}
                >
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <svg
                      width="16"
                      height="17"
                      viewBox="0 0 16 17"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clipPath="url(#clip0_1082_8)">
                        <path
                          d="M8 0.5C6.41775 0.5 4.87104 0.969192 3.55544 1.84824C2.23985 2.72729 1.21447 3.97672 0.608967 5.43853C0.00346629 6.90034 -0.15496 8.50887 0.153721 10.0607C0.462403 11.6126 1.22433 13.038 2.34315 14.1569C3.46197 15.2757 4.88743 16.0376 6.43928 16.3463C7.99113 16.655 9.59966 16.4965 11.0615 15.891C12.5233 15.2855 13.7727 14.2602 14.6518 12.9446C15.5308 11.629 16 10.0822 16 8.5C16 6.37827 15.1571 4.34344 13.6569 2.84315C12.1566 1.34285 10.1217 0.5 8 0.5V0.5ZM12.5467 6.57L7.52667 12.2633C7.44566 12.3546 7.34742 12.429 7.23759 12.4822C7.12776 12.5354 7.00851 12.5663 6.88667 12.5733H6.82667C6.59929 12.5747 6.3795 12.4916 6.21 12.34L3.52334 9.95667C3.33857 9.79267 3.22652 9.562 3.21182 9.31539C3.19713 9.06878 3.28101 8.82643 3.445 8.64167C3.609 8.4569 3.83967 8.34484 4.08628 8.33015C4.33289 8.31546 4.57524 8.39934 4.76 8.56333L6.76 10.3267L11.1633 5.32667C11.3269 5.14146 11.5573 5.0288 11.8039 5.01349C12.0505 4.99817 12.2931 5.08145 12.4783 5.245C12.6635 5.40855 12.7762 5.63898 12.7915 5.88559C12.8068 6.1322 12.7236 6.37479 12.56 6.56L12.5467 6.57Z"
                          fill="#4CBC9A"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip00_1082_8">
                          <rect
                            width="16"
                            height="16"
                            fill="white"
                            transform="translate(0 0.5)"
                          />
                        </clipPath>
                      </defs>
                    </svg>
                    {item.title}
                  </div>
                </li>
              ))}
            </ul>
          </div>

          <div>
            <br></br>
          </div>

          <div>
            <h4>Scoring:</h4>
            <ul style={{ listStyleType: "none", padding: 0 }}>
              {scoringData.map((item, index) => (
                <li
                  key={index}
                  style={{
                    display: "block",
                    marginBottom: "-1px",
                    clear: "both",
                  }}
                >
                  <div style={{ display: "flex", alignItems: "center" }}>
                    {/* Adjust the SVG for scoringData */}
                    <svg
                      width="16"
                      height="17"
                      viewBox="0 0 16 17"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clipPath="url(#clip0_1082_8)">
                        <path
                          d="M8 0.5C6.41775 0.5 4.87104 0.969192 3.55544 1.84824C2.23985 2.72729 1.21447 3.97672 0.608967 5.43853C0.00346629 6.90034 -0.15496 8.50887 0.153721 10.0607C0.462403 11.6126 1.22433 13.038 2.34315 14.1569C3.46197 15.2757 4.88743 16.0376 6.43928 16.3463C7.99113 16.655 9.59966 16.4965 11.0615 15.891C12.5233 15.2855 13.7727 14.2602 14.6518 12.9446C15.5308 11.629 16 10.0822 16 8.5C16 6.37827 15.1571 4.34344 13.6569 2.84315C12.1566 1.34285 10.1217 0.5 8 0.5V0.5ZM12.5467 6.57L7.52667 12.2633C7.44566 12.3546 7.34742 12.429 7.23759 12.4822C7.12776 12.5354 7.00851 12.5663 6.88667 12.5733H6.82667C6.59929 12.5747 6.3795 12.4916 6.21 12.34L3.52334 9.95667C3.33857 9.79267 3.22652 9.562 3.21182 9.31539C3.19713 9.06878 3.28101 8.82643 3.445 8.64167C3.609 8.4569 3.83967 8.34484 4.08628 8.33015C4.33289 8.31546 4.57524 8.39934 4.76 8.56333L6.76 10.3267L11.1633 5.32667C11.3269 5.14146 11.5573 5.0288 11.8039 5.01349C12.0505 4.99817 12.2931 5.08145 12.4783 5.245C12.6635 5.40855 12.7762 5.63898 12.7915 5.88559C12.8068 6.1322 12.7236 6.37479 12.56 6.56L12.5467 6.57Z"
                          fill="#4CBC9A"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip00_1082_8">
                          <rect
                            width="16"
                            height="16"
                            fill="white"
                            transform="translate(0 0.5)"
                          />
                        </clipPath>
                      </defs>
                    </svg>
                    {item.title}
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>

      <div>
        <br></br>
        <p>Good luck!</p>
      </div>

      <div className="row">
        <div className="col-xl-12">
          {/* {mockTests.map((item, ind) => (
            <>
              <div
                className="card-schedule"
                key={ind}
                style={{
                  border: "1px solid #ccc",
                  borderRadius: "8px",
                  marginBottom: "15px",
                  padding: "15px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  borderColor:
                    item.cardClass === "upcoming" ? "#63c2de" : "#ccc",
                }}
              >
                <div style={{ flexGrow: 1, marginLeft: "15px" }}>
                  <h3>{item.title}</h3>
                  <p>
                    <strong>Total Questions:</strong> {item.totalQuestions} |{" "}
                    <strong>Duration:</strong> {item.duration}
                  </p>
                  <p>
                    <strong>Starts at:</strong> {item.startAt}
                  </p>
                </div>
                <div style={{ padding: "10px" }}>
                  <span
                    style={{
                      backgroundColor: "#28a745",
                      color: "#fff",
                      padding: "5px 10px",
                      borderRadius: "5px",
                      fontSize: "12px",
                    }}
                  >
                    Upcoming
                  </span>
                </div>
              </div>
            </>
          ))} */}

          {props.reduxCurrentState.one.mockTests.pastMockTests.length > 0 && (
            <MockTestComponent
              mockTests={props.reduxCurrentState.one.mockTests.pastMockTests}
            />
          )}
        </div>
      </div>

      {renderConfirmation()}
      {renderModelQuiz()}
      {renderModelLeaderboard()}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    reduxCurrentState: state,
  };
};

export default connect(mapStateToProps)(MockTests);
