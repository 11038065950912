import { ColumnFilter } from './ColumnFilter';

export const generateColumns = (csvHeaders) => {
	return csvHeaders.map((header) => ({
	  Header: header,
	  Footer: header,
	  accessor: header,
	  Filter: ColumnFilter,
	}));
  };
  
  // Sample usage
  // Assume csvHeaders is an array of header names fetched from the CSV file
  const csvHeaders = ['Loading', 'Please', 'Wait', '....'];
  export const COLUMNS = generateColumns(csvHeaders);





// import {format} from 'date-fns';
// import { ColumnFilter } from './ColumnFilter';
// export const COLUMNS = [
// 	{
// 		Header : 'Id',
// 		Footer : 'Id',
// 		accessor: 'id',
// 		Filter: ColumnFilter,
// 		//disableFilters: true,
// 	},
// 	{
// 		Header : 'First Name',
// 		Footer : 'First Name',
// 		accessor: 'first_name',
// 		Filter: ColumnFilter,
// 	},
// 	{
// 		Header : 'Last Name',
// 		Footer : 'Last Name',
// 		accessor: 'last_name',
// 		Filter: ColumnFilter,
// 	},
// 	{
// 		Header : 'Email Id',
// 		Footer : 'Email Id',
// 		accessor: 'email',
// 		Filter: ColumnFilter,
// 	},
// 	{
// 		Header : 'Date of  Birth',
// 		Footer : 'Date of  Birth',
// 		accessor: 'date_of_birth',
// 		Cell: ({ value }) => {return format(new Date(value), 'dd/mm/yyyy')},
// 		Filter: ColumnFilter,
// 	},
// 	{
// 		Header : 'Country',
// 		Footer : 'Country',
// 		accessor: 'country',
// 		Filter: ColumnFilter,
// 	},
// 	{
// 		Header : 'Phone',
// 		Footer : 'Phone',
// 		accessor: 'phone',
// 		Filter: ColumnFilter,
// 	},
// 	{
// 		Header : 'Phone1',
// 		Footer : 'Phone1',
// 		accessor: 'phone1',
// 		Filter: ColumnFilter,
// 	},

// 	{
// 		Header : 'Phone2',
// 		Footer : 'Phone2',
// 		accessor: 'phone2',
// 		Filter: ColumnFilter,
// 	},
// 	{
// 		Header : 'Phone3',
// 		Footer : 'Phone3',
// 		accessor: 'phone3',
// 		Filter: ColumnFilter,
// 	},
// 	{
// 		Header : 'Phone4',
// 		Footer : 'Phone4',
// 		accessor: 'phone4',
// 		Filter: ColumnFilter,
// 	},
	
// 	{
// 		Header : 'test1',
// 		Footer : 'test1',
// 		accessor: 'test1',
// 		Filter: ColumnFilter,
// 	},

// 	{
// 		Header : 'test2',
// 		Footer : 'test2',
// 		accessor: 'test2',
// 		Filter: ColumnFilter,
// 	},
	
// ]

// export const GROUPED_COLUMNS = [
// 	{
// 		Header : 'Id',
// 		Footer : 'Id',
// 		accessor: 'id'
// 	},
// 	{
// 		Header : 'Name',
// 		Footer : 'Name',
// 		columns: [
// 			{
// 				Header : 'First Name',
// 				Footer : 'First Name',
// 				accessor: 'first_name'
// 			},
// 			{
// 				Header : 'Last Name',
// 				Footer : 'Last Name',
// 				accessor: 'last_name'
// 			},
// 		]
// 	},
// 	{
// 		Header: 'Info',
// 		Footer: 'Info',
// 		columns: [
// 			{
// 				Header : 'Date of  Birth',
// 				Footer : 'Date of  Birth',
// 				accessor: 'date_of_birth'
// 			},
// 			{
// 				Header : 'Country',
// 				Footer : 'Country',
// 				accessor: 'country',
// 			},
// 			{
// 				Header : 'Phone',
// 				Footer : 'Phone',
// 				accessor: 'phone'
// 			},
// 			{
// 				Header : 'test1',
// 				Footer : 'test1',
// 				accessor: 'test1'
// 			},
// 		]
// 	},
// ]