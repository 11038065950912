import React, {
  Fragment,
  useState,
  useRef,
  useEffect,
  useContext,
} from "react";
import { Link } from "react-router-dom";
import { Dropdown } from "react-bootstrap";
import { connect, useDispatch, useSelector } from "react-redux";
import swal from "sweetalert";

import {
  getMyAccountDetails,
  saveMyUPIID,
  editMyUsername,
} from "../../store/actions/AuthActions";
import pic8 from "./../../images/courses/pic8.jpg";
import { ThemeContext } from "../../context/ThemeContext";

const MyAccount = (props) => {
  const [data, setData] = useState(
    document.querySelectorAll(".email-right-box .email-list .message")
  );
  const sort = 8;
  const activePag = useRef(0);
  const [test, settest] = useState(0);

  let errorsObj = { email: "", password: "" };
  const [errors, setErrors] = useState(errorsObj);

  const [image, setImage] = useState(
    props.reduxCurrentState.one.myAccount.image
  );

  const reduxUsername = useSelector((state) => state.one.myAccount.username);
  const [isEditUsername, setIsEditUsername] = useState(false);
  const [localUsername, setLocalUsername] = useState(reduxUsername);
  const [upiID, setUpiID] = useState(
    props.reduxCurrentState.one.myAccount.upi_id
  );

  const [isEdited, setIsEdited] = useState(false);

  const dispatch = useDispatch();

  const {
    changeBackground,
    changePrimaryColor,
    changeSecondaryColor,
    chnageSidebarColor,
    chnageHaderColor,
    changeNavigationHader,
  } = useContext(ThemeContext);

  const darkModeData = useSelector((state) => state.one.darkMode);

  useEffect(() => {
    changeBackground({
      value: props.reduxCurrentState.one.darkMode ? "dark" : "light",
      label: "Dark",
    });

    changePrimaryColor("color_11");
    changeSecondaryColor("color_1");
    chnageSidebarColor(
      props.reduxCurrentState.one.darkMode ? "dark" : "color_11"
    );
    changeNavigationHader(
      props.reduxCurrentState.one.darkMode ? "dark" : "color_11"
    );
  }, [darkModeData]);

  const handleEdit = () => {
    setIsEditUsername(true);
  };

  const handleChange = (e) => {
    setLocalUsername(e.target.value);
  };

  const handleSave = async () => {
    try {
      await dispatch(
        editMyUsername(
          props.reduxCurrentState.one.webAuthTokenId,
          localUsername
        )
      );
      setIsEditUsername(false);
    } catch (error) {
      // Handle any potential errors with dispatching the action
      console.error("Error updating username:", error);
    }
  };

  // const handleSave = () => {
  //   dispatch(
  //     editMyUsername(props.reduxCurrentState.one.webAuthTokenId, localUsername)
  //   );

  //   setIsEditUsername(false);
  // };

  useEffect(() => {
    dispatch(getMyAccountDetails(props.reduxCurrentState.one.webAuthTokenId));
    setLocalUsername(reduxUsername);
  }, [reduxUsername]);

  const isDisabled = true;

  const handleImageChange = (event) => {
    const newImage = event.target.files[0];

    if (newImage) {
      const reader = new FileReader();
      reader.onload = () => {
        setImage(reader.result);
      };
      reader.readAsDataURL(newImage);
    }
  };

  const onPressEditUsername = () => {
    setIsEditUsername(false);
  };

  const handleUPIChange = (e) => {
    setUpiID(e.target.value);
  };

  const onPressEditUPI = () => {
    setIsEdited(true);
  };

  const onPressSaveUPI = () => {

    if (!upiID) {
      alert("Please enter UPI ID");
      return;
    } else if (upiID.length < 5) {
      alert("UPI ID is too short");
      return;
    }

    dispatch(saveMyUPIID(props.reduxCurrentState.one.webAuthTokenId, upiID));
    setIsEdited(false)
  };

  return (
    <Fragment>
      <div className="row">
        <div className="login-wrapper ">
          <div className="container h-100 ">
            <div className="row h-100 d-flex justify-content-center align-items-center">
              <div className="col-xl-0 col-md-8">
                <div className="sign-in-your">
                  <div className="basic-form">
                    <form onSubmit={(e) => e.preventDefault()}>
                      <div className="row">
                        <div style={{ position: "relative" }}>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              position: "relative",
                            }}
                          >
                            <img
                              src={props.reduxCurrentState.one.myAccount.image}
                              width={100}
                              height={100}
                              alt=""
                              style={{ borderRadius: "50%" }}
                            />

                            <label htmlFor="fileUpload">
                              <button
                                style={{
                                  position: "absolute",
                                  top: 10,
                                  right: 256,
                                  transform: "translate(-50%, -50%)",
                                  background: "none",
                                  border: "none",
                                }}
                                onClick={() =>
                                  document.getElementById("fileUpload").click()
                                }
                              ></button>
                            </label>
                            <input
                              type="file"
                              id="fileUpload"
                              style={{ display: "none" }}
                              onChange={handleImageChange}
                            />
                          </div>
                        </div>

                        <div>
                          <br></br>
                        </div>

                        <div className="form-group mb-3 col-md-6">
                          <label>Full Name</label>
                          <input
                            type="text"
                            className="form-control"
                            value={
                              props.reduxCurrentState.one.myAccount.fullname
                            }
                            disabled={isDisabled}
                            style={{
                              backgroundColor: isDisabled ? "#f2f2f2" : "white",
                              color: isDisabled ? "#808080" : "black",
                            }}
                          />
                        </div>

                        <div className="form-group mb-3 col-md-6">
                          <label>Email</label>
                          <input
                            type="text"
                            className="form-control"
                            value={props.reduxCurrentState.one.myAccount.email}
                            disabled={isDisabled}
                            style={{
                              backgroundColor: isDisabled ? "#f2f2f2" : "white",
                              color: isDisabled ? "#808080" : "black",
                            }}
                          />
                        </div>

                        <div className="form-group mb-3 col-md-6">
                          <label>
                            {props.reduxCurrentState.one.myAccount.batch}
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            value={
                              props.reduxCurrentState.one.myAccount.batch_name
                            }
                            disabled={isDisabled}
                            style={{
                              backgroundColor: isDisabled ? "#f2f2f2" : "white",
                              color: isDisabled ? "#808080" : "black",
                            }}
                          />
                        </div>

                        <div className="form-group mb-3 col-md-6">
                          <label>Roll No</label>
                          <input
                            type="text"
                            className="form-control"
                            value={props.reduxCurrentState.one.myAccount.rollno}
                            disabled={isDisabled}
                            style={{
                              backgroundColor: isDisabled ? "#f2f2f2" : "white",
                              color: isDisabled ? "#808080" : "black",
                            }}
                          />
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="login-wrapper ">
          <div className="container h-100 ">
            <div className="row h-100 d-flex justify-content-center align-items-center">
              <div className="col-xl-0 col-md-8">
                <div className="sign-in-your">
                  <div className="basic-form">
                    <form onSubmit={(e) => e.preventDefault()}>
                      <div className="row">
                        <div className="form-group mb-3 col-md-6">
                          <label>My UPI ID</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Please enter your UPI ID"
                            onChange={handleUPIChange}
                            value={
                              isEdited
                                ? upiID
                                : props.reduxCurrentState.one.myAccount.upi_id
                                ? props.reduxCurrentState.one.myAccount.upi_id
                                : upiID
                            }
                            disabled={
                              isEdited
                                ? false
                                : props.reduxCurrentState.one.myAccount.upi_id
                                ? true
                                : false
                            }
                            style={{
                              backgroundColor: isDisabled ? "#f2f2f2" : "white",
                              color: isDisabled ? "#808080" : "black",
                            }}
                          />
                        </div>
                        { props.reduxCurrentState.one.myAccount.upi_id && !isEdited ? (
                          <div className="form-group mb-3 col-md-6">
                            <label>.</label>
                            <div className="text-center">
                              <button
                                type="submit"
                                className="btn btn-primary btn-block"
                                onClick={() => onPressEditUPI()}
                              >
                                Edit My UPI ID
                              </button>
                            </div>
                          </div>
                        ) : (
                          <div className="form-group mb-3 col-md-6">
                            <label>.</label>
                            <div className="text-center">
                              <button
                                type="submit"
                                className="btn btn-secondary btn-block"
                                onClick={() => onPressSaveUPI()}
                              >
                                Save My UPI ID
                              </button>
                            </div>
                          </div>
                        )}
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    reduxCurrentState: state,
  };
};

export default connect(mapStateToProps)(MyAccount);
