import React, { useContext, useState, useEffect, useRef } from "react";

import { connect, useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { getChallenges, getMCQAction } from "../../store/actions/AuthActions";
import {
  Row,
  Card,
  Col,
  Button,
  ListGroup,
  Alert,
  Modal,
  ProgressBar,
} from "react-bootstrap";
import swal from "sweetalert";

import { ThemeContext } from "../../context/ThemeContext";

import Check from "./../../images/vector/check.png";
import Bookmarks from "./../../images/vector/bookmarks.png";
import Bulb from "./../../images/vector/bulb.png";
import ML from "./../../images/ML.png";

const sections = ["LR", "PF", "AN", "SQ"];

const randomSection = sections[Math.floor(Math.random() * sections.length)];

const Challenges = (props) => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [isModelShown, setIsModelShown] = useState(false);
  const [isModelQuizShown, setIsModelQuizShown] = useState(false);
  const [tQuestions, setTQuestions] = useState(10);
  const [topicUuid, setTopicUuid] = useState("");
  const [topicName, setTopicName] = useState("");

  const [quizQuestions, setQuizQuestions] = useState([]);
  const [currentQuestion, setCurrentQuestion] = useState(null);
  const [questionIndex, setQuestionIndex] = useState(1);
  const [progress, setProgress] = useState(0);
  const [isCorrect, setIsCorrect] = useState(false);
  const [isExplanationAvailable, setIsExplanationAvailable] = useState(false);
  const [explanation, setExplanation] = useState("");
  const [selectedOption, setSelectedOption] = useState(null);
  const [correctAnswer, setCorrectAnswer] = useState(null);
  const [submitted, setSubmitted] = useState(false);

  const [activeTab, setActiveTab] = useState(randomSection);
  const [challenges, setChallenges] = useState(
    props.reduxCurrentState.one.challenges
  );
  const [selectedSection, setSelectedSection] = useState(randomSection);

  const handleTabClick = (tabNumber) => {
    setActiveTab(tabNumber);
    setSelectedSection(tabNumber);
  };

  const explanationRef = useRef();
  const modalContentRef = useRef(null);

  const {
    changeBackground,
    changePrimaryColor,
    changeSecondaryColor,
    chnageSidebarColor,
    chnageHaderColor,
    changeNavigationHader,
  } = useContext(ThemeContext);

  const darkModeData = useSelector((state) => state.one.darkMode);

  useEffect(() => {
    changeBackground({
      value: props.reduxCurrentState.one.darkMode ? "dark" : "light",
      label: "Dark",
    });

    changePrimaryColor("color_11");
    changeSecondaryColor("color_1");
    chnageSidebarColor(
      props.reduxCurrentState.one.darkMode ? "dark" : "color_11"
    );
    changeNavigationHader(
      props.reduxCurrentState.one.darkMode ? "dark" : "color_11"
    );
  }, [darkModeData]);

  const handleOptionSelect = (option) => {
    if (submitted) {
      return;
    }
    setSelectedOption(option);
  };

  const handleSubmission = () => {
    if (selectedOption && currentQuestion) {
      sumbitAnswer();
    } else {
      swal("Oops", "Please choose an option", "error");
    }
  };

  useEffect(() => {
    dispatch(getChallenges(props.reduxCurrentState.one.webAuthTokenId));
  }, []);

  const scrollToModalContent = () => {
    if (modalContentRef.current) {
      modalContentRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const handleSelectTQuestions = (event) => {
    setTQuestions(event.target.value);
  };

  const onClickGo = () => {
    fetchMcqs();
  };

  const onPressSkipQuestion = () => {
    if (questionIndex < quizQuestions.length) {
      setCurrentQuestion(quizQuestions[questionIndex]);
      setQuestionIndex(questionIndex + 1);
      setSelectedOption(null);
      setProgress(((questionIndex + 1) / quizQuestions.length) * 100);
      setSubmitted(false);
      setIsExplanationAvailable(false);
      setExplanation("");
    } else {
      swal("Done", "Your challenge has been completed..", "success");
      setIsModelQuizShown(false);
    }
  };

  const renderModelQuestions = () => {
    return (
      <Modal className="fade bd-example-modal-lg" show={isModelShown} size="lg">
        <Modal.Header>
          <Modal.Title>Confirmation</Modal.Title>
          <Button
            onClick={() => setIsModelShown(false)}
            variant=""
            className="btn-close"
          ></Button>
        </Modal.Header>
        <Modal.Body
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div className="form-group mb-4 col-md-6">
            <label>Choose number of questions</label>

            <select
              id="inputState"
              className="form-control"
              value={tQuestions}
              onChange={handleSelectTQuestions}
            >
              <option value={5}>5</option>
              <option value={10}>10</option>
              <option value={15}>15</option>
              <option value={20}>20</option>
            </select>
          </div>
        </Modal.Body>

        {isLoading ? (
          <Modal.Footer>
            <div class="spinner-border text-primary" role="status">
              <span class="sr-only">Loading...</span>
            </div>
          </Modal.Footer>
        ) : (
          <Modal.Footer>
            <Button onClick={() => setIsModelShown(false)} variant="dark light">
              Close
            </Button>
            <Button onClick={() => onClickGo()} variant="primary">
              Let's Go
            </Button>
          </Modal.Footer>
        )}
      </Modal>
    );
  };

  function fetchMcqs() {
    setIsLoading(true);

    fetch("https://kmkm84urcd.execute-api.ap-south-1.amazonaws.com/dev/api/", {
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        method: "getmcqs",
        web_auth_token_id: props.reduxCurrentState.one.webAuthTokenId,
        topic_id: topicUuid,
        total_qs: tQuestions,
      }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (!responseJson["error"]) {
          const quizQuestions = responseJson["quiz_questions"];
          setIsLoading(false);
          setIsModelShown(false);
          setQuizQuestions(quizQuestions);

          if (quizQuestions.length > 0) {
            setCurrentQuestion(quizQuestions[0]);
            setIsModelQuizShown(true);
            setProgress((questionIndex / quizQuestions.length) * 100);
          } else {
            alert("no questions");
            // swal("Oops", error, "error");
          }
        } else {
          const error = responseJson["message"];
          // swal("Oops", error, "error");
          throw error;
        }
      })
      .catch((error) => {
        console.log("request failed", error);
        alert("Backend error, try again or reach out to us.");
      });
  }
  function sumbitAnswer() {
    // setIsLoading(true);

    fetch("https://kmkm84urcd.execute-api.ap-south-1.amazonaws.com/dev/api/", {
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        method: "submitmcqanswer",
        web_auth_token_id: props.reduxCurrentState.one.webAuthTokenId,
        mcq_question_id: currentQuestion.uuid,
        user_answer: selectedOption,
      }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (!responseJson["error"]) {
          const questionData = responseJson["question_data"];

          setIsCorrect(questionData["is_correct"]);
          setCorrectAnswer(questionData["answer"]);
          setIsExplanationAvailable(questionData["is_explanation_available"]);
          setExplanation(questionData["explanation"]);
          setSubmitted(true);

          dispatch(getMCQAction(responseJson));

          if (questionData["is_explanation_available"]) {
            scrollToModalContent();
          }
        } else {
          const error = responseJson["message"];
          // swal("Oops", error, "error");
          throw error;
        }
      })
      .catch((error) => {
        console.log("request failed", error);
        alert("Backend error, try again or reach out to us.");
      });
  }

  const onPressReportQuestion = () => {
    alert("here")
  }

  function onPressReportQuestion1() {
    // setIsLoading(true);

    fetch("https://kmkm84urcd.execute-api.ap-south-1.amazonaws.com/dev/api/", {
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        method: "reportmcq",
        web_auth_token_id: props.reduxCurrentState.one.webAuthTokenId,
        mcq_question_id: currentQuestion.uuid,
      }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (!responseJson["error"]) {
          const questionData = responseJson["question_data"];

          setIsCorrect(questionData["is_correct"]);
          setCorrectAnswer(questionData["answer"]);
          setIsExplanationAvailable(questionData["is_explanation_available"]);
          setExplanation(questionData["explanation"]);
          setSubmitted(true);

          dispatch(getMCQAction(responseJson));

          if (questionData["is_explanation_available"]) {
            scrollToModalContent();
          }
        } else {
          const error = responseJson["message"];
          // swal("Oops", error, "error");
          throw error;
        }
      })
      .catch((error) => {
        console.log("request failed", error);
        alert("Backend error, try again or reach out to us.");
      });
  }
  const renderModelQuiz = () => {
    return (
      <Modal
        className="fade bd-example-modal-lg"
        show={isModelQuizShown}
        size="lg"
      >
        <Modal.Header>
          <Modal.Title>
            {topicName}
            {" --> "}
            Question {questionIndex}
          </Modal.Title>

          <Button
            variant=""
            className="btn-close"
            onClick={() => setIsModelQuizShown(false)}
          ></Button>
        </Modal.Header>
        <Card.Body>
          <ProgressBar now={progress} variant="secondary" />
        </Card.Body>
        {currentQuestion && (
          <Modal.Body ref={modalContentRef}>
            <Col xl="12">
              <Card>
                <Card.Body>
                  <h5>{currentQuestion.question}</h5>
                </Card.Body>
              </Card>
            </Col>

            <Col xl="12">
              <Card>
                <Card.Body>
                  <div className="basic-list-group">
                    <ListGroup>
                      <ListGroup.Item
                        action
                        active={selectedOption === "A"}
                        style={
                          submitted && correctAnswer === "A"
                            ? { color: "white", backgroundColor: "#56c760" }
                            : {}
                        }
                        onClick={() => handleOptionSelect("A")}
                      >
                        A) {currentQuestion.A}
                      </ListGroup.Item>

                      <ListGroup.Item
                        action
                        active={selectedOption === "B"}
                        style={
                          submitted && correctAnswer === "B"
                            ? { color: "white", backgroundColor: "#56c760" }
                            : {}
                        }
                        onClick={() => handleOptionSelect("B")}
                        // disabled={submitted}
                      >
                        B) {currentQuestion.B}
                      </ListGroup.Item>
                      <ListGroup.Item
                        action
                        active={selectedOption === "C"}
                        style={
                          submitted && correctAnswer === "C"
                            ? { color: "white", backgroundColor: "#56c760" }
                            : {}
                        }
                        onClick={() => handleOptionSelect("C")}
                        // disabled={submitted}
                      >
                        C) {currentQuestion.C}
                      </ListGroup.Item>

                      <ListGroup.Item
                        action
                        active={selectedOption === "D"}
                        style={
                          submitted && correctAnswer === "D"
                            ? { color: "white", backgroundColor: "#56c760" }
                            : {}
                        }
                        onClick={() => handleOptionSelect("D")}
                        // disabled={submitted}
                      >
                        D) {currentQuestion.D}
                      </ListGroup.Item>
                    </ListGroup>
                  </div>
                </Card.Body>
              </Card>
            </Col>

            {submitted ? (
              <div className="Row">
                <div className="card-footer pt-0 border-0">
                  <div className="row">
                    <div className="col-md-2"></div>

                    <div className="col-md-4">
                      <Link
                        onClick={() => onPressReportQuestion()}
                        className="btn btn-danger btn-block"
                      >
                        Report Question
                      </Link>
                    </div>
                    <div className="col-md-4">
                      <a
                        onClick={() => onPressSkipQuestion()}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="btn btn-info btn-block"
                      >
                        Next Question
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div className="Row">
                <div className="card-footer pt-0 border-0">
                  <div className="row">
                    <div className="col-md-2"></div>

                    <div className="col-md-4">
                      <Link
                        onClick={() => onPressSkipQuestion()}
                        className="btn btn-warning btn-block"
                      >
                        Skip Question
                      </Link>
                    </div>
                    <div className="col-md-4">
                      <a
                        onClick={() => handleSubmission()}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="btn btn-info btn-block"
                      >
                        Submit Answer
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {isCorrect && submitted ? (
              <>
                <div>
                  <br></br>
                </div>

                <div className="row">
                  <div className="col-md-12">
                    <Alert
                      variant="success"
                      className="alert-dismissible solid alert-alt fade show"
                    >
                      <svg
                        viewBox="0 0 24 24"
                        width="24"
                        height="24"
                        stroke="currentColor"
                        strokeWidth="2"
                        fill="none"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="me-2"
                      >
                        <polyline points="9 11 12 14 22 4"></polyline>
                        <path d="M21 12v7a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h11"></path>
                      </svg>
                      {"Your answer"} {selectedOption} {"is Correct"}
                    </Alert>
                  </div>
                </div>
                <div>
                  <br></br>
                </div>
              </>
            ) : !isCorrect && submitted ? (
              <Alert variant="primary" className="alert-dismissible fade show">
                <svg
                  viewBox="0 0 24 24"
                  width="24"
                  height="24"
                  stroke="currentColor"
                  strokeWidth="2"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="me-2"
                >
                  <polygon points="7.86 2 16.14 2 22 7.86 22 16.14 16.14 22 7.86 22 2 16.14 2 7.86 7.86 2"></polygon>
                  <line x1="15" y1="9" x2="9" y2="15"></line>
                  <line x1="9" y1="9" x2="15" y2="15"></line>
                </svg>
                {"Your answer"} {selectedOption} {"is Wrong"}
              </Alert>
            ) : null}

            {isExplanationAvailable && (
              <Col xl="12">
                <Card>
                  <Card.Header>
                    <Card.Title>Explaination</Card.Title>
                  </Card.Header>
                  <Card.Body>
                    <div ref={explanationRef}>
                      {explanation.split("\n").map((line, index) => (
                        <p key={index}>{line}</p>
                      ))}
                    </div>{" "}
                  </Card.Body>
                </Card>
              </Col>
            )}
          </Modal.Body>
        )}
      </Modal>
    );
  };

  const onPressTake = (topicUuid, topicName) => {
    setIsModelShown(true);
    setTopicUuid(topicUuid);
    setTopicName(topicName);

    setQuizQuestions([]);
    setCurrentQuestion(null);
    setQuestionIndex(1);
    setProgress(0);
    setIsCorrect(false);
    setIsExplanationAvailable(false);
    setExplanation("");
    setSelectedOption(null);
    setCorrectAnswer(null);
    setSubmitted(false);
  };

  function BookIcon() {
    return (
      <>
        <svg
          viewBox="0 0 1024 1024"
          class="icon"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          fill="#000000"
        >
          <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
          <g
            id="SVGRepo_tracerCarrier"
            stroke-linecap="round"
            stroke-linejoin="round"
          ></g>
          <g id="SVGRepo_iconCarrier">
            <path
              d="M520.51 506.876m-226.291 0a226.291 226.291 0 1 0 452.582 0 226.291 226.291 0 1 0-452.582 0Z"
              fill="#228E9D"
            ></path>
            <path
              d="M283.154 783.518c0 12.077 9.791 21.866 21.867 21.866h20.95-20.95c-12.077 0-21.867 9.791-21.867 21.867v20.95-20.95c0-12.077-9.79-21.867-21.866-21.867h-20.951 20.951c12.076 0 21.866-9.789 21.866-21.866v-20.951 20.951z"
              fill="#ED8F27"
            ></path>
            <path
              d="M294.249 848.2h-22.194v-20.951c0-5.939-4.826-10.765-10.765-10.765h-20.951V794.29h20.951c5.939 0 10.765-4.833 10.765-10.772v-20.951h22.194v20.951c0 5.939 4.833 10.772 10.772 10.772v22.194c-5.938 0-10.772 4.826-10.772 10.765V848.2z m31.723-31.716H305.02V794.29h20.952v22.194z"
              fill="#ED8F27"
            ></path>
            <path
              d="M893.42 584.46c0 8.085 6.554 14.639 14.639 14.639h14.026-14.026c-8.085 0-14.639 6.554-14.639 14.64v14.026-14.026c0-8.086-6.555-14.64-14.64-14.64h-14.026 14.026c8.085 0 14.64-6.554 14.64-14.639v-14.027 14.027z"
              fill="#ED8F27"
            ></path>
            <path
              d="M900.848 627.766h-14.859v-14.027c0-3.976-3.231-7.211-7.207-7.211h-14.027v-14.859h14.027a7.213 7.213 0 0 0 7.207-7.207v-14.027h14.859v14.027c0 3.976 3.236 7.207 7.212 7.207v14.859c-3.976 0-7.212 3.236-7.212 7.211v14.027z m21.238-21.239h-14.027v-14.859h14.027v14.859z"
              fill="#ED8F27"
            ></path>
            <path
              d="M798.898 630.64c0 12.076 9.79 21.866 21.866 21.866h20.95-20.95c-12.077 0-21.866 9.791-21.866 21.867v20.95-20.95c0-12.077-9.791-21.867-21.867-21.867H756.08h20.951c12.076 0.001 21.867-9.789 21.867-21.866v-20.95 20.95z"
              fill="#ED8F27"
            ></path>
            <path
              d="M809.996 695.324h-22.194v-20.952c0-5.938-4.833-10.764-10.772-10.764h-20.951v-22.194h20.951c5.939 0 10.772-4.834 10.772-10.772V609.69h22.194v20.951c0 5.938 4.826 10.772 10.765 10.772v22.194c-5.939 0-10.765 4.826-10.765 10.764v20.953z m31.717-31.717h-20.951v-22.194h20.951v22.194z"
              fill="#ED8F27"
            ></path>
            <path
              d="M717.137 779.358c0 8.247 6.686 14.932 14.932 14.932h14.306-14.305c-8.247 0-14.932 6.685-14.932 14.932v14.307-14.307c0-8.247-6.685-14.932-14.932-14.932H687.9h14.306c8.246 0.001 14.931-6.684 14.931-14.932v-14.306 14.306z"
              fill="#ED8F27"
            ></path>
            <path
              d="M724.715 823.531H709.56v-14.307c0-4.055-3.3-7.356-7.356-7.356h-14.307v-15.156h14.307c4.055 0 7.356-3.3 7.356-7.356V765.05h15.156v14.307c0 4.056 3.3 7.356 7.356 7.356v15.156c-4.056 0-7.356 3.3-7.356 7.356v14.306z m21.663-21.663h-14.307v-15.156h14.307v15.156z"
              fill="#ED8F27"
            ></path>
            <path
              d="M149.568 408.566c0 12.077 9.79 21.866 21.866 21.866h20.95-20.95c-12.077 0-21.866 9.791-21.866 21.867v20.95V452.3c0-12.077-9.791-21.867-21.867-21.867h-20.95 20.95c12.077 0 21.867-9.79 21.867-21.867v-20.951 20.951z"
              fill="#ED8F27"
            ></path>
            <path
              d="M160.667 473.253h-22.194v-20.951c0-5.938-4.834-10.772-10.772-10.772H106.75v-22.194h20.951c5.939 0 10.772-4.834 10.772-10.772v-20.951h22.194v20.951c0 5.938 4.826 10.772 10.764 10.772v22.194c-5.938 0-10.764 4.833-10.764 10.772v20.951z m31.716-31.723h-20.952v-22.194h20.952v22.194z"
              fill="#ED8F27"
            ></path>
            <path
              d="M597.466 738.507c-128.35 42.653-267.476-27.068-310.129-155.419-42.653-128.351 27.069-267.476 155.42-310.129 128.351-42.653 267.475 27.069 310.129 155.42 42.652 128.35-27.07 267.475-155.42 310.128zM449.756 294.02c-116.737 38.793-180.151 165.331-141.357 282.069 38.793 116.737 165.332 180.15 282.068 141.357 116.737-38.793 180.15-165.331 141.357-282.068C693.03 318.64 566.492 255.226 449.756 294.02z"
              fill="#300604"
            ></path>
            <path
              d="M302.798 707.113c-64.336 21.38-115.256 27.278-129.836 2.447-20.837-35.488 49.769-102.514 112.678-152.494l13.802 17.385c-100.734 80.029-111.64 116.558-107.343 123.877 13.766 23.442 161.564-17.505 361.801-135.062 89.557-52.586 169.606-108.886 225.403-158.528 63.697-56.682 72.87-84.702 68.82-91.601-4.337-7.39-41.966-15.654-162.398 34.173l-8.489-20.505c75.104-31.087 169.055-60.626 190.024-24.9 14.086 23.99-9.858 63.047-73.203 119.415-56.85 50.577-138.148 107.788-228.924 161.083-77.951 45.774-180.893 97.646-262.335 124.71z"
              fill="#300604"
            ></path>
            <path
              d="M237.831 332.952m-36.355 0a36.355 36.355 0 1 0 72.71 0 36.355 36.355 0 1 0-72.71 0Z"
              fill="#B12800"
            ></path>
            <path
              d="M237.835 380.403c-26.168 0-47.459-21.283-47.459-47.451s21.291-47.452 47.459-47.452c26.168 0 47.451 21.284 47.451 47.452s-21.283 47.451-47.451 47.451z m0-72.708c-13.93 0-25.265 11.329-25.265 25.258 0 13.929 11.335 25.257 25.265 25.257 13.929 0 25.257-11.328 25.257-25.257 0-13.93-11.328-25.258-25.257-25.258z"
              fill="#300604"
            ></path>
            <path
              d="M642.976 306.393c10.31-20.227 12.853-44.4 5.128-67.646-15.313-46.079-65.082-71.022-111.162-55.708-39.891 13.257-63.885 52.339-59.691 92.492 58.548-10.975 117.368 1.09 165.725 30.862z"
              fill="#FCE3C3"
            ></path>
            <path
              d="M647.405 322.146l-10.25-6.308c-47.368-29.161-103.428-39.598-157.859-29.397l-11.825 2.217-1.253-11.971c-4.793-45.829 23.479-89.643 67.227-104.181 51.811-17.218 107.969 10.926 125.189 62.743 8.411 25.312 6.365 52.371-5.77 76.175l-5.459 10.722zM487.986 262.58c51.562-6.88 103.836 2.866 149.654 27.891 5.178-15.599 5.207-32.35-0.067-48.221-13.36-40.204-56.931-62.039-97.128-48.681-30.16 10.021-50.831 37.964-52.459 69.011z"
              fill="#300604"
            ></path>
            <path
              d="M408.721 711.669c-14.823 20.596-19.961 47.725-11.347 73.646 14.257 42.902 60.594 66.124 103.496 51.867 41.65-13.841 64.643-57.909 52.912-99.724-50.931 7.458-101.633-2.309-145.061-25.789z"
              fill="#ED8F27"
            ></path>
            <path
              d="M504.372 847.714c-48.643 16.165-101.366-10.258-117.531-58.901-9.359-28.164-4.544-59.425 12.87-83.628l5.699-7.915 8.583 4.639c42.669 23.063 90.449 31.563 138.181 24.57l9.654-1.419 2.637 9.401c13.42 47.849-12.978 97.596-60.093 113.253zM412.71 726.201c-9.07 17.015-10.929 37.191-4.808 55.613 12.305 37.029 52.442 57.144 89.471 44.838 32.813-10.904 52.417-43.548 47.675-76.923-45.601 4.746-90.959-3.316-132.338-23.528zM651.535 398.697c-0.177-0.308-19.09-32.72-52.752-49.59-33.849-16.963-68.899-14.343-69.249-14.315l-1.849-21.922c1.671-0.143 41.378-3.265 80.954 16.569 39.78 19.936 61.12 56.804 62.009 58.363l-19.113 10.895zM679.453 402.033l11.865 24.29-19.767 9.656-11.865-24.29z"
              fill="#300604"
            ></path>
          </g>
        </svg>
      </>
    );
  }

  function BulbIcon() {
    return (
      <>
        <svg
          viewBox="0 0 1024 1024"
          class="icon"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          fill="#000000"
        >
          <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
          <g
            id="SVGRepo_tracerCarrier"
            stroke-linecap="round"
            stroke-linejoin="round"
          ></g>
          <g id="SVGRepo_iconCarrier">
            <path
              d="M879.028 203.277H559.911l-19.06-65.018-13.486-7.694-79.352 20.153-8.087 13.591 32.411 127.623-0.94-0.717-16.65 3.634-20.152 37.788v10.529l27.896 52.297-15.354 42.652-12.823-11.537-17.291 2.934-21.411 39.048-0.044 10.683 14.316 26.578-18.622 25.781-7.694-13.082-17.392-2.396-28.971 27.845-3.433 8.065v25.355l-36.831 15.67v-20.873l-17.261-9.39-37.34 24.161-36.969-19.414 8.155-35.695-9.48-13.583-33.853-4.344c-4.73-13.941-15.146-58.723 3.088-149.897l-19.989-8.8c-3.513 4.795-85.815 118.703-66.081 227.416 8.738 48.131 36.182 87.602 81.577 117.308 58.261 38.126 113.201 59.903 161.503 70.838L301.8 843.32l6.077 14.4 43.457 18.262 4.332 0.874h61.09l11.185-11.185v-35.899l-8.002-10.722-25.403-7.54 22.244-31.687c38.495-1.064 67.92-9.498 84.787-19.604a66.58 66.58 0 0 0 2.791 3.043l-50.069 69.417 4.948 16.94 66.758 26.451 4.121 0.787h74.317l11.185-11.185v-39.049l-11.185-11.185H561.56l-0.83-0.622 54.114-45.205a56.864 56.864 0 0 0 20.466-43.756c0-15.734-6.448-30.6-17.783-41.34 15.295-11.2 39.943-34.087 57.577-74.211a89.004 89.004 0 0 0 29.645-13.515l24.754-17.388c11.956-8.4 26.007-12.841 40.624-12.841h46.235l11.185-11.185v-23.618l-8.986-10.966-53.946-10.813a92.828 92.828 0 0 0-52.756 4.809l-16.193 6.458c2.909-29.346 0.317-50.997-4.015-78.002h187.377l11.185-11.185V214.462l-11.185-11.185z"
              fill="#FCE3C3"
            ></path>
            <path
              d="M559.911 203.277l-19.06-65.018-13.486-7.694-79.352 20.153-8.087 13.591 32.411 127.623-0.94-0.717-16.65 3.634-20.152 37.788v10.529l27.896 52.297-15.354 42.652-12.823-11.537-17.291 2.934-21.411 39.048-0.044 10.683 14.316 26.578-18.622 25.781-7.694-13.082-17.392-2.396-28.971 27.845-3.433 8.065v25.355l-36.831 15.67v-20.873l-17.261-9.39-37.34 24.161-36.969-19.414 8.155-35.695-9.48-13.583-33.853-4.344c-4.73-13.941-15.146-58.723 3.088-149.897l-19.989-8.8c-1.726 2.356-22.462 31.05-40.777 72.514-15.495 70.715 0.845 167.923 42.855 211.574 42.014 43.655 131.834 89.688 262.033 40.39C573.468 620.61 545.321 522.858 576.112 477.07c34.974-52.007 91.302-43.105 91.302-43.105l57.126 9.277h154.488l11.185-11.185V214.462l-11.185-11.185H559.911z"
              fill="#ED8F27"
            ></path>
            <path
              d="M604.435 876.857h-74.317l-4.121-0.787-66.759-26.451-4.948-16.94 50.069-69.417c-13.114-13.377-16.08-28.679-35.58-28.679v-22.369c37.435 0 54.215 37.905 58.489 42.882l0.586 13.832-47.017 65.185 51.416 20.375h60.998v-16.679h-35.418l-6.71-2.235-15.117-11.334-0.459-17.534 64.96-54.264a34.55 34.55 0 0 0 12.434-26.585 34.55 34.55 0 0 0-12.434-26.582l-2.039-2.238-40.771-44.378 18.415-12.692 39.952 43.187c12.252 10.825 19.246 26.298 19.246 42.704a56.864 56.864 0 0 1-20.466 43.756l-54.114 45.205 0.83 0.622h42.875l11.185 11.185v39.049l-11.185 11.182zM416.756 876.857h-61.09l-4.332-0.874-43.457-18.263-6.077-14.399 33.365-84.942 20.818 8.178-29.37 74.772 31.307 13.158h47.652v-16.366l-32.306-9.59-5.971-17.148 34.639-49.341 18.307 12.852-25.705 36.616 25.403 7.54 8.002 10.723v35.899z"
              fill="#300604"
            ></path>
            <path
              d="M408.575 779.944c-62.543 0.004-146.952-19.246-239.787-79.997-45.394-29.706-72.838-69.177-81.577-117.308-19.734-108.712 62.568-222.62 66.081-227.415l19.989 8.8c-18.233 91.174-7.817 135.957-3.088 149.897l33.853 4.344 9.48 13.583-8.155 35.695 36.969 19.414 37.34-24.161 17.261 9.39v20.873l36.831-15.67v-25.355l3.433-8.065 28.971-27.845 17.392 2.396 7.694 13.082 18.622-25.781-14.316-26.578 0.044-10.683 21.411-39.048 17.291-2.934 12.823 11.537 15.354-42.652-27.896-52.297v-10.529l20.152-37.788 16.65-3.634 0.94 0.717-32.411-127.623 8.087-13.591 79.352-20.153 13.486 7.694 19.06 65.018h319.117l11.185 11.185v217.595l-11.185 11.185H691.652c5.29 32.983 7.992 57.971 1.237 98.756l-21.812 3.457c7.071-42.696 2.523-75.755-3.663-111.49l11.021-13.093h189.409V225.646h-316.31l-10.734-8.039-18.382-62.707-58.057 14.742 37.551 147.873-17.618 11.651-15.994-12.19-11.156 20.924 27.423 51.416 0.652 9.051-22.671 62.98-18.004 4.525-14.694-13.224-11.702 21.336 14.756 27.397-0.782 11.854-32.75 45.347-18.707-0.881-9.197-15.634-15.324 14.727v27.988l-6.805 10.292-59.201 25.191-15.565-10.292v-17.229l-25.566 16.541-11.276 0.513-50.382-26.45-5.705-12.393 7.329-32.073-28.082-3.601-8.334-5.625c-0.874-1.558-17.793-33.034-9.925-112.742-20.964 41.462-44.01 103.502-33.398 161.807 7.613 41.823 31.771 76.309 71.801 102.504 165.429 108.261 296.228 74.114 314.723 55.618l15.816 15.816c-13.881 13.883-50.836 27.282-102.999 27.285z"
              fill="#300604"
            ></path>
            <path
              d="M603.565 693.288l-9.692-20.163 4.846 10.082-4.889-10.06c0.385-0.189 38.56-19.449 61.756-74.047l20.585 8.745c-26.677 62.797-70.746 84.548-72.606 85.443zM745.07 314.958h129.935v22.369H745.07z"
              fill="#300604"
            ></path>
            <path
              d="M653.554 612.965a88.946 88.946 0 0 1-30.339-5.33l-14.635-3.016L614.333 583l16.127 3.463a66.677 66.677 0 0 0 61.432-7.981l24.751-17.388c15.743-11.057 34.239-16.905 53.484-16.905h35.051v-3.27l-44.961-9.011a70.521 70.521 0 0 0-40.071 3.652l-56.776 22.642-6.124 0.619c-2.115-0.379-22.216 10.667-63.416-55.188l18.962-11.862c30.67 49.017 35.447 41.415 45.616 44.331l53.452-21.317c16.791-6.699 35.024-8.359 52.756-4.809l53.946 10.813 8.986 10.966v23.618l-11.185 11.185h-46.235c-14.618 0-28.668 4.442-40.624 12.842l-24.754 17.388a89.067 89.067 0 0 1-51.196 16.177z"
              fill="#300604"
            ></path>
            <path
              d="M734.235 861.672l33-81.426 20.73 8.402-32.998 81.426zM801.765 867.314l-20.731-8.402 13.704-33.817 20.732 8.402z"
              fill="#300604"
            ></path>
            <path
              d="M215.058 870.01l-32.999-81.427 20.732-8.402 32.999 81.426zM154.596 833.525l20.732-8.402 13.705 33.818-20.732 8.401z"
              fill="#300604"
            ></path>
            <path
              d="M593.829 255.953l88.179 29.046s-12.991 51.601-66.423 32.75c-31.277-11.036-21.756-61.796-21.756-61.796z"
              fill="#300604"
            ></path>
            <path
              d="M745.07 324.249l22.111 53.888 24.047-52.997z"
              fill="#FCE3C3"
            ></path>
            <path
              d="M775.376 381.855l-16.521-0.303-22.11-53.888 8.5-12.414 46.158 0.891 8.021 12.717-24.048 52.997z m-16.777-48.343l8.998 21.929 9.785-21.566-18.783-0.363z"
              fill="#300604"
            ></path>
            <path
              d="M262.403 363.322s8.039 8.12 26.008 9.23 22.886-6.701 22.886-6.701l28.651-48.029-34.328-49.225-35.169 15.317-22.901 45.142 14.853 34.266z"
              fill="#B12800"
            ></path>
            <path
              d="M262.403 363.322c-8.077-20.119-2.497-31.056 5.466-40.865 8.542-11.245 11.191-22.363 11.191-22.363s5.87 8.21 2.642 20.764c11.504-11.552 14.666-30.977 13.652-38.397 23.376 18.611 31.803 56.9 15.943 83.389 77.905-38.354 25.176-105.865 15.826-113.31 2.968 7.541 2.702 19.787-4.501 25.228-8.584-43.694-35.923-53.721-35.923-53.721 2.063 22.2-14.563 45.208-30.339 62.382 0.054-8.826-0.072-14.719-4.415-23.817-1.973 16.065-14.973 28.015-19.39 43.929-5.755 21.719 1.097 37.839 29.848 56.781z"
              fill="#B12800"
            ></path>
            <path
              d="M331.907 458.698s3.643 3.68 11.785 4.182c8.143 0.503 10.371-3.037 10.371-3.037l12.983-21.764-15.556-22.306-15.937 6.941-10.378 20.456 6.732 15.528z"
              fill="#B12800"
            ></path>
            <path
              d="M331.907 458.698c-3.66-9.117-1.131-14.073 2.477-18.518 3.871-5.095 5.071-10.134 5.071-10.134s2.66 3.721 1.197 9.409c5.213-5.235 6.646-14.037 6.187-17.4 10.593 8.434 14.411 25.784 7.224 37.787 35.303-17.38 11.408-47.973 7.171-51.346 1.345 3.417 1.225 8.966-2.039 11.432-3.89-19.8-16.278-24.343-16.278-24.343 0.935 10.06-6.599 20.486-13.748 28.268 0.025-3.999-0.033-6.67-2.001-10.792-0.894 7.28-6.785 12.695-8.786 19.906-2.609 9.843 0.496 17.147 13.525 25.731z"
              fill="#B12800"
            ></path>
          </g>
        </svg>
      </>
    );
  }

  function QuestionIcon() {
    return (
      <svg
        viewBox="0 0 1024 1024"
        class="icon"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        fill="#000000"
      >
        <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
        <g
          id="SVGRepo_tracerCarrier"
          stroke-linecap="round"
          stroke-linejoin="round"
        ></g>
        <g id="SVGRepo_iconCarrier">
          <path
            d="M796.839 549.833H692.601v39.908h84.281v244.945h-512.25V589.741h84.28v-39.908H244.674l-19.957 19.954v284.852l19.957 19.955h552.165l19.956-19.955V569.787z"
            fill="#300604"
          ></path>
          <path
            d="M247.885 575.611h545.743v271.09H247.885z"
            fill="#FCE3C3"
          ></path>
          <path
            d="M519.828 258.564c-78.125 0-141.653 63.53-141.653 141.653 0 54.371 30.907 103.594 79.553 127.345v61.239c0 17.17 10.305 32.053 25.186 38.634v13.164c0 14.882 12.018 26.9 26.899 26.9h20.605c14.88 0 26.9-12.018 26.9-26.9v-13.162c14.88-6.581 25.183-21.464 25.183-38.635v-61.239c48.361-23.751 79.553-72.973 79.553-127.345-0.572-78.125-64.101-141.655-142.225-141.655l-0.001 0.001z"
            fill="#300604"
          ></path>
          <path
            d="M530.418 669.999h-20.605c-16.211 0-29.399-13.188-29.399-29.4V629.03c-15.354-7.472-25.186-23.085-25.186-40.229v-59.688c-23.698-11.888-43.604-29.983-57.638-52.416-14.336-22.917-21.915-49.363-21.915-76.48 0-79.486 64.667-144.153 144.153-144.153 79.221-0.001 144.145 64.658 144.727 144.135 0 27.084-7.616 53.531-22.023 76.502a144.9 144.9 0 0 1-57.53 52.409v59.691c0 17.145-9.83 32.759-25.183 40.23v11.567c-0.001 16.213-13.19 29.401-29.401 29.401z m-10.589-408.937c-76.73 0.001-139.154 62.425-139.154 139.154 0 26.179 7.315 51.708 21.153 73.829 13.813 22.079 33.522 39.808 56.996 51.27l1.403 0.685v62.801c0 15.714 9.302 29.981 23.697 36.348l1.489 0.658v14.792c0 13.454 10.945 24.4 24.399 24.4h20.605c13.455 0 24.4-10.946 24.4-24.4v-14.79l1.489-0.658C570.7 618.784 580 604.516 580 588.801v-62.797l1.398-0.687a139.908 139.908 0 0 0 56.896-51.273c13.908-22.174 21.259-47.703 21.259-73.827-0.56-76.722-63.241-139.155-139.724-139.155z"
            fill="#300604"
          ></path>
          <path
            d="M530.127 647.469h-20.604c-3.718 0-6.869-3.15-6.869-6.868v-9.445h34.344v9.445c0 3.718-3.149 6.868-6.871 6.868z"
            fill="#FCE3C3"
          ></path>
          <path
            d="M530.127 652.541h-20.604c-6.473 0-11.941-5.469-11.941-11.94v-14.518h44.487v14.518c0.001 6.471-5.468 11.94-11.942 11.94z m-22.401-16.313v4.373c0 0.924 0.874 1.796 1.797 1.796h20.604c0.925 0 1.799-0.872 1.799-1.796v-4.373h-24.2z"
            fill="#300604"
          ></path>
          <path
            d="M539.573 611.123h-39.492c-12.303 0-22.322-10.015-22.322-22.322v-24.037h84.423v24.037c-0.288 12.307-10.305 22.322-22.609 22.322z"
            fill="#FCE3C3"
          ></path>
          <path
            d="M539.573 613.623h-39.492c-13.687 0-24.822-11.135-24.822-24.822v-26.536h89.423v26.536c-0.32 13.713-11.583 24.822-25.109 24.822z m-59.314-46.358v21.536c0 10.93 8.892 19.822 19.822 19.822h39.492c10.833 0 19.854-8.918 20.109-19.881v-21.478h-79.423z"
            fill="#300604"
          ></path>
          <path
            d="M519.828 465.178l-21.464-39.204h42.639l-21.176 39.204z"
            fill="#ED8F27"
          ></path>
          <path
            d="M519.842 470.411l-25.696-46.938h51.049l-25.353 46.938z m-17.259-41.937l17.23 31.471 16.998-31.471h-34.228z"
            fill="#300604"
          ></path>
          <path
            d="M567.904 511.823l-6.009 2.576v30.335h-32.052v-58.951c1.718-1.146 2.864-2.862 4.007-4.578l29.189-53.801c2.575-4.292 2.289-9.729-0.285-14.022-2.861-4.579-8.014-7.442-13.736-7.442h-58.665c-5.725 0-10.874 2.86-13.735 7.442-2.576 4.292-2.863 9.443-0.286 14.022l29.477 54.086c1.143 1.715 2.289 3.432 4.005 4.579v58.665h-32.051v-30.335l-6.01-2.576c-44.641-19.17-73.258-63.243-73.258-111.604 0-66.965 54.658-121.622 121.622-121.622 66.962 0 121.623 54.658 121.623 121.622-0.285 48.361-29.189 92.148-73.832 111.604h-0.004z"
            fill="#ED8F27"
          ></path>
          <path
            d="M564.396 547.233h-37.052v-62.787l1.112-0.742c1.281-0.855 2.242-2.277 3.251-3.791l29.189-53.795c2.03-3.384 1.92-7.771-0.286-11.449-2.427-3.886-6.77-6.229-11.592-6.229h-58.665c-4.823 0-9.166 2.343-11.615 6.267-2.184 3.639-2.275 7.834-0.228 11.471l29.44 54.019c1.01 1.517 1.974 2.94 3.251 3.794l1.111 0.742v62.501h-37.051v-31.187l-4.495-1.927c-45.423-19.506-74.773-64.215-74.773-113.901 0-68.441 55.681-124.122 124.122-124.122s124.123 55.681 124.123 124.122c-0.291 49.378-29.861 94.079-75.333 113.896l-4.511 1.933v31.185z m-32.053-5h27.052V512.75l7.699-3.301c43.556-19.067 71.865-61.925 72.144-109.245 0-65.67-53.438-119.107-119.123-119.107-65.684 0-119.122 53.438-119.122 119.122 0 47.686 28.161 90.591 71.744 109.307l7.523 3.225v29.483h27.051v-54.937c-1.522-1.324-2.607-2.952-3.585-4.421l-0.115-0.189-29.477-54.086c-2.893-5.141-2.773-11.321 0.338-16.504 3.392-5.435 9.319-8.656 15.879-8.656h58.665c6.558 0 12.485 3.222 15.856 8.618 3.171 5.287 3.29 11.62 0.338 16.582l-29.282 53.95c-0.978 1.469-2.062 3.096-3.587 4.421v55.221z"
            fill="#300604"
          ></path>
          <path
            d="M278.274 371.91h53.127v20.517h-53.127z"
            fill="#ED8F27"
          ></path>
          <path
            d="M336.473 397.5h-63.271v-30.662h63.271V397.5z m-53.127-10.145h42.983v-10.373h-42.983v10.373z"
            fill="#ED8F27"
          ></path>
          <path
            d="M362.004 222.035l37.566 37.566-14.509 14.508-37.565-37.566z"
            fill="#ED8F27"
          ></path>
          <path
            d="M385.061 281.283l-44.738-44.737 21.684-21.679 44.738 44.739-21.684 21.677z m-30.393-44.737l30.394 30.394 7.336-7.335-30.393-30.394-7.337 7.335z"
            fill="#ED8F27"
          ></path>
          <path
            d="M637.119 259.606l37.566-37.566 14.507 14.507-37.565 37.565-14.508-14.506z"
            fill="#ED8F27"
          ></path>
          <path
            d="M651.627 281.285l-21.68-21.68 44.738-44.738 21.68 21.68-44.738 44.738z m-7.335-21.68l7.335 7.334 30.393-30.393-7.334-7.334-30.394 30.393z"
            fill="#ED8F27"
          ></path>
          <path
            d="M704.746 371.91h53.126v20.517h-53.126z"
            fill="#ED8F27"
          ></path>
          <path
            d="M762.944 397.5h-63.27v-30.662h63.27V397.5z m-53.126-10.145H752.8v-10.373h-42.982v10.373z"
            fill="#ED8F27"
          ></path>
          <path
            d="M507.814 167.652h20.517v53.126h-20.517z"
            fill="#ED8F27"
          ></path>
          <path
            d="M533.403 225.851h-30.661V162.58h30.661v63.271z m-20.517-10.145h10.374v-42.981h-10.374v42.981z"
            fill="#ED8F27"
          ></path>
          <path
            d="M331.401 692.599h84.407v84.407h-84.407zM478.524 692.599h84.407v84.407h-84.407zM625.648 692.599h84.407v84.407h-84.407z"
            fill="#B12800"
          ></path>
        </g>
      </svg>
    );
  }

  const CourseBlogData = [
    {
      coloumClass: "col-sm-6",
      // classChange: "bg-warning",

      image: Check,
      imgClass: "",
      title: "Total Questions Answered",
      number: props.reduxCurrentState.one.userMcqStats.total_qa,
      svgicon: <QuestionIcon />,
    },
    {
      coloumClass: "col-sm-6",
      // classChange: "bg-primary",

      image: Bookmarks,
      imgClass: "bookmarks",
      title: "Total Correctly Answered",
      number: props.reduxCurrentState.one.userMcqStats.total_correct,
      svgicon: <BookIcon />,
    },
    {
      coloumClass: "col-sm-12",
      image: Bulb,
      imgClass: "bulb",
      title: "Total Coins Earned",
      number: props.reduxCurrentState.one.userMcqStats.coins_earned,
      svgicon: <BulbIcon />,
    },
  ];

  return (
    <>
      <div className="row">
        {CourseBlogData.map((item, ind) => (
          <div className={`col-xl-4 ${item.coloumClass}`} key={ind}>
            <div className={`dlab-cource ${item.classChange}`}>
              <div className="d-flex align-items-center">
                <span className="course-icon">{item.svgicon}</span>

                <div className="ms-2">
                  <h4 className="mb-0">{item.number}</h4>
                  <span>{item.title}</span>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>

      <div className="row">
        <div className="col-xl-6 col-xl-12">
          <div className="card">
            <div className="card-body">
              <div className="widget-courses align-items-center d-flex flex-wrap">
                <div className="d-flex  align-items-center flex-wrap flex-1 justify-content-center">
                  <div>
                    <div
                      style={{
                        display: "flex",
                        borderRadius: "10px",
                        overflow: "hidden",
                      }}
                    >

                      <div
                        className={
                          activeTab === "PF"
                            ? "btn btn-primary "
                            : "btn bg-dark"
                        }
                        style={{
                          padding: "10px 20px",
                          cursor: "pointer",
                          // backgroundColor: activeTab === 1 ? "#e83e8c" : "#fff",
                          color: "#fff",
                          borderRadius: "20px 20px 20px 20px",
                          transition: "background-color 0.3s ease",
                          marginRight: "10px",
                        }}
                        onClick={() => handleTabClick("PF")}
                      >
                        Python
                      </div>
                      
                      <div
                        className={
                          activeTab === "SQ"
                            ? "btn btn-primary "
                            : "btn bg-dark"
                        }
                        style={{
                          padding: "10px 20px",
                          cursor: "pointer",
                          // backgroundColor: activeTab === 1 ? "#e83e8c" : "#fff",
                          color: "#fff",
                          borderRadius: "20px 20px 20px 20px",
                          transition: "background-color 0.3s ease",
                          marginRight: "10px",
                        }}
                        onClick={() => handleTabClick("SQ")}
                      >
                        SQL
                      </div>

                     
                      
                     

                      <div
                        className={
                          activeTab === "AN"
                            ? "btn btn-primary "
                            : "btn bg-dark"
                        }
                        style={{
                          padding: "10px 20px",
                          cursor: "pointer",
                          // backgroundColor: activeTab === 2 ? "#3498db" : "#fff",
                          color: "#fff",
                          borderRadius: "20px 20px 20px 20px",
                          transition: "background-color 0.3s ease",
                          marginRight: "10px",
                        }}
                        onClick={() => handleTabClick("AN")}
                      >
                        Aptitude
                      </div>
                      
                      <div
                        className={
                          activeTab === "LR"
                            ? "btn btn-primary "
                            : "btn bg-dark"
                        }
                        style={{
                          padding: "10px 20px",
                          cursor: "pointer",
                          // backgroundColor: activeTab === 3 ? "#3498db" : "#fff",
                          color: "#fff",
                          borderRadius: "20px 20px 20px 20px",
                          transition: "background-color 0.3s ease",
                          marginRight: "10px",
                        }}
                        onClick={() => handleTabClick("LR")}
                      >
                        Reasoning
                      </div>
                    
                      
                      
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        {props.reduxCurrentState.one.challengesTest[selectedSection].map(
          (data, index) => (
            <div className="col-xl-4 col-md-6" key={index}>
              <div className="card all-crs-wid">
                <div className="card-body">
                  <div className="courses-bx">
                    <div className="dlab-media">
                      <img src={data.thumbnail} alt="" />
                      {/* <img src={ML} alt="" /> */}
                    </div>
                    <div className="dlab-info">
                      <div className="dlab-title d-flex justify-content-between">
                        <div>
                          <h4>
                            <Link to={"./course-details-1"}>{data.topic}</Link>
                          </h4>
                          <p className="m-0">MCQs</p>
                        </div>
                        <p className="m-0">
                          <span className="text-secondary">10 Coins</span> / 25
                          Q's
                        </p>
                      </div>
                      <div className="d-flex justify-content-between content align-items-center">
                        <span>
                          <svg
                            fill="#c36969"
                            width="20"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                            <g
                              id="SVGRepo_tracerCarrier"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            ></g>
                            <g id="SVGRepo_iconCarrier">
                              {" "}
                              <path d="M.221 16.268a15.064 15.064 0 0 0 1.789 1.9C2.008 18.111 2 18.057 2 18a5.029 5.029 0 0 1 3.233-4.678 1 1 0 0 0 .175-1.784A2.968 2.968 0 0 1 4 9a2.988 2.988 0 0 1 5.022-2.2 5.951 5.951 0 0 1 2.022-.715 4.994 4.994 0 1 0-7.913 6.085 7.07 7.07 0 0 0-2.91 4.098zM23.779 16.268a7.07 7.07 0 0 0-2.91-4.1 4.994 4.994 0 1 0-7.913-6.086 5.949 5.949 0 0 1 2.022.715 2.993 2.993 0 1 1 3.614 4.74 1 1 0 0 0 .175 1.784A5.029 5.029 0 0 1 22 18c0 .057-.008.111-.01.167a15.065 15.065 0 0 0 1.789-1.899z"></path>{" "}
                              <path d="M18.954 20.284a7.051 7.051 0 0 0-3.085-5.114A4.956 4.956 0 0 0 17 12a5 5 0 1 0-8.869 3.17 7.051 7.051 0 0 0-3.085 5.114 14.923 14.923 0 0 0 1.968.849C7.012 21.088 7 21.046 7 21a5.031 5.031 0 0 1 3.233-4.678 1 1 0 0 0 .175-1.785A2.964 2.964 0 0 1 9 12a3 3 0 1 1 6 0 2.964 2.964 0 0 1-1.408 2.537 1 1 0 0 0 .175 1.785A5.031 5.031 0 0 1 17 21c0 .046-.012.088-.013.133a14.919 14.919 0 0 0 1.967-.849z"></path>{" "}
                            </g>
                          </svg>{" "}
                          {data.total_taken}+ people have taken
                        </span>

                        <Link
                          onClick={() => onPressTake(data.uuid, data.topic)}
                          className="btn btn-primary btn-sm"
                        >
                          Take up
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )
        )}
      </div>

      {renderModelQuestions()}
      {renderModelQuiz()}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    reduxCurrentState: state,
  };
};

export default connect(mapStateToProps)(Challenges);
