import React, { useEffect } from 'react';
import Plotly from 'plotly.js-dist-min';

const HistogramChart = () => {
  useEffect(() => {
    // Mock data for 500 students in Math, Physics, and Chemistry
    const mathScores = Array.from({ length: 500 }, () => Math.floor(Math.random() * 100));
    const physicsScores = Array.from({ length: 500 }, () => Math.floor(Math.random() * 100));
    const chemistryScores = Array.from({ length: 500 }, () => Math.floor(Math.random() * 100));

    const mathData = [
      {
        x: mathScores,
        type: 'histogram',
        name: 'Math',
        opacity: 0.7,
      },
    ];

    const physicsData = [
      {
        x: physicsScores,
        type: 'histogram',
        name: 'Physics',
        opacity: 0.7,
      },
    ];

    const chemistryData = [
      {
        x: chemistryScores,
        type: 'histogram',
        name: 'Chemistry',
        opacity: 0.7,
      },
    ];

    const mathLayout = {
      title: 'Math Scores Distribution',
      xaxis: { title: 'Math Scores' },
      yaxis: { title: 'Frequency' },
      width: 500, // Adjust the width as needed
    };

    const physicsLayout = {
      title: 'Physics Scores Distribution',
      xaxis: { title: 'Physics Scores' },
      yaxis: { title: 'Frequency' },
      width: 500, // Adjust the width as needed
    };

    const chemistryLayout = {
      title: 'Chemistry Scores Distribution',
      xaxis: { title: 'Chemistry Scores' },
      yaxis: { title: 'Frequency' },
      width: 500, // Adjust the width as needed
    };

    Plotly.newPlot('math-chart', mathData, mathLayout);
    Plotly.newPlot('physics-chart', physicsData, physicsLayout);
    Plotly.newPlot('chemistry-chart', chemistryData, chemistryLayout);
  }, []);

  return (
    <div className="row">
      <div className="col-xl-6 col-md-6">
        <div className="card">
          <div className="card-header border-0 pb-0">
            <h4>Math</h4>
          </div>
          <div className="card-body">
            <div id="math-chart"></div>
          </div>
        </div>
      </div>
      <div className="col-xl-6 col-md-6">
        <div className="card">
          <div className="card-header border-0 pb-0">
            <h4>Physics</h4>
          </div>
          <div className="card-body">
            <div id="physics-chart"></div>
          </div>
        </div>
      </div>
      <div className="col-xl-6 col-md-6">
        <div className="card">
          <div className="card-header border-0 pb-0">
            <h4>Chemistry</h4>
          </div>
          <div className="card-body">
            <div id="chemistry-chart"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HistogramChart;
