import React, { useContext, useState, useEffect, useRef } from "react";

import { Link } from "react-router-dom";
import { Accordion } from "react-bootstrap";
import { connect, useDispatch, useSelector } from "react-redux";
import SimpleReactLightbox from "simple-react-lightbox";
import { SRLWrapper } from "simple-react-lightbox";
import { getClasses } from "../../store/actions/AuthActions";
import { ThemeContext } from "../../context/ThemeContext";

//images
import pic1 from "./../../images/courses/pic-1.jpg";
import pic2 from "./../../images/courses/pic-2.jpg";
import pic3 from "./../../images/courses/pic-3.jpg";

import big1 from "../../images/materials/info1.png";

import ppt1 from "../../images/materials/D1.jpg";
import ppt2 from "../../images/materials/D2.jpg";
import ppt3 from "../../images/materials/D3.jpg";
import ppt4 from "../../images/materials/D4.jpg";

import py1 from "../../images/resources/PythonCheetsheets/1.png";
import py2 from "../../images/resources/PythonCheetsheets/2.png";
import py3 from "../../images/resources/PythonCheetsheets/3.png";
import py4 from "../../images/resources/PythonCheetsheets/4.png";
import py5 from "../../images/resources/PythonCheetsheets/5.png";
import py6 from "../../images/resources/PythonCheetsheets/6.png";
import py7 from "../../images/resources/PythonCheetsheets/7.png";
import py8 from "../../images/resources/PythonCheetsheets/8.png";
import py9 from "../../images/resources/PythonCheetsheets/9.png";
import py10 from "../../images/resources/PythonCheetsheets/10.png";
import py11 from "../../images/resources/PythonCheetsheets/11.png";
import py12 from "../../images/resources/PythonCheetsheets/12.png";

import { Viewer } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";
import { Worker } from "@react-pdf-viewer/core";


const boxCard = [
  { image: pic1, title: "Here 10 Tips to become better in UI/UX Design." },
  { image: pic2, title: "Best 10 font pairing for web design with example." },
  { image: pic3, title: "Create A Real-Time E-Commerce App With React Native" },
];

const FaqBlog = [
  { title: "Is there a freetrial?" },
  { title: "How the I get payment?" },
  { title: "Can I rewatch the live courses?" },
  { title: "What different Free account and Premium?" },
];

const lightGallery = [{ thumb: big1, large: big1 }];

const lightGallery2 = [
  { thumb: py1, large: py1 },
  { thumb: py2, large: py2 },
  { thumb: py3, large: py3 },
  { thumb: py4, large: py4 },
  { thumb: py5, large: py5 },
  { thumb: py6, large: py6 },
  { thumb: py7, large: py7 },
  { thumb: py8, large: py8 },
  { thumb: py9, large: py9 },
  { thumb: py10, large: py10 },
  { thumb: py11, large: py11 },
  { thumb: py12, large: py12 },
];


const projectTypes = ["EDA", "DL"];
const randomProjectType =
  projectTypes[Math.floor(Math.random() * projectTypes.length)];

const dataBatch = ["Batch 1", "Batch 2", "Batch 3"];
const randomDataBatch = dataBatch[Math.floor(Math.random() * dataBatch.length)];

const edaBatch = ["Batch 1", "Batch 2", "Batch 3"];
const randomEDABatch = edaBatch[Math.floor(Math.random() * edaBatch.length)];

const mlBatch = ["Batch 1", "Batch 2", "Batch 3"];
const randomMLBatch = mlBatch[Math.floor(Math.random() * mlBatch.length)];

const randomValue = Math.random();

// Define your two batches
const b1 = "Batch 1";
const b2 = "Batch 2";

// Use the random number to select a batch
const batch = randomValue < 0.5 ? b1 : b2;


const underlineStyle1 = {
  textDecoration: "underline",
  cursor: "pointer",
  color: "green",
};

const tabStyle = {
  display: "inline-block",
  padding: "10px 20px",
  borderRadius: "20px 20px 0 0",
  cursor: "pointer",
};

const videoPlayOverlayStyle = {
  position: "absolute",
  top: "45%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  pointerEvents: "none",
};

const videoPlayIconStyle = {
  fontSize: "40px", // Adjust the size and style as needed
};






const Resources = (props) => {
  const [activeDefault, setActiveDefault] = useState(0);

  const [showPdf, setShowPdf] = useState(false);
  const [showHtml, setShowHtml] = useState(false);
  const [currentPdf, setCurrentPdf] = useState(null);
  const [currentHtml, setCurrentHtml] = useState(null);
  const [isHtmlLoading, setIsHtmlLoading] = useState(false);

  const [isLoading, setIsLoading] = useState(true);
  const [activeToggle, setActiveToggle] = useState("aboutMe");
  const [selectedTab, setSelectedTab] = useState(batch);

  const [activeProjectTab, setActiveProjectTab] = useState("Guides");

  const [showPlayIcon, setShowPlayIcon] = useState(true);

  const handleProjectTab = (tabNumber) => {
    setActiveProjectTab(tabNumber);
  };


  const dispatch = useDispatch();

  const {
    changeBackground,
    changePrimaryColor,
    changeSecondaryColor,
    chnageSidebarColor,
    chnageHaderColor,
    changeNavigationHader,
  } = useContext(ThemeContext);

  const darkModeData = useSelector((state) => state.one.darkMode);

  useEffect(() => {
    changeBackground({
      value: props.reduxCurrentState.one.darkMode ? "dark" : "light",
      label: "Dark",
    });

    changePrimaryColor("color_11");
    changeSecondaryColor("color_1");
    chnageSidebarColor(
      props.reduxCurrentState.one.darkMode ? "dark" : "color_11"
    );
    changeNavigationHader(
      props.reduxCurrentState.one.darkMode ? "dark" : "color_11"
    );
  }, [darkModeData]);

  useEffect(() => {
    dispatch(getClasses(props.reduxCurrentState.one.webAuthTokenId));
  }, []);

  const handlePdfLoaded = () => {
    setIsLoading(false);
  };

  const onPressViewHtmlCode = (htmlLink) => {
    window.scrollTo({
      top: 0,
      behavior: "auto", // You can use 'auto' for an immediate scroll
    });
    setShowHtml(true);
    setCurrentHtml(htmlLink);
  };

  const options = {
    settings: {
      lightboxTransitionSpeed: 0.3,
      lightboxTransitionTimingFunction: "ease-in-out",
    },
    buttons: {
      showAutoplayButton: false,
      showDownloadButton: false,
    },
    caption: {
      showCaption: false,
    },
  };


  const VideoPlayButton = () => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100"
      height="100"
      viewBox="0 0 100 100"
    >
      <circle
        cx="50"
        cy="50"
        r="40"
        stroke="white"
        strokeWidth="2"
        fill="transparent"
      />
      <polygon points="35,25 35,75 75,50" fill="white" />
    </svg>
  );


  return (
    <>
      <div className="row">
        {showPdf && currentPdf ? (
         
          <div className="pdf-viewer-container">
            {isLoading && <p>Please wait...</p>}
            
            <div className="d-flex align-items-center">
              <p onClick={() => setShowPdf(false)} style={underlineStyle1}>
                Go Back
              </p>
            </div>

            <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
              <Viewer
                fileUrl={currentPdf}
                onError={(error) => {
                  console.error("Error loading PDF:", error);
                }}
                onDocumentLoad={handlePdfLoaded}
              />{" "}
            </Worker>
          </div>
        ) : showHtml && currentHtml ? (
          <>
            <div className="d-flex align-items-center">
              <p onClick={() => setShowHtml(false)} style={underlineStyle1}>
                Go Back
              </p>
            </div>
            <div>
              <iframe
                src={currentHtml}
                width="100%"
                height="1000px"
                title="HTML Viewer"
              >
                Your browser does not support iframes.
              </iframe>
            </div>
          </>
        ) : (
          <>
           

          

            {activeProjectTab == "Guides" && (
              <>
                {props.reduxCurrentState.one.guides &&
                  props.reduxCurrentState.one.guides.map((item, index) => (
                    <div className="col-xl-4 col-xxl-4 col-md-4" key={index}>
                      <div className="card instructors-box">
                        <div className="card-body text-center pb-3">
                          <img
                            style={{ borderRadius: "10px" }}
                            src={item.thumbnail}
                            alt=""
                            width={250}
                            height={400}
                          />

                          <div className="instructors-media">
                            <div className="instructors-media-info">
                              <h4>{item.title}</h4>
                            </div>
                          </div>
                        </div>

                        <div className="Row">
                          <div className="card-footer pt-0 border-0">
                            <div className="row">
                              <div className="col-md-12">
                                <Link
                                  onClick={() =>
                                    onPressViewHtmlCode(item.html_link)
                                  }
                                  className="btn btn-primary btn-block"
                                >
                                  View
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
              </>
            )}

<SimpleReactLightbox>
        <SRLWrapper>
          <div className="row">
            <div className="col-lg-12">
              <div className="card">
                <div className="card-header">
                  <h4 className="card-title">Python Cheat Sheets</h4>
                </div>
                <div className="card-body pb-1">
                  <div id="lightgallery" className="row">
                    {lightGallery2.map((item, index) => (
                      <a
                        href={item.large}
                        data-exthumbimage="images/big/img1.jpg"
                        data-src={big1}
                        className="col-lg-4 col-md-6 mb-3"
                        key={index}
                      >
                        <img
                          src={item.thumb}
                          style={{ width: "105%" }}
                          alt="gallery"
                          className="img-fluid rounded"
                        />
                      </a>
                    ))}
                  </div>
                </div>
              </div>
              {/* <!-- /# card --> */}
            </div>
          </div>
        </SRLWrapper>
      </SimpleReactLightbox>

      <SimpleReactLightbox>
        <SRLWrapper>
          <div className="row">
            <div className="col-lg-12">
              <div className="card">
                <div className="card-header">
                  <h4 className="card-title">Infographics</h4>
                </div>
                <div className="card-body pb-1">
                  <div id="lightgallery" className="row">
                    {lightGallery.map((item, index) => (
                      <a
                        href={item.large}
                        data-exthumbimage="images/big/img1.jpg"
                        data-src={big1}
                        className="col-lg-3 col-md-6 mb-4"
                        key={index}
                      >
                        <img
                          src={item.thumb}
                          style={{ width: "100%" }}
                          alt="gallery"
                          className="img-fluid rounded"
                        />
                      </a>
                    ))}
                  </div>
                </div>
              </div>
              {/* <!-- /# card --> */}
            </div>
          </div>
        </SRLWrapper>
      </SimpleReactLightbox>
    
    
          </>
        )}
      </div>

   
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    reduxCurrentState: state,
  };
};

export default connect(mapStateToProps)(Resources);
