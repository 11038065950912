import { applyMiddleware, combineReducers, compose, createStore } from "redux";
import thunk from "redux-thunk";
import { OneReducer } from "./reducers/OneReducer";
import { OMSReducer } from "./reducers/OMS/OMSReducer";

//import { reducer as reduxFormReducer } from 'redux-form';
const middleware = applyMiddleware(thunk);

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const reducers = combineReducers({
  one: OneReducer,
  two: OMSReducer
});

//const store = createStore(rootReducers);

export const store = createStore(reducers, composeEnhancers(middleware));
