import axios from "axios";
import swal from "sweetalert";
import { loginConfirmedAction, logout } from "../store/actions/AuthActions";

export function signUp(email, password) {
  //axios call
  const postData = {
    email,
    password,
    returnSecureToken: true,
  };
  return axios.post(
    `https://identitytoolkit.googleapis.com/v1/accounts:signUp?key=AIzaSyD3RPAp3nuETDn9OQimqn_YF6zdzqWITII`,
    postData
  );
}

export function login(email, password) {
  const postData = {
    email,
    password,
    returnSecureToken: true,
  };

  console.log("Post data", postData);

  return axios.post(
    `https://identitytoolkit.googleapis.com/v1/accounts:signInWithPassword?key=AIzaSyD3RPAp3nuETDn9OQimqn_YF6zdzqWITII`,
    postData
  );
}

// export function login_new(email, password) {

//     fetch(
//         'https://kmkm84urcd.execute-api.ap-south-1.amazonaws.com/dev/api/',
//         {
//           method: 'post',
//           headers: {
//             Accept: 'application/json',
//             'Content-Type': 'application/json',
//           },
//           body: JSON.stringify({
//             method: 'login',
//             email: email,
//             pwd: password,
//           }),
//         },
//       )
//         .then(response => response.json())
//         .then(responseJson => {
//           if (!responseJson['error']) {
//               const web_auth_token_id = responseJson['web_auth_token_id']
//               console.log("coming here the response",web_auth_token_id)

//             // dispatch(fetchHomeDataSuccess(responseJson, navigation));
//           } else {
//             const error = responseJson['message'];
//             alert(error);
//             // dispatch(fetchHomeDataError(error));
//             throw error;
//           }
//         })
//         .catch(error => {
//           console.log('request failed', error);
//           alert('Backend error, try again or reach out to us.');
//         });
// }

export function change_password(webAuthTokenId, oldPwd, newPwd) {
  fetch("https://m6axrnhm25.execute-api.ap-south-1.amazonaws.com/dev/api/", {
    method: "post",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      method: "changepassword",
      old_pws: oldPwd,
      new_pwd: newPwd,
      web_auth_token_id: webAuthTokenId,
    }),
  })
    .then((response) => response.json())
    .then((responseJson) => {
      if (!responseJson["error"]) {
        console.log("coming here the response change password");

        // dispatch(fetchHomeDataSuccess(responseJson, navigation));
      } else {
        const error = responseJson["message"];
        alert(error);
        // dispatch(fetchHomeDataError(error));
        throw error;
      }
    })
    .catch((error) => {
      console.log("request failed", error);
      alert("Backend error, try again or reach out to us.");
    });
}

export function formatError(errorResponse) {
  switch (errorResponse.error.message) {
    case "EMAIL_EXISTS":
      //return 'Email already exists';
      swal("Oops", "Email already exists", "error");
      break;
    case "EMAIL_NOT_FOUND":
      //return 'Email not found';
      swal("Oops", "Email not found", "error", { button: "Try Again!" });
      break;
    case "INVALID_PASSWORD":
      //return 'Invalid Password';
      swal("Oops", "Invalid Password", "error", { button: "Try Again!" });
      break;
    case "USER_DISABLED":
      return "User Disabled";

    default:
      return "";
  }
}

export function saveTokenInLocalStorage(tokenDetails) {
  localStorage.setItem("userDetails", JSON.stringify(tokenDetails));
}

export function runLogoutTimer(dispatch, timer, history) {
  setTimeout(() => {
    dispatch(logout(history));
  }, timer);
}

export function checkAutoLogin(dispatch, history) {
  const tokenDetailsString = localStorage.getItem("userDetails");

  if (!tokenDetailsString) {
    dispatch(logout(history));
    return;
  }
  let tokenDetails = "";
  tokenDetails = JSON.parse(tokenDetailsString);

  dispatch(loginConfirmedAction(tokenDetails));

  if (tokenDetails["role"] == "Counselor") {
    history.push("/dashboard");
  } else {
    history.push("/classes");
  }
}
