import React, { useMemo, useEffect, useState } from "react";
import {
  useTable,
  useFilters,
  useGlobalFilter,
  useSortBy,
  usePagination,
} from "react-table";
import MOCK_DATA from "./MOCK_DATA_2.json";
// import { COLUMNS } from './Columns';
import { Row, Card, Col, ListGroup, Badge, Tab, Table } from "react-bootstrap";

import { COLUMNS, generateColumns } from "./Columns";

import { GlobalFilter } from "./GlobalFilter";
//import './table.css';
import "./filtering.css";

import Papa from "papaparse";
import loadable from "@loadable/component";
import pMinDelay from "p-min-delay";
import HistogramChart from "./HistogramChart";

export const FilteringTable = (props) => {
  const [data, setData] = React.useState([]);
  const [activeTab, setActiveTab] = useState("Column Types");

  const PopularClassChart = loadable(() =>
    pMinDelay(import("./PopularClassChart"), 1000)
  );

  useEffect(() => {
    const fetchData = async () => {
      const response = await fetch(props.data.s3_link); // Replace 'your_csv_file.csv' with your actual CSV file URL
      const result = await response.text();
      console.log("Raw CSV data:", result);

      Papa.parse(result, {
        header: true,
        dynamicTyping: true,
        skipEmptyLines: true,
        date: true,
        complete: (parsedData) => {
          console.log("Parsed data:", parsedData.data);
          setData(parsedData.data);
        },
      });
    };

    fetchData();
  }, []);

  const columns = useMemo(() => {
    if (data.length === 0) {
      // Return default columns if data is not available
      return COLUMNS;
    }
    const csvHeaders = Object.keys(data[0]); // Assuming the first row contains headers
    return generateColumns(csvHeaders);
  }, [data]);

  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: 5 },
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    state,
    page,
    gotoPage,
    pageCount,
    pageOptions,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    setGlobalFilter,
  } = tableInstance;

  const { globalFilter, pageIndex } = state;

  const tabs = [
    { name: "Column Types", backgroundColor: "#e83e8c" },
    { name: "Value Counts", backgroundColor: "#000" },
    { name: "Statistics", backgroundColor: "#1abc9c" },
    { name: "Distribution", backgroundColor: "#9b59b6" },

    // { name: "Cleaning", backgroundColor: "#3498db" },

    // { name: "Manipulation", backgroundColor: "#ffa500" },
    // { name: "Transformation", backgroundColor: "#2ecc71" },
    // Add more tabs as needed
  ];

  const handleTabClick = (tabName) => {
    setActiveTab(tabName);
  };

  const chartBlog = [
    { title: "Design", color: "#FEC64F", percent: "50", status: "763" },
    {
      title: "Parental Level Of Education",
      color: "#FC6B57",
      percent: "26",
      status: "543",
    },
    {
      title: "Sweet Potatoes Production (Tonnes) ",
      color: "#4CBC9A",
      percent: "24",
      status: "333",
    },
  ];

  const chartBlog1 = [
    { title: "Design", color: "#FEC64F", percent: "50", status: "763" },
    { title: "Programming ", color: "#FC6B57", percent: "26", status: "543" },
  ];

  return (
    <>
      <div className="row">
        <div className="col-xl-6 col-xl-12">
          <div className="card">
            <div className="card-header">
              <h4 className="card-title">{props.data.name}</h4>
              <div>
                <div>
                  <input
                    className="ml-2 input-search form-control"
                    value={globalFilter || ""}
                    placeholder="Search..."
                    onChange={(e) => setGlobalFilter(e.target.value)}
                    style={{ width: "100%" }}
                  />
                </div>
              </div>
            </div>

            <div className="card-body">
              <div className="table-responsive">
                <table {...getTableProps()} className="table verticle-middle">
                  <thead>
                    {headerGroups.map((headerGroup) => (
                      <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map((column) => (
                          <th
                            {...column.getHeaderProps(
                              column.getSortByToggleProps()
                            )}
                          >
                            {column.render("Header")}
                            <span className="ml-1">
                              {column.isSorted ? (
                                column.isSortedDesc ? (
                                  <i
                                    className="fa fa-arrow-down ms-2 fs-14"
                                    style={{ opacity: "0.7" }}
                                  />
                                ) : (
                                  <i
                                    className="fa fa-arrow-up ms-2 fs-14"
                                    style={{ opacity: "0.7" }}
                                  />
                                )
                              ) : (
                                <i
                                  className="fa fa-sort ms-2 fs-14"
                                  style={{ opacity: "0.3" }}
                                />
                              )}
                            </span>
                            {column.canFilter ? column.render("Filter") : null}
                          </th>
                        ))}
                      </tr>
                    ))}
                  </thead>
                  <tbody {...getTableBodyProps()} className="">
                    {page.map((row) => {
                      prepareRow(row);
                      return (
                        <tr {...row.getRowProps()}>
                          {row.cells.map((cell) => {
                            return (
                              <td {...cell.getCellProps()}>
                                {" "}
                                {cell.render("Cell")}{" "}
                              </td>
                            );
                          })}
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
                <div className="d-flex justify-content-between">
                  <span>
                    Page{" "}
                    <strong>
                      {pageIndex + 1} of {pageOptions.length}
                    </strong>
                    {""}
                  </span>
                  <span className="table-index">
                    Go to page :{" "}
                    <input
                      type="number"
                      className="ml-2"
                      defaultValue={pageIndex + 1}
                      onChange={(e) => {
                        const pageNumber = e.target.value
                          ? Number(e.target.value) - 1
                          : 0;
                        gotoPage(pageNumber);
                      }}
                    />
                  </span>
                </div>
                <div className="text-center mb-3">
                  <div className="filter-pagination  mt-3">
                    <button
                      className=" previous-button"
                      onClick={() => gotoPage(0)}
                      disabled={!canPreviousPage}
                    >
                      {"<<"}
                    </button>

                    <button
                      className="previous-button"
                      onClick={() => previousPage()}
                      disabled={!canPreviousPage}
                    >
                      Previous
                    </button>
                    <button
                      className="next-button"
                      onClick={() => nextPage()}
                      disabled={!canNextPage}
                    >
                      Next
                    </button>
                    <button
                      className=" next-button"
                      onClick={() => gotoPage(pageCount - 1)}
                      disabled={!canNextPage}
                    >
                      {">>"}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-xl-6 col-xl-12">
          <div className="card">
            <div className="card-body" style={{ overflow: "hidden" }}>
              <div className="widget-courses align-items-center d-flex flex-wrap">
                <div className="d-flex align-items-center flex-wrap flex-1 justify-content-center">
                  <div>
                    <div
                      style={{
                        display: "flex",
                        overflowX: "auto",
                        borderRadius: "10px",
                        marginRight: "10px",
                        scrollbarWidth: "thin", // For Firefox
                        scrollbarColor: "transparent transparent", // For Firefox
                      }}
                    >
                      {tabs.map((tab, index) => (
                        <div
                          key={tab.name}
                          className={`btn ${
                            activeTab === tab.name ? "btn-dark" : "bg-dark"
                          }`}
                          style={{
                            padding: "10px 20px",
                            cursor: "pointer",
                            backgroundColor:
                              activeTab === tab.name
                                ? tab.backgroundColor
                                : "#fff",
                            color: "#fff",
                            borderRadius: "20px 20px 20px 20px",
                            transition: "background-color 0.3s ease",
                            minWidth: "120px", // Adjust this value based on your content
                            flex: "0 0 auto", // Ensure the tabs don't grow
                            marginRight:
                              index === tabs.length - 1 ? "0" : "10px", // No right margin for the last tab
                          }}
                          onClick={() => handleTabClick(tab.name)}
                        >
                          {tab.name}
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {activeTab == "Value Counts" && (
        <div className="row">
          <div className="col-xl-4 col-md-6">
            <div className="card">
              <div className="card-header border-0 pb-0">
                <h4>Popular Class</h4>
              </div>
              <div className="card-body">
                <PopularClassChart />
                <div className="mb-3 mt-4">
                  {chartBlog.map((item, ind) => (
                    <div
                      className="d-flex justify-content-between mb-2"
                      key={ind}
                    >
                      <span>
                        <svg
                          className="me-3"
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <rect
                            width="20"
                            height="20"
                            rx="6"
                            fill={item.color}
                          />
                        </svg>
                        {item.title} ({item.percent}%)
                      </span>
                      <h6>{item.status}</h6>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-4 col-md-6">
            <div className="card">
              <div className="card-header border-0 pb-0">
                <h4>Popular Class</h4>
              </div>
              <div className="card-body">
                <PopularClassChart />
                <div className="mb-3 mt-4">
                  {chartBlog1.map((item, ind) => (
                    <div
                      className="d-flex justify-content-between mb-2"
                      key={ind}
                    >
                      <span>
                        <svg
                          className="me-3"
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <rect
                            width="20"
                            height="20"
                            rx="6"
                            fill={item.color}
                          />
                        </svg>
                        {item.title} ({item.percent}%)
                      </span>
                      <h6>{item.status}</h6>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>

          <div className="col-xl-4 col-md-6">
            <div className="card">
              <div className="card-header border-0 pb-0">
                <h4>Popular Class</h4>
              </div>
              <div className="card-body">
                <PopularClassChart />
                <div className="mb-3 mt-4">
                  {chartBlog.map((item, ind) => (
                    <div
                      className="d-flex justify-content-between mb-2"
                      key={ind}
                    >
                      <span>
                        <svg
                          className="me-3"
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <rect
                            width="20"
                            height="20"
                            rx="6"
                            fill={item.color}
                          />
                        </svg>
                        {item.title} ({item.percent}%)
                      </span>
                      <h6>{item.status}</h6>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {activeTab == "Column Types" && (
        <Row>
          <div className="col-lg-12">
            <Card>
              <Card.Header>
                <Card.Title>Numerical Columns</Card.Title>
              </Card.Header>
              <Card.Body>
                <div className="basic-list-group">
                  <Row>
                    <Tab.Container defaultActiveKey="#Math Score">
                      <Col lg="6" xl="4">
                        <ListGroup className="mb-4" id="list-tab">
                          <ListGroup.Item action href="#Math Score">
                            Math Score
                          </ListGroup.Item>
                          <ListGroup.Item action href="#Reading Score">
                            Reading Score
                          </ListGroup.Item>
                          <ListGroup.Item action href="#Writing Score">
                            Writing Score
                          </ListGroup.Item>
                        </ListGroup>
                      </Col>
                      <Col lg="6" xl="8">
                        <Tab.Content>
                          <Tab.Pane eventKey="#Math Score">
                            <p>
                              Velit aute mollit ipsum ad dolor consectetur nulla
                              officia culpa adipisicing exercitation fugiat
                              tempor. Voluptate deserunt sit sunt nisi aliqua
                              fugiat proident ea ut. Mollit voluptate
                              reprehenderit occaecat nisi ad non minim tempor
                              sunt voluptate consectetur exercitation id ut
                              nulla. Ea et fugiat aliquip nostrud sunt
                              incididunt consectetur culpa aliquip eiusmod
                              dolor. Anim ad Lorem aliqua in cupidatat nisi enim
                              eu nostrud do aliquip veniam minim.
                            </p>
                          </Tab.Pane>
                          <Tab.Pane eventKey="#Reading Score">
                            <p>
                              Cupidatat quis ad sint excepteur laborum in esse
                              qui. Et excepteur consectetur ex nisi eu do cillum
                              ad laborum. Mollit et eu officia dolore sunt Lorem
                              culpa qui commodo velit ex amet id ex. Officia
                              anim incididunt laboris deserunt anim aute dolor
                              incididunt veniam aute dolore do exercitation.
                              Dolor nisi culpa ex ad irure in elit eu dolore. Ad
                              laboris ipsum reprehenderit irure non commodo enim
                              culpa commodo veniam incididunt veniam ad.
                            </p>
                          </Tab.Pane>
                          <Tab.Pane eventKey="#Writing Score">
                            <p>
                              Ut ut do pariatur aliquip aliqua aliquip
                              exercitation do nostrud commodo reprehenderit aute
                              ipsum voluptate. Irure Lorem et laboris nostrud
                              amet cupidatat cupidatat anim do ut velit mollit
                              consequat enim tempor. Consectetur est minim
                              nostrud nostrud consectetur irure labore voluptate
                              irure. Ipsum id Lorem sit sint voluptate est
                              pariatur eu ad cupidatat et deserunt culpa sit
                              eiusmod deserunt. Consectetur et fugiat anim do
                              eiusmod aliquip nulla laborum elit adipisicing
                              pariatur cillum.
                            </p>
                          </Tab.Pane>
                        </Tab.Content>
                      </Col>
                    </Tab.Container>
                  </Row>
                </div>
              </Card.Body>
            </Card>
          </div>

          <div className="col-lg-12">
            <Card>
              <Card.Header>
                <Card.Title>Categorical Columns</Card.Title>
              </Card.Header>
              <Card.Body>
                <div className="basic-list-group">
                  <Row>
                    <Tab.Container defaultActiveKey="#Gender">
                      <Col lg="6" xl="4">
                        <ListGroup className="mb-4" id="list-tab">
                          <ListGroup.Item action href="#Gender">
                            Gender
                          </ListGroup.Item>
                          <ListGroup.Item action href="#Race/Ethnicity">
                            Race/Ethnicity
                          </ListGroup.Item>
                          <ListGroup.Item
                            action
                            href="#Parental Level Of Education"
                          >
                            Parental Level Of Education
                          </ListGroup.Item>

                          <ListGroup.Item action href="#Lunch">
                            Lunch
                          </ListGroup.Item>

                          <ListGroup.Item
                            action
                            href="#Test Preparation Course"
                          >
                            Test Preparation Course
                          </ListGroup.Item>
                        </ListGroup>
                      </Col>
                      <Col lg="6" xl="8">
                        <Tab.Content>
                          <Tab.Pane eventKey="#Gender">
                            <p>
                              Velit aute mollit ipsum ad dolor consectetur nulla
                              officia culpa adipisicing exercitation fugiat
                              tempor. Voluptate deserunt sit sunt nisi aliqua
                              fugiat proident ea ut. Mollit voluptate
                              reprehenderit occaecat nisi ad non minim tempor
                              sunt voluptate consectetur exercitation id ut
                              nulla. Ea et fugiat aliquip nostrud sunt
                              incididunt consectetur culpa aliquip eiusmod
                              dolor. Anim ad Lorem aliqua in cupidatat nisi enim
                              eu nostrud do aliquip veniam minim.
                            </p>
                          </Tab.Pane>
                          <Tab.Pane eventKey="#Race/Ethnicity">
                            <p>
                              Cupidatat quis ad sint excepteur laborum in esse
                              qui. Et excepteur consectetur ex nisi eu do cillum
                              ad laborum. Mollit et eu officia dolore sunt Lorem
                              culpa qui commodo velit ex amet id ex. Officia
                              anim incididunt laboris deserunt anim aute dolor
                              incididunt veniam aute dolore do exercitation.
                              Dolor nisi culpa ex ad irure in elit eu dolore. Ad
                              laboris ipsum reprehenderit irure non commodo enim
                              culpa commodo veniam incididunt veniam ad.
                            </p>
                          </Tab.Pane>
                          <Tab.Pane eventKey="#Parental Level Of Education">
                            <p>
                              Ut ut do pariatur aliquip aliqua aliquip
                              exercitation do nostrud commodo reprehenderit aute
                              ipsum voluptate. Irure Lorem et laboris nostrud
                              amet cupidatat cupidatat anim do ut velit mollit
                              consequat enim tempor. Consectetur est minim
                              nostrud nostrud consectetur irure labore voluptate
                              irure. Ipsum id Lorem sit sint voluptate est
                              pariatur eu ad cupidatat et deserunt culpa sit
                              eiusmod deserunt. Consectetur et fugiat anim do
                              eiusmod aliquip nulla laborum elit adipisicing
                              pariatur cillum.
                            </p>
                          </Tab.Pane>

                          <Tab.Pane eventKey="#Lunch">
                            <p>
                              Ut ut do pariatur aliquip aliqua aliquip
                              exercitation do nostrud commodo reprehenderit aute
                              ipsum voluptate. Irure Lorem et laboris nostrud
                              amet cupidatat cupidatat anim do ut velit mollit
                              consequat enim tempor. Consectetur est minim
                              nostrud nostrud consectetur irure labore voluptate
                              irure. Ipsum id Lorem sit sint voluptate est
                              pariatur eu ad cupidatat et deserunt culpa sit
                              eiusmod deserunt. Consectetur et fugiat anim do
                              eiusmod aliquip nulla laborum elit adipisicing
                              pariatur cillum.
                            </p>
                          </Tab.Pane>

                          <Tab.Pane eventKey="#Test Preparation Course">
                            <p>
                              Ut ut do pariatur aliquip aliqua aliquip
                              exercitation do nostrud commodo reprehenderit aute
                              ipsum voluptate. Irure Lorem et laboris nostrud
                              amet cupidatat cupidatat anim do ut velit mollit
                              consequat enim tempor. Consectetur est minim
                              nostrud nostrud consectetur irure labore voluptate
                              irure. Ipsum id Lorem sit sint voluptate est
                              pariatur eu ad cupidatat et deserunt culpa sit
                              eiusmod deserunt. Consectetur et fugiat anim do
                              eiusmod aliquip nulla laborum elit adipisicing
                              pariatur cillum.
                            </p>
                          </Tab.Pane>
                        </Tab.Content>
                      </Col>
                    </Tab.Container>
                  </Row>
                </div>
              </Card.Body>
            </Card>
          </div>
        </Row>
      )}

      {activeTab == "Statistics" && (
        <Row>
          <Col lg={12}>
            <Card>
              <Card.Body>
                <p>
                  The data stats offer a snapshot of our numbers—how many there
                  are, the average (middle point), and whether they're tightly
                  packed or spread out. We also spot the smallest and biggest
                  numbers. Additionally, quartiles provide insights into where
                  the majority of our numbers gather, with the first quartile
                  (Q1), second quartile (Q2), and third quartile (Q3) marking
                  key points.
                </p>
                <Table bordered striped responsive>
                  <thead>
                    <tr>
                      <th></th>
                      <th className="text-center text-dark">Math Score</th>
                      <th className="text-center text-dark">Reading Score</th>
                      <th className="text-center text-dark">Writing Score</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th className="text-nowrap text-dark" scope="row">
                        count
                      </th>
                      <td>1000</td>
                      <td>1000</td>
                      <td>1000</td>
                    </tr>

                    <tr>
                      <th className="text-nowrap text-dark" scope="row">
                        mean
                      </th>
                      <td>66.08900</td>
                      <td>69.169000</td>
                      <td>68.054000</td>
                    </tr>

                    <tr>
                      <th className="text-nowrap text-dark" scope="row">
                        std
                      </th>
                      <td>15.16308</td>
                      <td>14.600192</td>
                      <td>15.195657</td>
                    </tr>

                    <tr>
                      <th className="text-nowrap text-dark" scope="row">
                        min
                      </th>
                      <td>0.00000 </td>
                      <td>17.000000</td>
                      <td>10.000000</td>
                    </tr>

                    <tr>
                      <th className="text-nowrap text-dark" scope="row">
                        25%
                      </th>
                      <td>57.00000</td>
                      <td>59.000000</td>
                      <td>57.750000</td>
                    </tr>

                    <tr>
                      <th className="text-nowrap text-dark" scope="row">
                        50%
                      </th>
                      <td>66.00000</td>
                      <td>70.000000</td>
                      <td>69.000000</td>
                    </tr>

                    <tr>
                      <th className="text-nowrap text-dark" scope="row">
                        75%
                      </th>
                      <td>77.00000</td>
                      <td>79.000000</td>
                      <td>79.000000</td>
                    </tr>

                    <tr>
                      <th className="text-nowrap text-dark" scope="row">
                        max
                      </th>
                      <td>100.00000</td>
                      <td>100.00000</td>
                      <td>100.00000</td>
                    </tr>
                  </tbody>
                </Table>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      )}

      {activeTab == "Distribution" && <HistogramChart />}
    </>
  );
};
export default FilteringTable;
