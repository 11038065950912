import React, { useContext, useState, useEffect } from "react";

import { connect, useDispatch, useSelector } from "react-redux";
import "bootstrap-daterangepicker/daterangepicker.css";
import "react-datepicker/dist/react-datepicker.css";
import { getTreats, orderTreat } from "../../store/actions/AuthActions";
import certificate from "./../../images/svg/degree-certificate.svg";
import clock from "./../../images/svg/clock-1.svg";
import pic2 from "./../../images/courses/pic2.jpeg";
import educationwebsite from "./../../images/svg/education-website.svg";
import skill from "./../../images/svg/skill.svg";
import puzzle from "./../../images/svg/puzzle.svg";
import at from "./../../images/svg/at.svg";
import totalcoins from "./../../images/svg/coins.svg";
import currentcoins from "./../../images/svg/ccoins.svg";
import bag from "./../../images/svg/bag.svg";
import VideoPlayer from "./VideoPlayer";
import swal from "sweetalert";
import { Link } from "react-router-dom";
import { Row, Col, Card, Tab, Nav } from "react-bootstrap";

import { Button, Dropdown, Modal } from "react-bootstrap";
import linkedin from "./../../images/Linkedin.png";
import kaggle from "./../../images/Kaggle.png";

import acheiv from "../../images/svg/achievement.svg";
import medal from "../../images/medal.png";

import Check from "./../../images/vector/check.png";
import Bookmarks from "./../../images/vector/bookmarks.png";
import Bulb from "./../../images/vector/bulb.png";
import { ThemeContext } from "../../context/ThemeContext";

import dairy from "./../../images/treats/dark.png";

const WidgetBlog = ({ changeImage, title, value }) => {
  return (
    <>
      <div className="col-xl-6 col-lg-6 col-sm-6">
        <div className="card profile-widget">
          <div className="card-body">
            <div className="widget-courses align-items-center d-flex justify-content-between style-1 flex-wrap">
              <div className="d-flex ">
                <img src={changeImage} alt="" className="rounded" />
                <div className="ms-4">
                  <h5>{value}</h5>

                  <span>{title}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const MyTreats = (props) => {
  const [basicModal, setBasicModal] = useState(false);

  const [itemUuid, setItemUuid] = useState("");
  const [name, setName] = useState("");
  const [price, setPrice] = useState("");
  const [image, setImage] = useState();

  const dispatch = useDispatch();

  const {
    changeBackground,
    changePrimaryColor,
    changeSecondaryColor,
    chnageSidebarColor,
    chnageHaderColor,
    changeNavigationHader,
  } = useContext(ThemeContext);

  const darkModeData = useSelector((state) => state.one.darkMode);

  useEffect(() => {
    changeBackground({
      value: props.reduxCurrentState.one.darkMode ? "dark" : "light",
      label: "Dark",
    });

    changePrimaryColor("color_11");
    changeSecondaryColor("color_1");
    chnageSidebarColor(
      props.reduxCurrentState.one.darkMode ? "dark" : "color_11"
    );
    changeNavigationHader(
      props.reduxCurrentState.one.darkMode ? "dark" : "color_11"
    );
  }, [darkModeData]);

  useEffect(() => {
    dispatch(getTreats(props.reduxCurrentState.one.webAuthTokenId));
    shuffleArray(props.reduxCurrentState.one.treatsData);
  }, []);

  function shuffleArray(array) {
    const shuffledArray = [...array];
    for (let i = shuffledArray.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [shuffledArray[i], shuffledArray[j]] = [
        shuffledArray[j],
        shuffledArray[i],
      ];
    }
    return shuffledArray;
  }

  const onClickGrab = (itemUuid, previewImg, title, price) => {
    setItemUuid(itemUuid);
    setBasicModal(true);
    setName(title);
    setPrice(price);
    setImage(previewImg);
  };

  const Products = ({ product }) => {
    return (
      <div className="col-xl-3 col-lg-6 col-md-6 col-sm-6">
        <div className="card">
          <div className="card-body">
            <div className="new-arrival-product">
              <div className="new-arrivals-img-contnent">
                <img
                  className="img-fluid rounded"
                  src={product.thumbnail}
                  alt=""
                />
              </div>
              <div className="new-arrival-content text-center mt-3">
                <h4>
                  <Link>{product.name}</Link>
                  <br></br>
                  <span>Cost : {product.coins}</span>
                </h4>
              </div>
              <br></br>
              <div className="text-center">
                <button
                  onClick={() =>
                    onClickGrab(
                      product.uuid,
                      product.thumbnail,
                      product.name,
                      product.coins
                    )
                  }
                  className="btn btn-primary me-2"
                  // style={{ backgroundColor: "#3c3c3d", borderColor: "green" }}
                >
                  <span className="me-2">
                    {" "}
                    <i className="fa fa-heart" />{" "}
                  </span>
                  Grab Me
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderModel = () => {
    return (
      <Modal className="fade" show={basicModal}>
        <Modal.Header>
          <Modal.Title>Confirmation</Modal.Title>
          <Button
            variant=""
            className="btn-close"
            onClick={() => setBasicModal(false)}
          ></Button>
        </Modal.Header>
        <Modal.Body>
          <div className="new-arrival-content text-center mt-3">
            <span className="price">
              My balance : {props.reduxCurrentState.one.treatStore.balance}
            </span>
          </div>
          <br></br>
          <div className="new-arrivals-img-contnent">
            <img className="img-fluid" src={image} alt="" />
          </div>
          <div className="new-arrival-content text-center mt-3">
            <h4>
              <Link>{name}</Link>
              <br></br>
              <span>{price}</span>
            </h4>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={() => setBasicModal(false)}
            style={{ backgroundColor: "#3c3c3d", borderColor: "#3c3c3d" }}
          >
            Cancel
          </Button>
          <Button onClick={() => onPressConfirm()} variant="primary">
            Confirm Order
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const onPressConfirm = () => {
    if (itemUuid) {
      dispatch(
        orderTreat(props.reduxCurrentState.one.webAuthTokenId, itemUuid)
      );

      window.scrollTo({
        top: 0,
        behavior: "auto",
      });

      setBasicModal(false);
    }
  };

  return (
    <>
      {props.reduxCurrentState.one.myOrders.pending_orders.length > 0 && (
        <>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <h3 className="m-0">Pending Orders</h3>
          </div>
          <div>
            <br></br>
          </div>

          <div className="row">
            <div className="col-lg-12">
              <div className="card">
                <div className="card-body " style={{ padding: "1.25rem" }}>
                  <div className="table-responsive">
                    <table className="table table-sm mb-0 table-responsive-lg ">
                      <thead>
                        <tr>
                          <th className="align-middle">#</th>

                          <th className="align-middle">Item</th>
                          <th className="align-middle pr-7">Name</th>
                          <th className="align-middle text-right">Cost</th>
                          <th className="align-middle minw200">Date</th>

                          <th className="align-middle text-right">Status</th>
                        </tr>
                      </thead>

                      <tbody id="orders">
                        {props.reduxCurrentState.one.myOrders.pending_orders.map(
                          (order, idx) => (
                            <tr className="btn-reveal-trigger">
                              <td className="py-2">{idx + 1}</td>
                              <td className="py-3">
                                <Link>
                                  <div className="media d-flex align-items-center">
                                    <div className="avatar avatar-xl me-2">
                                      <div className="">
                                        <img
                                          className="rounded-circle img-fluid"
                                          src={order.treat.thumbnail}
                                          width="50"
                                          alt=""
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </Link>
                              </td>

                              <td className="py-2">{order.treat.name}</td>
                              <td className="py-2">{order.treat.coins}</td>
                              <td className="py-2 text-right">
                                {order.ordered_at}
                              </td>

                              <td className="py-2 text-right">
                                <span className="badge badge-primary">
                                  Processing
                                  <span className="ms-1 fa fa-redo" />
                                </span>
                              </td>
                            </tr>
                          )
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}

      <div className="row">
        {props.reduxCurrentState.one.myAccount ? (
          <div className="col-xl-6 col-xxl-6 col-lg-12">
            <div className="row">
              <WidgetBlog
                changeImage={at}
                title="Name"
                value={props.reduxCurrentState.one.myAccount.first_name}
              />
              <WidgetBlog
                changeImage={totalcoins}
                title="Total Earned"
                value={props.reduxCurrentState.one.treatStore.total_coins}
              />
            </div>
          </div>
        ) : null}

        {props.reduxCurrentState.one.myAccount ? (
          <div className="col-xl-6 col-xxl-6 col-lg-12 ">
            <div className="row">
              <WidgetBlog
                changeImage={currentcoins}
                title="My Balance"
                value={props.reduxCurrentState.one.treatStore.balance}
              />
              <WidgetBlog
                changeImage={bag}
                title="Total Orders"
                value={props.reduxCurrentState.one.treatStore.total_orders}
              />
            </div>
          </div>
        ) : null}
      </div>

      <div className="row">
        {props.reduxCurrentState.one.treatsData &&
          props.reduxCurrentState.one.treatsData.map((product) => (
            <Products key={product.uuid} product={product} />
          ))}
      </div>
      {renderModel()}

      <div>
        <br></br>
      </div>

      {props.reduxCurrentState.one.myOrders.completed_orders.length > 0 && (
        <>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <h3 className="m-0">Completed Orders</h3>
          </div>
          <div>
            <br></br>
          </div>

          <div className="row">
            <div className="col-lg-12">
              <div className="card">
                <div className="card-body " style={{ padding: "1.25rem" }}>
                  <div className="table-responsive">
                    <table className="table table-sm mb-0 table-responsive-lg ">
                      <thead>
                        <tr>
                          <th className="align-middle">#</th>

                          <th className="align-middle">Item</th>
                          <th className="align-middle pr-7">Name</th>
                          <th className="align-middle text-right">Cost</th>
                          <th className="align-middle minw200">Date</th>

                          <th className="align-middle text-right">Status</th>
                        </tr>
                      </thead>

                      <tbody id="orders">
                        {props.reduxCurrentState.one.myOrders.completed_orders.map(
                          (order, idx) => (
                            <tr className="btn-reveal-trigger" key={idx}>
                              <td className="py-2">{idx + 1}</td>
                              <td className="py-3">
                                <Link>
                                  <div className="media d-flex align-items-center">
                                    <div className="avatar avatar-xl me-2">
                                      <div className="">
                                        <img
                                          className="rounded-circle img-fluid"
                                          src={order.treat.thumbnail}
                                          width="50"
                                          alt=""
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </Link>
                              </td>

                              <td className="py-2">{order.treat.name}</td>
                              <td className="py-2">{order.treat.coins}</td>
                              <td className="py-2 text-right">
                                {order.ordered_at}
                              </td>

                              <td className="py-2 text-right">
                                <span className="badge badge-success">
                                  Completed
                                  <span className="ms-1 fa fa-check" />
                                </span>
                              </td>
                            </tr>
                          )
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    reduxCurrentState: state,
  };
};

export default connect(mapStateToProps)(MyTreats);
