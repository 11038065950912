import swal from "sweetalert";

export const ADD_LEAD_CONFIRMED_ACTION = "ADD_LEAD";
export const GET_LEADS_ACTION = "GET_LEADS";
export const ADD_COMMENT_SUCCESS_ACTION = "ADD_COMMENT_SUCCESS";
export const GET_LEAD_COMMENTS_SUCCESS_ACTION = "GET_LEAD_COMMENTS_SUCCESS";


export function saveAndSubmitLeadAction(webAuthTokenId, data) {
  console.log("coming here", data);
  return (dispatch) =>
    fetch("https://k1v90bv7c3.execute-api.ap-south-1.amazonaws.com/dev/api/", {
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        method: "addnewlead",
        web_auth_token_id: webAuthTokenId,
        data: data,
      }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (!responseJson["error"]) {
          dispatch(addLeadConfirmedAction(responseJson));
          swal("Good job!", "Lead has been registered. ", "success");
          // history.push("/leads");
        } else {
          const message = responseJson["message"];
          swal("Oops", message, "error");
        }
      })
      .catch((error) => {
        alert("Backend error, try again or reach out to us.", error);
      });
}

export function addCommentAction(leadUuid, comment, webAuthTokenId) {

  return (dispatch) =>
    fetch("https://k1v90bv7c3.execute-api.ap-south-1.amazonaws.com/dev/api/", {
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        method: "addleadcomment",
        lead_id : leadUuid,
        comment: comment,
        web_auth_token_id: webAuthTokenId,
      }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (!responseJson["error"]) {
          dispatch(addCommentSuccessAction(responseJson));
          swal("Great!", "Comment Has Been Added. ", "success");
          console.log("rrrrrrr", responseJson)
        } else {
          const message = responseJson["message"];
          swal("Oops", message, "error");
        }
      })
      .catch((error) => {
        alert("Backend error, try again or reach out to us.", error);
      });
}

export function getLeadCommentsAction(leadUuid, webAuthTokenId) {

  return (dispatch) =>
    fetch("https://k1v90bv7c3.execute-api.ap-south-1.amazonaws.com/dev/api/", {
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        method: "getleadcomments",
        lead_id : leadUuid,
        web_auth_token_id: webAuthTokenId,
      }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (!responseJson["error"]) {
          dispatch(getLeadCommentSuccessAction(responseJson));
          console.log("rrrrrrr", responseJson)
        } else {
          const message = responseJson["message"];
          swal("Oops", message, "error");
        }
      })
      .catch((error) => {
        alert("Backend error, try again or reach out to us.", error);
      });
}

export function getLeads(webAuthTokenId ) {

  
  return (dispatch) =>
    fetch("https://k1v90bv7c3.execute-api.ap-south-1.amazonaws.com/dev/api/", {
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        method: "getleads",
        web_auth_token_id: webAuthTokenId,
      }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (!responseJson["error"]) {
          dispatch(getLeadsAction(responseJson));
          console.log("final data = ",responseJson)
          // history.push("/leads");
        } else {
          const message = responseJson["message"];
          swal("Oops", message, "error");
        }
      })
      .catch((error) => {
        alert("Backend error, try again or reach out to us.", error);
      });
}

export function getLeadCommentSuccessAction(data) {
  return {
    type: GET_LEAD_COMMENTS_SUCCESS_ACTION,
    payload: data,
  };
}

export function addCommentSuccessAction(data) {
  return {
    type: ADD_COMMENT_SUCCESS_ACTION,
    payload: data,
  };
}

export function addLeadConfirmedAction(data) {
  return {
    type: ADD_LEAD_CONFIRMED_ACTION,
    payload: data,
  };
}

export function getLeadsAction(data) {
  return {
    type: GET_LEADS_ACTION,
    payload: data,
  };
}
