import {
  LOADING_TOGGLE_ACTION,
  LOGIN_CONFIRMED_ACTION,
  REGISTER_CONFIRMED_ACTION,
  LOGIN_FAILED_ACTION,
  LOGOUT_ACTION,
  GET_MOCKTESTS_ACTION,
  TOGGLE_DARK_MODE,
} from "../actions/AuthActions";

const initialState = {
  webAuthTokenId: "",
  errorMessage: "",
  successMessage: "",
  showLoading: false,
  role: "Student",
  profileComplete: false,

  darkMode: true,

  list: [],

  students: [],

  classes: [],
  myPortfolio: {},

  projectsData: { "Batch 2": [], "Batch 1": [], "Batch 3": [] },
  codingProjectsData: { "Batch 2": [], "Batch 1": [], "Batch 3": [] },
  mlProjectsData: { "Batch 2": [], "Batch 1": [], "Batch 3": [] },

  dataProjects: [],
  edaProjects: [],
  mlProjects: [],
  dlProjects: [],

  guides: [
    {
      uuid: 1,
      title: "Data : Definition & Types",
      thumbnail:
        "https://degenden-academy.s3.ap-south-1.amazonaws.com/webapp_assets/home_content/guides/1/Understanding+Data_page-0001.jpg",
      html_link:
        "https://degenden-academy.s3.ap-south-1.amazonaws.com/webapp_assets/home_content/guides/1/Data++Definition%2C+Types+%26+Representation.html",
    },
    {
      uuid: 2,
      title: "Data Visualization",
      thumbnail:
        "https://degenden-academy.s3.ap-south-1.amazonaws.com/webapp_assets/home_content/guides/2/Data+Visualisation_page-0001.jpg",
      html_link:
        "https://degenden-academy.s3.ap-south-1.amazonaws.com/webapp_assets/home_content/guides/2/Data+Visualisation.html",
    },
    {
      uuid: 3,
      title: "Data Statistics",
      thumbnail:
        "https://degenden-academy.s3.ap-south-1.amazonaws.com/webapp_assets/home_content/guides/3/Data+Statistics_page-0001.jpg",
      html_link:
        "https://degenden-academy.s3.ap-south-1.amazonaws.com/webapp_assets/home_content/guides/3/Data+Statistics.html",
    },
    
  ],

  trainerDVideos :[
    {
      uuid: 1,
      thumbnail:
        "https://degenden-academy.s3.ap-south-1.amazonaws.com/webapp_assets/landing-page-assets/poster.png",
      video_link:
        "https://degenden-academy.s3.ap-south-1.amazonaws.com/webapp_assets/landing-page-assets/Data+Science+%26+AI.mp4",
    },
  ],

  trainerMVideos: [
    {
      uuid: 1,
      thumbnail:
        "https://degenden-academy.s3.ap-south-1.amazonaws.com/webapp_assets/videos/1.jpg",
      video_link:
        "https://degenden-academy.s3.ap-south-1.amazonaws.com/webapp_assets/videos/1.mp4",
    },
    {
      uuid: 2,
      thumbnail:
        "https://degenden-academy.s3.ap-south-1.amazonaws.com/webapp_assets/videos/2.jpg",
      video_link:
        "https://degenden-academy.s3.ap-south-1.amazonaws.com/webapp_assets/videos/2.mp4",
    },
    {
      uuid: 3,
      thumbnail:
        "https://degenden-academy.s3.ap-south-1.amazonaws.com/webapp_assets/videos/3.jpg",
      video_link:
        "https://degenden-academy.s3.ap-south-1.amazonaws.com/webapp_assets/videos/3.mp4",
    },

    {
      uuid: 4,
      thumbnail:
        "https://degenden-academy.s3.ap-south-1.amazonaws.com/webapp_assets/videos/4.jpg",
      video_link:
        "https://degenden-academy.s3.ap-south-1.amazonaws.com/webapp_assets/videos/4.mp4",
    },

    {
      uuid: 5,
      thumbnail:
        "https://degenden-academy.s3.ap-south-1.amazonaws.com/webapp_assets/videos/5.jpg",
      video_link:
        "https://degenden-academy.s3.ap-south-1.amazonaws.com/webapp_assets/videos/5.mp4",
    },

    {
      uuid: 6,
      thumbnail:
        "https://degenden-academy.s3.ap-south-1.amazonaws.com/webapp_assets/videos/6.jpg",
      video_link:
        "https://degenden-academy.s3.ap-south-1.amazonaws.com/webapp_assets/videos/6.mp4",
    },

    {
      uuid: 7,
      thumbnail:
        "https://degenden-academy.s3.ap-south-1.amazonaws.com/webapp_assets/videos/7.jpg",
      video_link:
        "https://degenden-academy.s3.ap-south-1.amazonaws.com/webapp_assets/videos/7.mp4",
    },

    {
      uuid: 8,
      thumbnail:
        "https://degenden-academy.s3.ap-south-1.amazonaws.com/webapp_assets/videos/8.jpg",
      video_link:
        "https://degenden-academy.s3.ap-south-1.amazonaws.com/webapp_assets/videos/8.mp4",
    },

    {
      uuid: 9,
      thumbnail:
        "https://degenden-academy.s3.ap-south-1.amazonaws.com/webapp_assets/videos/10.jpg",
      video_link:
        "https://degenden-academy.s3.ap-south-1.amazonaws.com/webapp_assets/videos/10.mp4",
    },

    {
      uuid: 10,
      thumbnail:
        "https://degenden-academy.s3.ap-south-1.amazonaws.com/webapp_assets/videos/10.jpg",
      video_link:
        "https://degenden-academy.s3.ap-south-1.amazonaws.com/webapp_assets/videos/10.mp4",
    },

    {
      uuid: 11,
      thumbnail:
        "https://degenden-academy.s3.ap-south-1.amazonaws.com/webapp_assets/videos/11.jpg",
      video_link:
        "https://degenden-academy.s3.ap-south-1.amazonaws.com/webapp_assets/videos/11.mp4",
    },

    {
      uuid: 12,
      thumbnail:
        "https://degenden-academy.s3.ap-south-1.amazonaws.com/webapp_assets/videos/12.jpg",
      video_link:
        "https://degenden-academy.s3.ap-south-1.amazonaws.com/webapp_assets/videos/12.mp4",
    },

    {
      uuid: 13,
      thumbnail:
        "https://degenden-academy.s3.ap-south-1.amazonaws.com/webapp_assets/videos/13.jpg",
      video_link:
        "https://degenden-academy.s3.ap-south-1.amazonaws.com/webapp_assets/videos/13.mp4",
    },
    
  ],

  
  projectLoading: false,

  resumes: [],

  classesDone: 0,
  projectsDone: 0,
  totalStudents: 0,
  leaderboard: [],

  leaderboardTable: { My: [], All: [] },

  treatsData: [],
  treatStore: { total_coins: 0, balance: 0, total_orders: 0 },
  myOrders: { pending_orders: [], completed_orders: [] },

  unReadNotifications: [],
  readNotifications: [],
  mockTests: { upcomingMockTests: [], pastMockTests: [] },
  assignments: [],
  playgroundDatasets: [],

  edaDatasets: [],
  mlDatasets: [],

  upcomingMockTests: [
    {
      title: "Python + Aptitude + Resoning",
      cardClass: "bg-primary",
      duration: "30 mins",
      totalQuestions: 30,
      startAt: "15th Nov, Tuesday 12:30 PM",
    },
  ],

  pastMockTests: [
    {
      title: "Python + Aptitude + Resoning",
      cardClass: "bg-primary",
      duration: "30 mins",
      totalQuestions: 30,
      startAt: "15th Nov, Tuesday 12:30 PM",
    },
  ],

  myAccount: {
    username: "",
    fullname: "",
    email: "",
    batch: "",
    batch_name: "",
    rollno: "",
    image:
      "https://degenden-academy.s3.ap-south-1.amazonaws.com/webapp_assets/pfps/M.png",
    upi_id: null,
  },

  profileURL:
    "https://degenden-academy.s3.ap-south-1.amazonaws.com/webapp_assets/pfps/M.png",

  challenges: [],
  challengesTest: { PF: [], AN: [], LR: [], SQ:[] },
  userMcqStats: { total_qa: 0, total_correct: 0, coins_earned: 0 },

  nextClass: {
    class_title: "Please wait for next class title here...",
    password: "kitshub",
    class_datetime: "September 27, 2023 4:37 AM",
    class_url: "",
  },

  prevClass: {
    title: "Home work Review & Feedback",
    description:
      "Home work had been given so we will analyse and give feedback.",
    datetime: "15, July, 2023 (11:30 AM - 12:30 PM)",
    joinLink:
      "https://kapilitshubcom.my.webex.com/wbxmjs/joinservice/sites/kapilitshubcom.my/meeting/download/13bdf2d273f7496e9064d98dd51208fc?siteurl=kapilitshubcom.my&MTID=m66d2205ba623439ae52c3b80a763d2c0",
  },

  myprofile: {
    name: "metamady.eth",
    joined: "July, 23",
    photoUrl: "",
    attendence: { total: 10, offline: 7, online: 3 },
    coins: { total: 100 },
  },

  myCourseProgress: {
    program: "Data Science",
    progressValue: 40,
    totalValue: 100,
    progressPct: "40",
  },

  myLearningActivity: {
    lastweek: {
      dates: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
      values: [60, 70, 80, 50, 60, 50, 90],
    },
    thisweek: {
      dates: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
      values: [40, 50, 40, 60, 90, 70, 90],
    },
  },

  programContent: [],
};



export function OneReducer(state = initialState, action) {
  if (action.type === LOGIN_CONFIRMED_ACTION) {
    return {
      ...state,
      webAuthTokenId: action.payload.web_auth_token_id,
      errorMessage: "",
      successMessage: "Login Successfully Completed",
      showLoading: false,
      role: action.payload.role,
    };
  }
  if (action.type === GET_MOCKTESTS_ACTION) {
    return {
      ...state,
      mockTests: action.payload.mock_tests,
    };
  }

  if (action.type === TOGGLE_DARK_MODE) {
    return {
      ...state,
      darkMode: action.payload,
    };
  }

  if (action.type === REGISTER_CONFIRMED_ACTION) {
    return {
      ...state,
      errorMessage: "",
      successMessage: "Register Successfully Completed",
      showLoading: false,
      students: action.payload.students,
    };
  }

  if (action.type === "GET_USERS") {
    return {
      ...state,
      students: action.payload.students,
    };
  }

  if (action.type === "GET_ASSIGNMENTS") {
    return {
      ...state,
      assignments: action.payload.assignments,
    };
  }

  if (action.type === "GET_NOTIFICATIONS") {
    return {
      ...state,
      unReadNotifications: action.payload.unread_notifications,
      readNotifications: action.payload.read_notifications,
    };
  }

  if (action.type === "GET_MYACCOUNT_DETAILS") {
    return {
      ...state,
      myAccount: action.payload.my_account,
    };
  }

  if (action.type === "SAVE_MY_UPIID") {
    return {
      ...state,
      myAccount: action.payload.my_account,
    };
  }

  if (action.type === "GET_CLASSES") {
    return {
      ...state,
      classes: action.payload.clasess,
      myAccount: action.payload.my_account,
      role: action.payload.role,
      profileComplete: action.payload.profile_complete,
    };
  }

  if (action.type === "GET_LEADERBOARD") {
    return {
      ...state,
      classesDone: action.payload.stats.classes_done,
      projectsDone: action.payload.stats.projects_done,
      totalStudents: action.payload.stats.total_students,
      leaderboard: action.payload.leaderboard,
      leaderboardTable: action.payload.leaderboard_table,
      role: action.payload.role,
      profileComplete: action.payload.profile_complete,
      profileURL: action.payload.pfp,
    };
  }

  if (action.type === "GET_NEXTCLASS") {
    return {
      ...state,
      nextClass: action.payload.latest_class,
    };
  }

  if (action.type === "GET_CHALLENGES") {
    console.log("here", action.payload.challenges_new);
    return {
      ...state,
      challenges: action.payload.challenges,
      challengesTest: action.payload.challenges_new,
      userMcqStats: action.payload.user_mcq_stats,
    };
  }
  if (action.type === "GET_MCQ") {
    return {
      ...state,
      userMcqStats: action.payload.user_mcq_stats,
    };
  }

  if (action.type === "GET_MY_PORTFOLIO") {
    return {
      ...state,
      myPortfolio: action.payload.my_portfolio,
    };
  }

  if (action.type === "GET_PROJECTS") {
    return {
      ...state,
      projectsData: action.payload.projects,
      codingProjectsData: action.payload.coding_projects,
      mlProjectsData: action.payload.ml_projects,

      dataProjects: action.payload.data_p,
      edaProjects: action.payload.eda_p,
      mlProjects: action.payload.ml_p,
      dlProjects: action.payload.dl_p,
      projectLoading: true,

      role: action.payload.role,
    };
  }

  if (action.type === "GET_RESUMES") {
    return {
      ...state,
      resumes: action.payload.resumes,
    };
  }
  if (action.type === "GET_DATASETS") {
    console.log("payload fcoming here", action.payload);
    return {
      ...state,
      playgroundDatasets: action.payload.datasets,
      edaDatasets: action.payload.eda_datasets,
      mlDatasets: action.payload.ml_datasets,
    };
  }

  if (action.type === "GET_TREATS") {
    return {
      ...state,
      treatsData: action.payload.treats,
      treatStore: action.payload.my_score,
      myOrders: action.payload.my_orders,
    };
  }

  if (action.type === "GET_ORDERTREAT") {
    return {
      ...state,
      treatStore: action.payload.my_score,
      myOrders: action.payload.my_orders,
    };
  }

  if (action.type === LOGOUT_ACTION) {
    return {
      ...state,
      webAuthTokenId: "",
      errorMessage: "",
      successMessage: "Logout Successfully Completed",
      showLoading: false,
    };
  }

  if (action.type === LOGIN_FAILED_ACTION) {
    return {
      ...state,
      errorMessage: action.payload,
      successMessage: "",
      showLoading: false,
    };
  }

  if (action.type === LOADING_TOGGLE_ACTION) {
    return {
      ...state,
      showLoading: action.payload,
    };
  }
  return state;
}
