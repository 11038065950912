

const initialState = {
  webAuthTokenId: "",
  errorMessage: "",
  successMessage: "",
  showLoading: false,
  role: "Student",
  profileComplete: false,

  darkMode: true,

  leads: [],

  chartData : [
    
  ],

  // currentLeadComments : [{comment: 'Hi', created_at: 'a few seconds ago'}, {comment: 'this is s a tes\n\nhihi\n\nhihi\nhihi\n', created_at: '3 hours ago'}],
  currentLeadComments : [],


  myAccount: {
    username: "",
    fullname: "",
    email: "",
    batch: "",
    batch_name: "",
    rollno: "",
    image:
      "https://degenden-academy.s3.ap-south-1.amazonaws.com/webapp_assets/pfps/M.png",
    upi_id: null,
  },

 

  myprofile: {
    name: "metamady.eth",
    joined: "July, 23",
    photoUrl: "",
    attendence: { total: 10, offline: 7, online: 3 },
    coins: { total: 100 },
  },


};



export function OMSReducer(state = initialState, action) {

  if (action.type === "GET_LEADS") {
    return {
      ...state,
      leads: action.payload.leads,
      chartData: action.payload.chart_data
    };
  }

  if (action.type === "GET_LEAD_COMMENTS_SUCCESS") {
    return {
      ...state,
      currentLeadComments: action.payload.lead_comments
    };
  }
  if (action.type === "ADD_COMMENT_SUCCESS") {
    return {
      ...state,
      currentLeadComments: action.payload.lead_comments
    };
  }

  return state;
}
