import React, { useContext, useState, useEffect, useRef } from "react";
import "bootstrap-daterangepicker/daterangepicker.css";
import "react-datepicker/dist/react-datepicker.css";
import { getProjects } from "../../store/actions/AuthActions";
import { Row, Col, Card, Tab, Nav } from "react-bootstrap";

import { Link } from "react-router-dom";

import acheiv from "../../images/svg/achievement.svg";
import medal from "../../images/medal.png";
import linkedin from "./../../images/Linkedin.png";
import kaggle from "./../../images/Kaggle.png";
import { connect, useDispatch, useSelector } from "react-redux";

import { Viewer } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";
import { Worker } from "@react-pdf-viewer/core";

import { ThemeContext } from "../../context/ThemeContext";
import ML from "./../../images/ML.png";

import loadable from "@loadable/component";
import pMinDelay from "p-min-delay";

const projectTypes = ["EDA", "DL"];
const randomProjectType =
  projectTypes[Math.floor(Math.random() * projectTypes.length)];

const dataBatch = ["Batch 1", "Batch 2", "Batch 3"];
const randomDataBatch = dataBatch[Math.floor(Math.random() * dataBatch.length)];

const edaBatch = ["Batch 1", "Batch 2", "Batch 3"];
const randomEDABatch = edaBatch[Math.floor(Math.random() * edaBatch.length)];

const mlBatch = ["Batch 1", "Batch 2", "Batch 3"];
const randomMLBatch = mlBatch[Math.floor(Math.random() * mlBatch.length)];

const randomValue = Math.random();

// Define your two batches
const b1 = "Batch 1";
const b2 = "Batch 2";

// Use the random number to select a batch
const batch = randomValue < 0.5 ? b1 : b2;

const Home = (props) => {
  const [showPdf, setShowPdf] = useState(false);
  const [showHtml, setShowHtml] = useState(false);
  const [currentPdf, setCurrentPdf] = useState(null);
  const [currentHtml, setCurrentHtml] = useState(null);
  const [isHtmlLoading, setIsHtmlLoading] = useState(false);

  const [isLoading, setIsLoading] = useState(true);
  const [activeToggle, setActiveToggle] = useState("aboutMe");
  const [selectedTab, setSelectedTab] = useState(batch);

  const [activeProjectTab, setActiveProjectTab] = useState(randomProjectType);

  const [showPlayIcon, setShowPlayIcon] = useState(true);

  const dispatch = useDispatch();

  const iframeRef = useRef(null);

  const handleProjectTab = (tabNumber) => {
    setActiveProjectTab(tabNumber);
  };

  const {
    changeBackground,
    changePrimaryColor,
    changeSecondaryColor,
    chnageSidebarColor,
    chnageHaderColor,
    changeNavigationHader,
  } = useContext(ThemeContext);

  const darkModeData = useSelector((state) => state.one.darkMode);

  const PopularClassChart = loadable(() =>
    pMinDelay(import("./PopularClassChart"), 1000)
  );
  useEffect(() => {
    changeBackground({
      value: props.reduxCurrentState.one.darkMode ? "dark" : "light",
      label: "Dark",
    });

    changePrimaryColor("color_11");
    changeSecondaryColor("color_1");
    chnageSidebarColor(
      props.reduxCurrentState.one.darkMode ? "dark" : "color_11"
    );
    changeNavigationHader(
      props.reduxCurrentState.one.darkMode ? "dark" : "color_11"
    );
  }, [darkModeData]);

  useEffect(() => {
    dispatch(getProjects(props.reduxCurrentState.one.webAuthTokenId));
  }, []);

  const onPressViewPDF = (pdf_link) => {
    setCurrentPdf(pdf_link);
    setShowPdf(true);
  };
  const handlePdfLoaded = () => {
    setIsLoading(false);
  };

  const underlineStyle1 = {
    textDecoration: "underline",
    cursor: "pointer",
    color: "green",
  };

  const tabStyle = {
    display: "inline-block",
    padding: "10px 20px",
    borderRadius: "20px 20px 0 0",
    cursor: "pointer",
  };

  const videoPlayOverlayStyle = {
    position: "absolute",
    top: "45%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    pointerEvents: "none",
  };

  const videoPlayIconStyle = {
    fontSize: "40px", // Adjust the size and style as needed
  };

  const selectedTabStyle = {
    ...tabStyle,
    backgroundColor: "#3a9b7e", // Color for the selected tab
  };

  const unselectedTabStyle = {
    ...tabStyle,
    backgroundColor: "#e0e0e0", // Color for the unselected tab
  };

  const onPressViewHtmlCode = (htmlLink) => {
    window.scrollTo({
      top: 0,
      behavior: "auto", // You can use 'auto' for an immediate scroll
    });
    setShowHtml(true);
    setCurrentHtml(htmlLink);
  };

  const chartBlog = [
    { title: "Design", color: "#FEC64F", percent: "50", status: "763" },
    {
      title: "Parental Level Of Education",
      color: "#FC6B57",
      percent: "26",
      status: "543",
    },
    {
      title: "Sweet Potatoes Production (Tonnes) ",
      color: "#4CBC9A",
      percent: "24",
      status: "333",
    },
  ];

  const VideoPlayButton = () => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100"
      height="100"
      viewBox="0 0 100 100"
    >
      <circle
        cx="50"
        cy="50"
        r="40"
        stroke="white"
        strokeWidth="2"
        fill="transparent"
      />
      <polygon points="35,25 35,75 75,50" fill="white" />
    </svg>
  );

  return (
    <>
      <div className="row">
        {showPdf && currentPdf ? (
          <div className="pdf-viewer-container">
            {isLoading && <p>Please wait...</p>}
            
            <div className="d-flex align-items-center">
              <p onClick={() => setShowPdf(false)} style={underlineStyle1}>
                Go Back
              </p>
            </div>

            <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
              <Viewer
                fileUrl={currentPdf}
                onError={(error) => {
                  console.error("Error loading PDF:", error);
                }}
                onDocumentLoad={handlePdfLoaded}
              />{" "}
            </Worker>
          </div>
        ) : showHtml && currentHtml ? (
          <>
            <div className="d-flex align-items-center">
              <p onClick={() => setShowHtml(false)} style={underlineStyle1}>
                Go Back
              </p>
            </div>
            <div>
              <iframe
                src={currentHtml}
                width="100%"
                height="1000px"
                title="HTML Viewer"
              >
                Your browser does not support iframes.
              </iframe>
            </div>
          </>
        ) : (
          <>
            {/* <div className="col-xl-6 col-sm-6">
              <div className="card total-order">
                <div className="card-body">
                  <h6 className="mb-2 fw-light">Total Guides</h6>
                  <div className="d-flex align-items-center mb-3">
                    <h2 className="m-0 fw-bold">
                      {props.reduxCurrentState.one.guides.length}
                    </h2>
                    <span className="text-secondary fs-18 ms-3">
                      <svg
                        className="me-2"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M23.25 12C23.25 5.775 18.225 0.75 12 0.75C5.775 0.749999 0.75 5.775 0.75 12C0.749999 18.225 5.775 23.25 12 23.25C18.225 23.25 23.25 18.225 23.25 12ZM11.25 16.575L11.25 9.675L9.3 11.4C8.85 11.775 8.25 11.7 7.875 11.325C7.725 11.1 7.65 10.875 7.65 10.65C7.65 10.35 7.8 10.05 8.025 9.9L11.625 6.75C11.7 6.675 11.775 6.675 11.85 6.6C11.925 6.6 11.925 6.6 12 6.525C12.075 6.525 12.075 6.525 12.15 6.525L12.225 6.525C12.3 6.525 12.3 6.525 12.375 6.525L12.45 6.525C12.525 6.525 12.525 6.525 12.6 6.6C12.6 6.6 12.675 6.6 12.675 6.675L12.75 6.75C12.75 6.75 12.75 6.75 12.825 6.825L15.975 10.05C16.35 10.425 16.35 11.1 15.975 11.475C15.6 11.85 14.925 11.85 14.55 11.475L13.125 9.975L13.125 16.65C13.125 17.175 12.675 17.7 12.075 17.7C11.7 17.55 11.25 17.1 11.25 16.575Z"
                          fill="var(--secondary)"
                        ></path>
                      </svg>
                      +0%
                    </span>
                  </div>
                  <p className="fs-14 font-w400">
                    Concise resources providing deep insights into various data
                    science topics, enriched with clear concepts, visual aids,
                    and illustrative examples. All are at one place.
                  </p>
                </div>
              </div>
            </div> */}

            {/* <div className="col-xl-6 col-sm-6">
              <div className="card total-order">
                <div className="card-body">
                  <h6 className="mb-2 fw-light">Total Bytes</h6>
                  <div className="d-flex align-items-center mb-3">
                    <h2 className="m-0 fw-bold">
                      {props.reduxCurrentState.one.dlProjects.length}
                    </h2>
                    <span className="text-secondary fs-18 ms-3">
                      <svg
                        className="me-2"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M23.25 12C23.25 5.775 18.225 0.75 12 0.75C5.775 0.749999 0.75 5.775 0.75 12C0.749999 18.225 5.775 23.25 12 23.25C18.225 23.25 23.25 18.225 23.25 12ZM11.25 16.575L11.25 9.675L9.3 11.4C8.85 11.775 8.25 11.7 7.875 11.325C7.725 11.1 7.65 10.875 7.65 10.65C7.65 10.35 7.8 10.05 8.025 9.9L11.625 6.75C11.7 6.675 11.775 6.675 11.85 6.6C11.925 6.6 11.925 6.6 12 6.525C12.075 6.525 12.075 6.525 12.15 6.525L12.225 6.525C12.3 6.525 12.3 6.525 12.375 6.525L12.45 6.525C12.525 6.525 12.525 6.525 12.6 6.6C12.6 6.6 12.675 6.6 12.675 6.675L12.75 6.75C12.75 6.75 12.75 6.75 12.825 6.825L15.975 10.05C16.35 10.425 16.35 11.1 15.975 11.475C15.6 11.85 14.925 11.85 14.55 11.475L13.125 9.975L13.125 16.65C13.125 17.175 12.675 17.7 12.075 17.7C11.7 17.55 11.25 17.1 11.25 16.575Z"
                          fill="var(--secondary)"
                        ></path>
                      </svg>
                      +0%
                    </span>
                  </div>
                  <p className="fs-14 font-w400">
                    {" "}
                    Bite-sized videos, each spanning approximately 8 minutes,
                    comprehensively dissecting various data science topics with
                    thorough explanations, engaging visuals, and detailed
                    examples.
                  </p>
                </div>
              </div>
            </div> */}

            <div className="row">
              <div className="col-xl-6 col-xl-12">
                <div className="card">
                  <div className="card-body">
                    <div className="widget-courses align-items-center d-flex flex-wrap">
                      <div className="d-flex  align-items-center flex-wrap flex-1 justify-content-center">
                        <div>
                          <div
                            style={{
                              display: "flex",
                              borderRadius: "10px",
                              overflow: "hidden",
                            }}
                          >
                            <div
                              className={
                                activeProjectTab === "EDA"
                                  ? "btn btn-primary "
                                  : "btn bg-dark"
                              }
                              style={{
                                padding: "10px 20px",
                                cursor: "pointer",
                                // backgroundColor: activeTab === 2 ? "#3498db" : "#fff",
                                color: "#fff",
                                borderRadius: "20px 20px 20px 20px",
                                transition: "background-color 0.3s ease",
                                marginRight: "10px",
                              }}
                              onClick={() => handleProjectTab("EDA")}
                            >
                              Guides
                            </div>

                            <div
                              className={
                                activeProjectTab === "DL"
                                  ? "btn btn-primary "
                                  : "btn bg-dark"
                              }
                              style={{
                                padding: "10px 20px",
                                cursor: "pointer",
                                // backgroundColor: activeTab === 2 ? "#3498db" : "#fff",
                                color: "#fff",
                                borderRadius: "20px 20px 20px 20px",
                                transition: "background-color 0.3s ease",
                                marginRight: "10px",
                              }}
                              onClick={() => handleProjectTab("DL")}
                            >
                              Bytes
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {!props.reduxCurrentState.one.projectLoading && (
              <div
                class="spinner-border"
                style={{
                  flex: 1,
                  alignItems: "center",
                  alignContent: "center",
                  justifyContent: "center",
                  justifyItems: "center",
                }}
              ></div>
            )}

            {activeProjectTab === "DL" && (
              <>
                {props.reduxCurrentState.one.dlProjects &&
                  props.reduxCurrentState.one.dlProjects.map((item, index) => (
                    <div className="col-xl-4 col-xxl-6 col-md-6" key={index}>
                      <div className="card instructors-box">
                        <div className="card-body text-center pb-3">
                          {/* Video Player */}
                          <div style={{ position: "relative" }}>
                            <video
                              style={{
                                borderRadius: "10px",
                                width: "100%",
                                height: "auto",
                              }}
                              controls
                              controlsList="nodownload"
                              poster={item.thumbnail}
                              onPlay={(e) => {
                                e.currentTarget.nextSibling.style.display =
                                  "none";
                              }}
                              onPause={(e) => {
                                e.currentTarget.nextSibling.style.display =
                                  "block";
                              }}
                            >
                              <source
                                src={item.demo_video_link}
                                type="video/mp4"
                              />
                              Your browser does not support the video tag.
                            </video>

                            {showPlayIcon && (
                              <div style={videoPlayOverlayStyle}>
                                <span style={videoPlayIconStyle}>
                                  <VideoPlayButton />
                                </span>{" "}
                              </div>
                            )}
                          </div>

                          {/* Instructor Info */}
                          <div className="instructors-media">
                            <div className="instructors-media-info">
                              <h4>{item.author.name}</h4>                              
                            </div>
                          </div>
                        </div>
                        
                      </div>
                    </div>
                  ))}
              </>
            )}

            {activeProjectTab == "EDA" && (
              <>
                {props.reduxCurrentState.one.guides &&
                  props.reduxCurrentState.one.guides.map((item, index) => (
                    <div className="col-xl-4 col-xxl-4 col-md-4" key={index}>
                      <div className="card instructors-box">
                        <div className="card-body text-center pb-3">
                          <img
                            style={{ borderRadius: "10px" }}
                            src={item.thumbnail}
                            alt=""
                            width={250}
                            height={400}
                          />

                          <div className="instructors-media">
                            <div className="instructors-media-info">
                              <h4>{item.title}</h4>
                            </div>
                          </div>
                        </div>

                        <div className="Row">
                          <div className="card-footer pt-0 border-0">
                            <div className="row">
                              <div className="col-md-12">
                                <Link
                                  onClick={() =>
                                    onPressViewHtmlCode(item.html_link)
                                  }
                                  className="btn btn-primary btn-block"
                                >
                                  View
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
              </>
            )}
          </>
        )}
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    reduxCurrentState: state,
  };
};

export default connect(mapStateToProps)(Home);
