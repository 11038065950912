import React, { useContext, useState, useEffect } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import "bootstrap-daterangepicker/daterangepicker.css";
import "react-datepicker/dist/react-datepicker.css";
import { getClasses } from "../../store/actions/AuthActions";
import certificate from "./../../images/svg/degree-certificate.svg";
import clock from "./../../images/svg/clock-1.svg";
import pic2 from "./../../images/courses/pic2.jpeg";
import educationwebsite from "./../../images/svg/education-website.svg";
import skill from "./../../images/svg/skill.svg";
import puzzle from "./../../images/svg/puzzle.svg";
import VideoPlayer from "./VideoPlayer";
import swal from "sweetalert";
import Check from "./../../images/vector/check.png";
import Bookmarks from "./../../images/vector/bookmarks.png";
import Bulb from "./../../images/vector/bulb.png";
import { Link } from "react-router-dom";
import { ThemeContext } from "../../context/ThemeContext";

const WidgetBlog = ({ changeImage, title, value }) => {
  return (
    <>
      <div className="col-xl-6 col-lg-6 col-sm-6">
        <div className="card profile-widget">
          <div className="card-body">
            <div className="widget-courses align-items-center d-flex justify-content-between style-1 flex-wrap">
              <div className="d-flex ">
                <img src={changeImage} alt="" className="rounded" />
                <div className="ms-4">
                  <h5>{value}</h5>

                  <span>{title}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};


const Attendence = (props) => {
  const [classes, setClasses] = useState(props.reduxCurrentState.one.classes);
  const [isLoading, setIsLoading] = useState(false);
  const [students, setStudents] = useState([]);

  const [selectedStudentsOffline, setSelectedStudentsOffline] = useState([]);
  const [selectedStudentsOnline, setSelectedStudentsOnline] = useState([]);
  const [selectedOfflineScore, setSelectedOfflineScore] = useState(6);
  const [selectedOnlineScore, setSelectedOnlineScore] = useState(5);

  const [classVideoID, setClassVideoID] = useState();

  const [attendenceTaken, setAttendenceTaken] = useState(true);

  const [today, setToday] = useState(new Date());

  const [showVideo, setShowVideo] = useState(false);
  const [classTitle, setClassTitle] = useState("");
  const [videoUrl, setVideoUrl] = useState("");

  const dispatch = useDispatch();

  const {
    changeBackground,
    changePrimaryColor,
    changeSecondaryColor,
    chnageSidebarColor,
    chnageHaderColor,
    changeNavigationHader,
  } = useContext(ThemeContext);

  const darkModeData = useSelector((state) => state.one.darkMode);

  useEffect(() => {
    changeBackground({
      value: props.reduxCurrentState.one.darkMode ? "dark" : "light",
      label: "Dark",
    });

    changePrimaryColor("color_11");
    changeSecondaryColor("color_1");
    chnageSidebarColor(props.reduxCurrentState.one.darkMode ? "dark": "color_11");
    changeNavigationHader(props.reduxCurrentState.one.darkMode ? "dark": "color_11");
    
  }, [darkModeData]);


  const handleCheckboxChangeOffline = (studentId) => {
    if (selectedStudentsOffline.includes(studentId)) {
      setSelectedStudentsOffline(
        selectedStudentsOffline.filter((id) => id !== studentId)
      );
    } else {
      setSelectedStudentsOffline([...selectedStudentsOffline, studentId]);
    }
  };

  const handleCheckboxChangeOnline = (studentId) => {
    if (selectedStudentsOnline.includes(studentId)) {
      setSelectedStudentsOnline(
        selectedStudentsOnline.filter((id) => id !== studentId)
      );
    } else {
      setSelectedStudentsOnline([...selectedStudentsOnline, studentId]);
    }
  };

  const handleSelectChangeOffline = (e) => {
    const selectedValue = parseInt(e.target.value);
    setSelectedOfflineScore(selectedValue);
  };

  const handleSelectChangeOnline = (e) => {
    const selectedValue = parseInt(e.target.value);
    setSelectedOnlineScore(selectedValue);
  };

  useEffect(() => {
    console.log("use effect called");
    dispatch(getClasses(props.reduxCurrentState.one.webAuthTokenId));
    getAllStudents();
  }, [classes]);

  function submitAttendence() {
    setIsLoading(true);

    fetch("https://kmkm84urcd.execute-api.ap-south-1.amazonaws.com/dev/api/", {
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        method: "submitattendence",
        web_auth_token_id: props.reduxCurrentState.one.webAuthTokenId,
        offline_score: selectedOfflineScore,
        online_score: selectedOnlineScore,
        offline_students: selectedStudentsOffline,
        online_students: selectedStudentsOnline,
        attendence_date: formatDate(today),
        class_video_id: classVideoID,
      }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (!responseJson["error"]) {
          console.log("Response - ", responseJson)

          swal("Done", "", "success");
          setAttendenceTaken(responseJson['attendence_taken'])

        } else {
          const error = responseJson["message"];
          swal("Oops", error, "error");
          throw error;
        }
      })
      .catch((error) => {
        console.log("request failed", error);
        alert("Backend error, try again or reach out to us.");
      });
  }

  const handleAttendanceSubmit = () => {
    if (!today) {
      swal("Oops", "Date is EMPTY", "error");
      return;
    }
    if (!selectedOfflineScore) {
      swal("Oops", "Offline Score is EMPTY", "error");
      return;
    }
    if (!selectedOnlineScore) {
      swal("Oops", "Online Score is EMPTY", "error");
      return;
    }

    if (
      selectedStudentsOffline.length == 0 &&
      selectedStudentsOnline.length == 0
    ) {
      swal("Oops", "No Student is PRESENT", "error");
      return;
    }

    if (!classVideoID) {
      swal("Oops", "Class ID is EMPTY", "error");
      return;
    }

    submitAttendence();
  };

  function getAllStudents() {
    setIsLoading(true);

    fetch("https://kmkm84urcd.execute-api.ap-south-1.amazonaws.com/dev/api/", {
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        method: "getclassattendence",
        web_auth_token_id: props.reduxCurrentState.one.webAuthTokenId,
        attendence_date: formatDate(today),
      }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (!responseJson["error"]) {
          setStudents(responseJson["students"]);
          setClassVideoID(responseJson["class_video_id"]);
          setAttendenceTaken(responseJson['attendence_taken'])
        } else {
          const error = responseJson["message"];
          swal("Oops", error, "error");
          throw error;
        }
      })
      .catch((error) => {
        console.log("request failed", error);
        alert("Backend error, try again or reach out to us.");
      });
  }

  function formatDate(date) {
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const day = date.getDate();
    const month = months[date.getMonth()];
    const year = date.getFullYear();

    return `${day}-${month}-${year}`;
  }

  return (
    <>
      {attendenceTaken ? (
        <>
          <div
            style={{
              display: "flex",
              flex: 1,
              alignItems: "center",
              justifyContent: "center",
              justifyItems: "center",
              alignSelf: "center",
              alignContent: "center",
              minHeight: "70vh",
            }}
          >
            <h1>Attendence has already been taken for today.</h1>
          </div>
        </>
      ) : (
        <>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              minHeight: "5vh",
            }}
          >
            <h5>Date: {formatDate(today)}</h5>
          </div>

          <div className="row">
            <div className="col-xl-6 col-lg-6">
              <div className="card">
                <div className="card-header">
                  <h4 className="card-title">Offline</h4>
                </div>
                <div className="card-body">
                  <div className="row">
                    {students.map((data, key) => {
                      return (
                        <div className="col-xl-4 col-xxl-6 col-6">
                          <div
                            key={key}
                            className="form-check custom-checkbox mb-3 checkbox-success"
                          >
                            <input
                              type="checkbox"
                              className="form-check-input"
                              id={`customCheckBox${key}`}
                              onChange={() =>
                                handleCheckboxChangeOffline(data.user_app_id)
                              }
                              checked={selectedStudentsOffline.includes(
                                data.user_app_id
                              )}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="customCheckBox2"
                            >
                              {data.first_name}
                            </label>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>

            <div className="col-xl-6 col-lg-6">
              <div className="card">
                <div className="card-header">
                  <h4 className="card-title">Online</h4>
                </div>
                <div className="card-body">
                  <div className="row">
                    {students.map((data, key) => {
                      return (
                        <div className="col-xl-4 col-xxl-6 col-6">
                          <div
                            key={key}
                            className="form-check custom-checkbox mb-3 checkbox-warning"
                          >
                            <input
                              type="checkbox"
                              className="form-check-input"
                              id={`customCheckBox${key}`}
                              onChange={() =>
                                handleCheckboxChangeOnline(data.user_app_id)
                              }
                              checked={selectedStudentsOnline.includes(
                                data.user_app_id
                              )}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="customCheckBox2"
                            >
                              {data.first_name}
                            </label>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-xl-6 col-lg-6">
              <div className="card">
                <div className="card-body">
                  <div className="basic-form">
                    <div className="form-group mb-3">
                      <label>Offline Coins</label>
                      <select
                        className="form-control"
                        id="sel1"
                        value={selectedOfflineScore}
                        onChange={handleSelectChangeOffline}
                      >
                        {" "}
                        
                        <option value={7}>7</option>
                        <option value={8}>8</option>
                        <option value={9}>9</option>
                        <option value={10}>10</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-6 col-lg-6">
              <div className="card">
                <div className="card-body">
                  <div className="basic-form">
                    <div className="form-group mb-3">
                      <label>Online Coins</label>
                      <select
                        className="form-control"
                        id="sel1"
                        value={selectedOnlineScore}
                        onChange={handleSelectChangeOnline}
                      >
                        {" "}
                        
                        <option value={6}>6</option>
                        <option value={7}>7</option>
                        <option value={8}>8</option>
                        <option value={9}>9</option>
                        <option value={10}>10</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            className="widget-heading d-flex justify-content-between align-items-center"
            style={{ marginLeft: 5 }}
          >
            <h5></h5>
            <Link
              onClick={handleAttendanceSubmit}
              className="btn btn-secondary btn-sm"
            >
              Submit Attendence
            </Link>
          </div>
        </>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    reduxCurrentState: state,
  };
};

export default connect(mapStateToProps)(Attendence);
