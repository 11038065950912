import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import { ThemeContext } from "../../../context/ThemeContext";

import vid from "./../../../images/courses/DDAShort.png";
import vid1 from "./../../../images/courses/dd.png";

const NavHader = () => {
  const [toggle, setToggle] = useState(false);
  const { openMenuToggle } = useContext(ThemeContext);

  // Function to check if the screen size is less than a certain breakpoint
  const isMobile = () => window.innerWidth <= 767;

  return (
    <div className="nav-header">
      <Link to="/" className="brand-logo">
        {isMobile() ? (
          <img src={vid1} alt="images" width={30} height={60} />
        ) : (
          toggle ? <img src={vid1} alt="images" width={30} height={50} /> : <img src={vid} alt="images"  width={180} height={60}/>
        )}
      </Link>

      <div
        className="nav-control"
        onClick={() => {
          setToggle(!toggle);
          openMenuToggle();
        }}
      >
        <div className={`hamburger ${toggle ? "is-active" : ""}`}>
          <span className="line"></span>
          <span className="line"></span>
          <span className="line"></span>
        </div>
      </div>
    </div>
  );
};

export default NavHader;
