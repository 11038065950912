import React, { useContext, useEffect,useState } from "react";
import { connect, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import {
  loadingToggleAction,
  loginAction,
} from "../../store/actions/AuthActions";
import { ThemeContext } from "../../context/ThemeContext";

//
import loginbg from "../../images/bg-1.jpg";
import logo from "../../images/log.png";
import logofull from "../../images/logo-full.png";

function Login(props) {

  const { changeBackground,changePrimaryColor,
    changeSecondaryColor, chnageSidebarColor, chnageHaderColor, changeNavigationHader } = useContext(ThemeContext);
  const [isOpen, setOpen] = useState(false);

  const [email, setEmail] = useState("");
  let errorsObj = { email: "", password: "" };
  const [errors, setErrors] = useState(errorsObj);
  const [password, setPassword] = useState("");
  const dispatch = useDispatch();

  function onLogin(e) {
    e.preventDefault();
    let error = false;
    const errorObj = { ...errorsObj };
    if (email === "") {
      errorObj.email = "Email is Required";
      error = true;
    }
    if (password === "") {
      errorObj.password = "Password is Required";
      error = true;
    }
    setErrors(errorObj);
    if (error) {
      return;
    }
    dispatch(loadingToggleAction(true));
    dispatch(loginAction(email, password, props.history));
  }

  useEffect(() => {
    
    changePrimaryColor("color_11");
    changeSecondaryColor("color_1");
    chnageSidebarColor("color_11");
    changeNavigationHader("color_11")

  }, []);

  return (
    <div
      className="login-main-page"
      style={{
        backgroundImage: "url(" + loginbg + ")",
        backgroundSize: "cover",
      }}
    >
      <div className="login-wrapper ">
        <div className="container h-100 ">
          <div className="row h-100 d-flex justify-content-center align-items-center">
            <div className="col-xl-0 col-md-6">
              <div className="sign-in-your">
              <span>Welcome to </span>
                <h1 className="fs-20 font-w800 text-black">
                  Degen Den Academy
                </h1>
                <br></br>

                <div className="text-center">Sign in your account</div>

                {props.errorMessage && (
                  <div className="bg-red-300 text-red-900 border border-red-900 p-1 my-2">
                    {props.errorMessage}
                  </div>
                )}
                {props.successMessage && (
                  <div className="bg-green-300 text-green-900 border border-green-900 p-1 my-2">
                    {props.successMessage}
                  </div>
                )}
                <form onSubmit={onLogin}>
                  <div className="mb-3">
                    <label className="mb-1">
                      <strong>Email</strong>
                    </label>
                    <input
                      type="email"
                      placeholder="Please enter email"
                      className="form-control"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                    {errors.email && (
                      <div className="text-danger fs-12">{errors.email}</div>
                    )}
                  </div>
                  <div className="mb-3">
                    <label className="mb-1">
                      <strong>Password</strong>
                    </label>
                    <input
                      type="password"
                      placeholder="Please enter password"
                      className="form-control"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                    {errors.password && (
                      <div className="text-danger fs-12">{errors.password}</div>
                    )}
                  </div>
                  <br></br>
                  <div className="text-center">
                    <button type="submit" className="btn btn-primary btn-block">
                      Sign Me In
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    reduxCurrentState: state,
  };
};
export default connect(mapStateToProps)(Login);
