import React, { useState } from "react";
import { Link } from "react-router-dom";
import loadable from "@loadable/component";
import pMinDelay from "p-min-delay";
import { Dropdown } from "react-bootstrap";

import DonutChart from "./components/DonutChart";

//import ProfileActivityChart from './Dashboard/ProfileActivityChart';

const MockInterviews = () => {
  const [dropSelect, setDropSelect] = useState("This Month");

  const mockVideos = [
    "https://www.youtube.com/embed/sD468LfeVdc", // Video 1
    "https://www.youtube.com/embed/1ykKyghfpgM", // Video 2
    "https://www.youtube.com/embed/0ls9RG_IDI0", // Video 3
    "https://www.youtube.com/embed/X0NZqK_CZ2M", // Video 4
  ];

  const containerStyle = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "50vh",
  };

  const contentStyle = {
    textAlign: "center",
    margin: 0,
    padding: 0,
  };
  return (
    <>
      <div style={{ maxWidth: "800px", margin: "0 auto", padding: "20px", display: 'flex', flexDirection: 'column' }}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            marginTop: "20px",
          }}
        >
          {[0, 1].map((rowIndex) => (
            <div
              key={rowIndex}
              style={{ display: "flex", marginBottom: "10px" }}
            >
              {[0, 1].map((colIndex) => {
                const videoIndex = rowIndex * 2 + colIndex;
                return (
                  <div
                    key={colIndex}
                    style={{
                      flex: "1",
                      marginRight: colIndex === 0 ? "10px" : "0",
                    }}
                  >
                    <iframe
                      title={`Mock Interview ${videoIndex + 1}`}
                      src={mockVideos[videoIndex]}
                      frameBorder="0"
                      allow="autoplay; encrypted-media"
                      allowFullScreen
                      style={{ width: "100%", height: "200px" }}
                    ></iframe>
                  </div>
                );
              })}
            </div>
          ))}
        </div>
        <h1 style={{ textAlign: 'center', marginTop: '20px' }}>Coming Soon....</h1>

      </div>

      
    </>
  );
};
export default MockInterviews;
