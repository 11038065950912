import React, { useContext, useState, useEffect } from "react";

import { connect, useDispatch, useSelector } from "react-redux";
import "bootstrap-daterangepicker/daterangepicker.css";
import "react-datepicker/dist/react-datepicker.css";
import { getResumes } from "../../store/actions/AuthActions";

import pic8 from "../../images/courses/pic8.jpg";
import pic7 from "../../images/courses/pic7.jpg";
import pic6 from "../../images/courses/pic6.jpg";
import pic5 from "../../images/courses/pic5.jpg";

import swal from "sweetalert";
import { Link } from "react-router-dom";
import {
  Row,
  Col,
  Card,
  Tab,
  Nav,
  Badge,
  ProgressBar,
  ListGroup,
  Alert,
} from "react-bootstrap";

import { Button, Dropdown, Modal } from "react-bootstrap";
import linkedin from "./../../images/Linkedin.png";
import kaggle from "./../../images/Kaggle.png";

import acheiv from "../../images/svg/achievement.svg";
import medal from "../../images/medal.png";

import Check from "./../../images/vector/check.png";
import Bookmarks from "./../../images/vector/bookmarks.png";
import Bulb from "./../../images/vector/bulb.png";
import { ThemeContext } from "../../context/ThemeContext";

import dairy from "./../../images/treats/dark.png";

import Calpng from "./../../images/vector/calpng.png";
import Book from "./../../images/vector/book.png";
import Educat from "./../../images/p_class.png";

import first from "./../../images/1.png";
import second from "./../../images/2.png";
import third from "./../../images/3.png";

const ResumeBuilding = (props) => {
  const [days, setDays] = useState("--");
  const [hours, setHours] = useState("--");
  const [minutes, setMinutes] = useState("--");
  const [seconds, setSeconds] = useState("--");

  const [isCModelShown, setIsCModelShown] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [mockTestUuid, setMockTestUuid] = useState("");

  const [quizQuestions, setQuizQuestions] = useState([]);
  const [currentQuestion, setCurrentQuestion] = useState(null);
  const [questionIndex, setQuestionIndex] = useState(1);

  const [showHtml, setShowHtml] = useState(false);
  // const [currentHtml, setCurrentHtml] = useState(null);

  const dispatch = useDispatch();

  const yourText = props.reduxCurrentState.one.nextClass.class_title;
  const textParts = yourText.split(":");

  const {
    changeBackground,
    changePrimaryColor,
    changeSecondaryColor,
    chnageSidebarColor,
    chnageHaderColor,
    changeNavigationHader,
  } = useContext(ThemeContext);

  const darkModeData = useSelector((state) => state.one.darkMode);

  const reduxStateData = useSelector((state) => state.one.mockTests);

  useEffect(() => {
    changeBackground({
      value: props.reduxCurrentState.one.darkMode ? "dark" : "light",
      label: "Dark",
    });

    changePrimaryColor("color_11");
    changeSecondaryColor("color_1");
    chnageSidebarColor(
      props.reduxCurrentState.one.darkMode ? "dark" : "color_11"
    );
    changeNavigationHader(
      props.reduxCurrentState.one.darkMode ? "dark" : "color_11"
    );

    
  }, [reduxStateData, darkModeData]);

  useEffect(() => {
    dispatch(getResumes(props.reduxCurrentState.one.webAuthTokenId));
  }, []);


  const counterStyle = {
    display: "flex",
    border: "2px solid rgba(0,0,0,1)",
    padding: "10px",
    borderRadius: 10,
    borderColor: "#b69926",
  };

  const boxStyle = {
    width: "80px",
    height: "80px",
    textAlign: "center",
    marginRight: "10px",
    position: "relative",
  };

  const h2Style = {
    fontSize: "2.5rem",
    fontWeight: "500",
    color: "#b69926",
  };

  const learningData = [
    { title: "Read each question carefully before answering." },
    { title: "Choose the most appropriate option from the given choices." },
    { title: "There is only one correct answer for each question." },
    {
      title:
        "Be mindful of the time limit. Manage your time wisely to attempt all questions.",
    },
    {
      title:
        "Your test gets saved automatically so no worries in case of restart.",
    },
  ];

  const scoringData = [
    { title: "Each question carries one point." },
    {
      title:
        "There is no negative marking, so feel free to attempt all questions.",
    },
  ];

  const onPressViewHtmlCode = (htmlLink) => {
    window.scrollTo({
      top: 0,
      behavior: "auto", // You can use 'auto' for an immediate scroll
    });
    setShowHtml(true);
    // setCurrentHtml(htmlLink);
  };

  return (
    <>
      <div style={{ marginTop: "-25px" }}></div>
      {/* 
      <br></br>
      <br></br>

      <div>
        <p>
          The test consists of 30 questions that cover a range of topics and it
          is designed to assess your proficiency & skills.
        </p>
      </div> */}

      {/* <div className="course-learn">
        <div className="row" style={{ display: "flex", flexDirection: "row" }}>
          <div style={{ marginRight: "20px" }}>
            <h4>Instructions:</h4>
            <ul style={{ listStyleType: "none", padding: 0 }}>
              {learningData.map((item, index) => (
                <li
                  key={index}
                  style={{
                    display: "block",
                    marginBottom: "-1px",
                    clear: "both",
                  }}
                >
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <svg
                      width="16"
                      height="17"
                      viewBox="0 0 16 17"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clipPath="url(#clip0_1082_8)">
                        <path
                          d="M8 0.5C6.41775 0.5 4.87104 0.969192 3.55544 1.84824C2.23985 2.72729 1.21447 3.97672 0.608967 5.43853C0.00346629 6.90034 -0.15496 8.50887 0.153721 10.0607C0.462403 11.6126 1.22433 13.038 2.34315 14.1569C3.46197 15.2757 4.88743 16.0376 6.43928 16.3463C7.99113 16.655 9.59966 16.4965 11.0615 15.891C12.5233 15.2855 13.7727 14.2602 14.6518 12.9446C15.5308 11.629 16 10.0822 16 8.5C16 6.37827 15.1571 4.34344 13.6569 2.84315C12.1566 1.34285 10.1217 0.5 8 0.5V0.5ZM12.5467 6.57L7.52667 12.2633C7.44566 12.3546 7.34742 12.429 7.23759 12.4822C7.12776 12.5354 7.00851 12.5663 6.88667 12.5733H6.82667C6.59929 12.5747 6.3795 12.4916 6.21 12.34L3.52334 9.95667C3.33857 9.79267 3.22652 9.562 3.21182 9.31539C3.19713 9.06878 3.28101 8.82643 3.445 8.64167C3.609 8.4569 3.83967 8.34484 4.08628 8.33015C4.33289 8.31546 4.57524 8.39934 4.76 8.56333L6.76 10.3267L11.1633 5.32667C11.3269 5.14146 11.5573 5.0288 11.8039 5.01349C12.0505 4.99817 12.2931 5.08145 12.4783 5.245C12.6635 5.40855 12.7762 5.63898 12.7915 5.88559C12.8068 6.1322 12.7236 6.37479 12.56 6.56L12.5467 6.57Z"
                          fill="#4CBC9A"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip00_1082_8">
                          <rect
                            width="16"
                            height="16"
                            fill="white"
                            transform="translate(0 0.5)"
                          />
                        </clipPath>
                      </defs>
                    </svg>
                    {item.title}
                  </div>
                </li>
              ))}
            </ul>
          </div>

          <div>
            <br></br>
          </div>
        </div>
      </div> */}

      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {/* <h3 className="m-0">Alumni Students Resumes</h3> */}
      </div>

      <div>
        <br></br>
      </div>

      <div className="row">
        {props.reduxCurrentState.one.resumes &&
          props.reduxCurrentState.one.resumes.map((item, index) => (
            <div className="col-xl-4 col-xxl-4 col-md-4" key={index}>
              <div className="card instructors-box">
                <div className="card-body text-center pb-3">
                  <img
                    style={{ borderRadius: "7px" }}
                    src={item.thumbnail}
                    alt=""
                    width={270}
                    height={400}
                  />

                  <div className="instructors-media">
                    <div className="instructors-media-info">
                      <h4>{item.author.name}</h4>
                    </div>
                  </div>
                </div>

                <div className="Row">
                  <div className="card-footer pt-0 border-0">
                    <div className="row">
                      <div className="col-md-12">
                        <a
                          href={item.pdf_link}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="btn btn-secondary btn-block"
                        >
                          View Resume
                        </a>
                      </div>
                      {/* <div className="col-md-6">
                        <a
                          href={item.kaggle_link}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="btn btn-info btn-block"
                        >
                          Kaggle
                        </a>
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    reduxCurrentState: state,
  };
};

export default connect(mapStateToProps)(ResumeBuilding);
