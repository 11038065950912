import React, { useContext, useState, useRef, useEffect } from "react";
import { Dropdown, Card, Row, Col } from "react-bootstrap";
import { connect, useDispatch, useSelector } from "react-redux";
import DatePicker from "react-datepicker";
import TimePicker from "react-time-picker";
import Dropzone from "react-dropzone-uploader";
import swal from "sweetalert";
import { ThemeContext } from "../../context/ThemeContext";

import {
  loadingToggleAction,
  registerUserAction,
} from "../../store/actions/AuthActions";

import "react-dropzone-uploader/dist/styles.css";
import Drop from "react-dropzone-uploader";

const AddNewClass = (props) => {
  const [data, setData] = useState(
    document.querySelectorAll(".email-right-box .email-list .message")
  );
  const sort = 8;
  const activePag = useRef(0);
  const [test, settest] = useState(0);

  const [textareaValue, setTextareaValue] = useState("");
  const [selectedTrack, setSelectedTrack] = useState("Data Science");
  const [selectedBatch, setSelectedBatch] = useState("Batch 1");
  const [isLoading, setIsLoading] = useState(false);

  let errorsObj = { classTitle: "", webexLink: "", password: "" };

  const [errors, setErrors] = useState(errorsObj);
  const dispatch = useDispatch();

  const {
    changeBackground,
    changePrimaryColor,
    changeSecondaryColor,
    chnageSidebarColor,
    chnageHaderColor,
    changeNavigationHader,
  } = useContext(ThemeContext);

  const darkModeData = useSelector((state) => state.one.darkMode);

  useEffect(() => {
    changeBackground({
      value: props.reduxCurrentState.one.darkMode ? "dark" : "light",
      label: "Dark",
    });

    changePrimaryColor("color_11");
    changeSecondaryColor("color_1");
    chnageSidebarColor(props.reduxCurrentState.one.darkMode ? "dark": "color_11");
    changeNavigationHader(props.reduxCurrentState.one.darkMode ? "dark": "color_11");
    
  }, [darkModeData]);


  const handleSelectTrack = (event) => {
    setSelectedTrack(event.target.value);
  };

  const handleSelectBatch = (event) => {
    setSelectedBatch(event.target.value);
  };

  const handleTextareaChange = (event) => {
    setTextareaValue(event.target.value);
  };

  const onClickUploadSubmit = async (e) => {
    e.preventDefault();

    let error = false;
    const errorObj = { ...errorsObj };

    if (textareaValue === "") {
      errorObj.textareaValue = "Webex meeting detail are Required";
      error = true;
    }

    if (textareaValue.length <= 3) {
      errorObj.textareaValue = "Webex meeting details are not enough length ";
      error = true;
    }

    setErrors(errorObj);
    if (error) {
      return;
    }

    setIsLoading(true);

    fetch("https://kmkm84urcd.execute-api.ap-south-1.amazonaws.com/dev/api/", {
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        method: "addwebexclass",
        web_auth_token_id: props.reduxCurrentState.one.webAuthTokenId,
        text: textareaValue,
        track: selectedTrack,
        batch: selectedBatch,
      }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (!responseJson["error"]) {
          swal("Done", responseJson["message"], "success");
          setIsLoading(false);
          setTextareaValue("")
        } else {
          const error = responseJson["message"];
          swal("Oops", error, "error");
          setIsLoading(false);

          throw error;
        }
      })
      .catch((error) => {
        console.log("request failed", error);
        // alert("Backend error, try again or reach out to us.");
      });
  };


  const onClickUploadSubmitLocal = async (e) => {
    e.preventDefault();

    let error = false;
    const errorObj = { ...errorsObj };

    if (textareaValue === "") {
      errorObj.textareaValue = "Webex meeting detail are Required";
      error = true;
    }

    if (textareaValue.length <= 3) {
      errorObj.textareaValue = "Webex meeting details are not enough length ";
      error = true;
    }

    setErrors(errorObj);
    if (error) {
      return;
    }

    try {
      const response = await fetch("http://127.0.0.1:5000/process_summary", {
        method: "post",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          method: "addclass",
          text: textareaValue,
          track: selectedTrack,
          batch: selectedBatch,
        }),
      });

      const responseJson = await response.json();
    } catch (error) {
      console.log("request failed", error);
      alert("Backend error, try again or reach out to us.");
    }
  };

  // Active data
  const chageData = (frist, sec) => {
    for (var i = 0; i < data.length; ++i) {
      if (i >= frist && i < sec) {
        data[i].classList.remove("d-none");
      } else {
        data[i].classList.add("d-none");
      }
    }
  };

  // use effect
  useEffect(() => {
    setData(document.querySelectorAll(".email-right-box .email-list .message"));
    //chackboxFun();
  }, [test]);
  // Active pagginarion
  activePag.current === 0 && chageData(0, sort);
  // paggination
  let paggination = Array(Math.ceil(data.length / sort))
    .fill()
    .map((_, i) => i + 1);

  // Active paggination & chage data
  const onClick = (i) => {
    activePag.current = i;
    chageData(activePag.current * sort, (activePag.current + 1) * sort);
    settest(i);
  };
  const chackbox = document.querySelectorAll(".message input");
  const motherChackBox = document.querySelector("#checkbox1");
  const chackboxFun = (type) => {
    for (let i = 0; i < chackbox.length; i++) {
      const element = chackbox[i];
      if (type === "all") {
        if (motherChackBox.checked) {
          element.checked = true;
        } else {
          element.checked = false;
        }
      } else {
        if (!element.checked) {
          motherChackBox.checked = false;
          break;
        } else {
          motherChackBox.checked = true;
        }
      }
    }
  };
  return (
    <>
      {props.reduxCurrentState.one.role == "Student" && (
        <div className="row justify-content-center ">
          <div className="col-0 col-md-10">
            <div className="sign-in-your">
              <div className="card-body">
                <div className="basic-form">
                  <form onSubmit={onClickUploadSubmit}>
                    <div style={{ marginLeft: 1, marginBottom: 5 }}>
                      <label className="mb-1">
                        <strong>Paste Webex Class Link</strong>
                      </label>
                    </div>

                    <div className="form-group">
                      <textarea
                        className="form-control"
                        rows="10"
                        columns="20"
                        id="comment"
                        value={textareaValue} // Set the value to the state variable
                        onChange={handleTextareaChange} // Handle changes
                      ></textarea>
                    </div>

                    {errors.textareaValue && (
                      <div className="text-danger fs-12">
                        {errors.textareaValue}
                      </div>
                    )}
                    <br></br>

                    <div className="row">
                      <div className="form-group mb-4 col-md-6">
                        <select
                          id="inputState"
                          className="form-control"
                          value={selectedTrack}
                          onChange={handleSelectTrack}
                        >
                          <option value="Data Science">Data Science</option>
                          <option value="Data Analytics">Digital Marketing</option>
                        </select>
                      </div>
                      <div className="form-group mb-4 col-md-6">
                        <select
                          id="inputState"
                          className="form-control"
                          value={selectedBatch}
                          onChange={handleSelectBatch}
                        >
                          <option value="Batch 1">Batch 1</option>
                          <option value="Batch 2">Batch 2</option>
                          <option value="Batch 3">Batch 3</option>
                          <option value="Batch 4">Batch 4</option>
                          <option value="Batch 5">Batch 5</option>
                          <option value="Batch 6">Batch 6</option>
                          <option value="Batch 7">Batch 7</option>
                        </select>
                      </div>
                    </div>
                    {/* {isLoading ? } */}
                    <div className="text-center">
                      {isLoading ? (
                        // Show a Bootstrap spinner when isLoading is true
                        <div
                          className="spinner-border text-primary"
                          role="status"
                        >
                          <span className="sr-only">Loading...</span>
                        </div>
                      ) : (
                        // Show the button when isLoading is false
                        <button
                          type="submit"
                          className="btn btn-primary btn-block"
                        >
                          Submit
                        </button>
                      )}
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    reduxCurrentState: state,
  };
};

export default connect(mapStateToProps)(AddNewClass);
