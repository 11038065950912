import React, { useContext, useState, useEffect } from "react";

import { connect, useDispatch, useSelector } from "react-redux";
import "bootstrap-daterangepicker/daterangepicker.css";
import "react-datepicker/dist/react-datepicker.css";
import { getMockTests, getAssignments } from "../../store/actions/AuthActions";

import pic8 from "../../images/courses/pic8.jpg";
import pic7 from "../../images/courses/pic7.jpg";
import pic6 from "../../images/courses/pic6.jpg";
import pic5 from "../../images/courses/pic5.jpg";

import swal from "sweetalert";
import { Link } from "react-router-dom";
import {
  Row,
  Col,
  Card,
  Tab,
  Nav,
  Badge,
  ProgressBar,
  ListGroup,
  Alert,
} from "react-bootstrap";

import { Button, Dropdown, Modal } from "react-bootstrap";
import linkedin from "./../../images/Linkedin.png";
import kaggle from "./../../images/Kaggle.png";

import acheiv from "../../images/svg/achievement.svg";
import medal from "../../images/medal.png";

import Check from "./../../images/vector/check.png";
import Bookmarks from "./../../images/vector/bookmarks.png";
import Bulb from "./../../images/vector/bulb.png";
import { ThemeContext } from "../../context/ThemeContext";

import dairy from "./../../images/treats/dark.png";

import Calpng from "./../../images/vector/calpng.png";
import Book from "./../../images/vector/book.png";
import Educat from "./../../images/p_class.png";

import first from "./../../images/1.png";
import second from "./../../images/2.png";
import third from "./../../images/3.png";

const Assignments = (props) => {
  const [days, setDays] = useState("--");
  const [hours, setHours] = useState("--");
  const [minutes, setMinutes] = useState("--");
  const [seconds, setSeconds] = useState("--");

  const [isCModelShown, setIsCModelShown] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [mockTestUuid, setMockTestUuid] = useState("");

  const [quizQuestions, setQuizQuestions] = useState([]);
  const [currentQuestion, setCurrentQuestion] = useState(null);
  const [questionIndex, setQuestionIndex] = useState(1);
  const [progress, setProgress] = useState(0);
  const [isCorrect, setIsCorrect] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [correctAnswer, setCorrectAnswer] = useState(null);
  const [submitted, setSubmitted] = useState(false);
  const [isModelQuizShown, setIsModelQuizShown] = useState(false);
  const [isModelLeaderboardShown, setIsModelLeaderboardShown] = useState(false);
  const [leaderboardData, setLeaderboardData] = useState([]);

  const [testMinutes, setTestMinutes] = useState("--");
  const [testSeconds, setTestSeconds] = useState("--");

  const [activeTabData, setActiveTabData] = useState("All");

  const dispatch = useDispatch();

  const yourText = props.reduxCurrentState.one.nextClass.class_title;
  const textParts = yourText.split(":");

  const {
    changeBackground,
    changePrimaryColor,
    changeSecondaryColor,
    chnageSidebarColor,
    chnageHaderColor,
    changeNavigationHader,
  } = useContext(ThemeContext);

  const darkModeData = useSelector((state) => state.one.darkMode);

  const reduxStateData = useSelector((state) => state.one.mockTests);

  useEffect(() => {
    changeBackground({
      value: props.reduxCurrentState.one.darkMode ? "dark" : "light",
      label: "Dark",
    });

    changePrimaryColor("color_11");
    changeSecondaryColor("color_1");
    chnageSidebarColor(
      props.reduxCurrentState.one.darkMode ? "dark" : "color_11"
    );
    changeNavigationHader(
      props.reduxCurrentState.one.darkMode ? "dark" : "color_11"
    );

    const updateTime = () => {
      if (!props.reduxCurrentState.one.mockTests.upcomingMockTests) {
        return;
      }
      const newYear =
        props.reduxCurrentState.one.mockTests.upcomingMockTests[0].start_at;

      const targetDate = new Date(newYear);
      targetDate.setTime(targetDate.getTime());

      const currentDate = new Date();
      const diff = targetDate - currentDate;
      if (diff <= 0) {
        clearInterval(interval);
        setDays("00");
        setHours("00");
        setMinutes("00");
        setSeconds("00");
        return;
      }

      const days = Math.floor(diff / (1000 * 60 * 60 * 24));
      const hours = Math.floor(
        (diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((diff % (1000 * 60)) / 1000);

      setDays(days < 10 ? "0" + days : days);
      setHours(hours < 10 ? "0" + hours : hours);
      setMinutes(minutes < 10 ? "0" + minutes : minutes);
      setSeconds(seconds < 10 ? "0" + seconds : seconds);
    };

    const interval = setInterval(updateTime, 1000);

    return () => clearInterval(interval);
  }, [reduxStateData, darkModeData]);

  useEffect(() => {
    dispatch(getAssignments(props.reduxCurrentState.one.webAuthTokenId));
  }, []);

  const MockTestComponent = ({ assignments }) => {
    return (
      <div>

        {assignments.map((item, ind) => (
          <div className="card instructors-box">
            <div
              key={ind}
              style={{
                // border: "1px solid #ccc",
                borderRadius: "8px",
                marginBottom: "15px",
                padding: "15px",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                borderColor: item.cardClass === "upcoming" ? "#63c2de" : "#ccc",
              }}
            >
              <div style={{ flexGrow: 1, marginLeft: "15px" }}>
                <div className="card-body text-center pb-3">
                  <div className="instructors-media">
                    <div className="instructors-media-info">
                      <h3>{item.title}</h3>
                      <p>
                        <strong>Description:</strong> {item.description} |{" "}
                      </p>
                      <br></br>
                      <div className="d-flex justify-content-center my-0">
                        <div className="info-box">
                          <span>
                            <img src={medal} alt="" /> Submissions
                          </span>
                          <h4>10</h4>
                        </div>
                        <div className="info-box">
                          <span>
                            <img src={medal} alt="" /> Reward
                          </span>
                          <h4>10 Coins</h4>
                        </div>

                        <div className="info-box">
                          <span>
                            <img src={medal} alt="" /> Level
                          </span>
                          <h4>Easy</h4>
                        </div>
                        {/* <div className="info-box">
                          <span>
                            <img src={acheiv} alt="" /> Batch
                          </span>
                          <h4>All</h4>
                        </div> */}
                      </div>
                    </div>
                  </div>
                </div>
                <br></br>

                <div className="Row">
                  <div className="card-footer pt-0 border-0">
                    <div
                      className="row"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <div className="col-md-4">
                        <Link
                          // onClick={() =>
                          //   onPressViewHtmlCode(item.html_link)
                          // }
                          className="btn btn-primary btn-block"
                        >
                          View All Submissions
                        </Link>
                      </div>
                      <div className="col-md-4">
                        <a
                          href={item.kaggle_link}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="btn btn-info btn-block"
                        >
                          Submit My Assignment
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-end",
                }}
              >
                <button
                  // onClick={() => onPressViewMyScore(item.my_score_data)}
                  style={{
                    width: "150px", // Set a fixed width for both buttons
                    backgroundColor: "#000",
                    color: "#fff",
                    padding: "5px 10px",
                    borderRadius: "5px",
                    marginBottom: "5px",
                  }}

                  // onClick={() => handleViewScore(item)}
                >
                  View My Score
                </button>
                <button
                  style={{
                    width: "150px", // Set a fixed width for both buttons
                    backgroundColor: "#000",
                    color: "#fff",
                    padding: "5px 10px",
                    borderRadius: "5px",
                  }}
                  // onClick={() => handleViewLeaderboard(item.leaderboard_data)}
                >
                  View Leaderboard
                </button>
              </div>
            */}
            </div>
          </div>
        ))}
      </div>
    );
  };

  const handleViewLeaderboard = (leaderboardData) => {
    setIsModelLeaderboardShown(true);
    setLeaderboardData(leaderboardData);
  };

  const counterStyle = {
    display: "flex",
    border: "2px solid rgba(0,0,0,1)",
    padding: "10px",
    borderRadius: 10,
    borderColor: "#b69926",
  };

  const boxStyle = {
    width: "80px",
    height: "80px",
    textAlign: "center",
    marginRight: "10px",
    position: "relative",
  };

  const h2Style = {
    fontSize: "2.5rem",
    fontWeight: "500",
    color: "#b69926",
  };

  return (
    <>
      <div className="row">
        <div className="col-xl-6 col-xl-12">
          <div className="card">
            <div className="card-body">
              <div className="widget-courses align-items-center d-flex flex-wrap">
                <div className="d-flex  align-items-center flex-wrap flex-1 justify-content-center">
                  <div>
                    <div
                      style={{
                        display: "flex",
                        borderRadius: "10px",
                        overflow: "hidden",
                      }}
                    >
                      <div
                        className={
                          activeTabData === "All"
                            ? "btn btn-primary "
                            : "btn bg-dark"
                        }
                        style={{
                          padding: "10px 20px",
                          cursor: "pointer",
                          // backgroundColor: activeTab === 1 ? "#e83e8c" : "#fff",
                          color: "#fff",
                          borderRadius: "20px 20px 20px 20px",
                          transition: "background-color 0.3s ease",
                          marginRight: "10px",
                        }}
                        // onClick={() => handleTabMlClick("Batch 1")}
                      >
                        All Assignements
                      </div>
                      <div
                        className={
                          activeTabData === "My"
                            ? "btn btn-primary "
                            : "btn bg-dark"
                        }
                        style={{
                          padding: "10px 20px",
                          cursor: "pointer",
                          // backgroundColor: activeTab === 2 ? "#3498db" : "#fff",
                          color: "#fff",
                          borderRadius: "20px 20px 20px 20px",
                          transition: "background-color 0.3s ease",
                          marginRight: "10px",
                        }}
                        // onClick={() => handleTabMlClick("Batch 2")}
                      >
                        My Assignements
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-xl-12">
          {props.reduxCurrentState.one.assignments.length > 0 && (
            <MockTestComponent
              assignments={props.reduxCurrentState.one.assignments}
            />
          )}
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    reduxCurrentState: state,
  };
};

export default connect(mapStateToProps)(Assignments);
