import React, { useContext, useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import loadable from "@loadable/component";
import pMinDelay from "p-min-delay";
import { Dropdown } from "react-bootstrap";
import { connect, useDispatch, useSelector } from "react-redux";
import { getProjects } from "../../../store/actions/AuthActions";

import DonutChart from "./DonutChart";
//import ProfileActivityChart from './Dashboard/ProfileActivityChart';

//images
import pic2 from "./../../../images/courses/pic2.jpg";
import cup from "./../../../images/svg/cup.svg";
import puzzle from "./../../../images/svg/puzzle.svg";
import planet from "./../../../images/svg/planet.svg";
import skill from "./../../../images/svg/skill.svg";
import readingtime from "./../../../images/svg/readingtime.svg";
import certificate from "./../../../images/svg/degree-certificate.svg";
import clock from "./../../../images/svg/clock-1.svg";

import acheiv from "./../../../images/svg/achievement.svg";
import medal from "./../../../images/medal.png";
import pic3 from "./../../../images/courses/pic3.jpg";
import pic4 from "./../../../images/courses/pic4.jpg";
import pic5 from "./../../../images/courses/pic5.jpg";
import pic6 from "./../../../images/courses/pic6.jpg";
import pic7 from "./../../../images/courses/pic7.jpg";
import pic8 from "./../../../images/courses/pic8.jpg";

const instructorsData = [
  {
    title: "Madhu Sanjeevi (Mady)",
    image:
      "https://degenden-academy.s3.ap-south-1.amazonaws.com/webapp_assets/pfps/IMG_3CD19B7AA206-1.jpeg",
    rating: "4.3",
    programs: ["Data Science & AI", "Generative AI"],
    classes: 261,
    batches: 8,
  },
  {
    title: "Dharna Ahuja",
    image:
      "https://degenden-academy.s3.ap-south-1.amazonaws.com/webapp_assets/pfps/Dharna.png",
    rating: "4.0",
    programs: ["SQL", "Data Science & AI"],
    classes: 15,
    batches: 1,
  },
];

const ProfileActivityChart = loadable(() =>
  pMinDelay(import("./ProfileActivityChart"), 1000)
);

const WidgetBlog = ({ changeImage, title, number }) => {
  return (
    <>
      <div className="col-xl-6 col-lg-6 col-sm-6">
        <div className="card profile-widget">
          <div className="card-body">
            <div className="widget-courses align-items-center d-flex justify-content-between style-1 flex-wrap">
              <div className="d-flex">
                <img src={changeImage} alt="" />
                <div className="ms-4">
                  <h4>{number}</h4>
                  <span>{title}</span>
                </div>
              </div>
              <Link>
                <i className="las la-angle-right text-primary"></i>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const CurrentCourse = ({ bg1, changeClass, title, percent, numb1, numb2 }) => {
  return (
    <>
      <div className="col-xl-6 col-sm-6">
        <div className="card">
          <div className="card-body">
            <div className="students1">
              <div className="d-inline-block position-relative donut-chart-sale me-4">
                <DonutChart
                  className="donut1"
                  value={percent}
                  backgroundColor={bg1}
                  backgroundColor2="rgba(245, 245, 245, 1)"
                />
                <small className={changeClass}>{percent}%</small>
              </div>
              <div className="">
                <span>Program</span>
                <h4 className="fs-18 mb-3">{title}</h4>
                <span>Total Classes</span>
                <h5 className="fs-18">
                  {numb1} / {numb2}
                </h5>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const projectTypes = ["EDA", "DL"];
const randomProjectType =
  projectTypes[Math.floor(Math.random() * projectTypes.length)];

const Profile = (props) => {
  const [dropSelect, setDropSelect] = useState("This Month");

  const [activeProjectTab, setActiveProjectTab] = useState(randomProjectType);

  const [showPlayIcon, setShowPlayIcon] = useState(true);

  const [showProfile, setShowProfile] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getProjects(props.reduxCurrentState.one.webAuthTokenId));
  }, []);

  const renderProfile = () => {
    return (
      <>
        <div className="d-flex align-items-center">
          <p onClick={() => setShowProfile(false)} style={underlineStyle1}>
            Go Back
          </p>
        </div>
        <div className="row">
          <div className="col-xl-4 col-xxl-5 col-lg-12">
            <div className="card instructors-box">
              <div className="card-body text-center pb-3">
                <div className="instructors-media">
                  <img
                    src={
                      "https://degenden-academy.s3.ap-south-1.amazonaws.com/webapp_assets/pfps/IMG_3CD19B7AA206-1.jpeg"
                    }
                    style={{ width: 220, height: 220, borderRadius: "50%" }}
                    alt=""
                  />
                  <div className="instructors-media-info mt-4">
                    <h4 className="mb-1">Madhu Sanjeevi (Mady)</h4>
                    <span className="fs-18">DS & AI Trainer</span>
                    <div className="d-flex justify-content-center my-3 mt-4">
                      <div className=" justify-content-center info-box text-start style-1">
                        <span className="d-flex justify-content-center">
                          Batches
                        </span>
                        <h4 className="d-flex justify-content-center">8</h4>
                      </div>
                      <div className="info-box text-start style-1">
                        <span className="d-flex justify-content-center">
                          Rating
                        </span>
                        <h4 className="d-flex justify-content-center">4.3</h4>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="bio text-start my-4">
                  <h4 className="mb-3">Bio</h4>
                  <div className="bio-content">
                    <p>
                      Mady, came from CS background, worked as software engineer
                      for several years in field of Data Science & Artificial
                      Intelligence.
                    </p>
                    <p className="mb-0">
                      Currently, he is passionate to train people in AI, he has
                      done more than 8 batches here in the den & trained more
                      than 100 people.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-8 col-xxl-7 col-lg-12 ">
            <div className="row">
              <WidgetBlog
                changeImage={clock}
                number="257"
                title="Total Classes"
              />
              <WidgetBlog
                changeImage={certificate}
                number="70"
                title="Total Projects"
              />
              <div className="widget-heading d-flex justify-content-between align-items-center">
                <h3 className="m-0">Programs Taught</h3>
              </div>

              <CurrentCourse
                changeclassName="text-secondary"
                bg1="rgba(76, 188, 154, 1)"
                title="Data Science & AI"
                percent="77"
                numb1="200"
                numb2="257"
              />

              <CurrentCourse
                changeclassName="text-warning"
                bg1="rgba(254, 198, 79, 1)"
                title="Generative AI"
                percent="23"
                numb1="57"
                numb2="257"
              />

              <div className="col-xl-12">
                <div className="card score-active style-1">
                  <div className="card-header border-0 pb-2 flex-wrap">
                    <h4 className="me-4">Score Activity</h4>
                    <ul className="d-flex mb-2">
                      <li>
                        <svg
                          className="me-2"
                          width="12"
                          height="12"
                          viewBox="0 0 12 12"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <rect
                            x="1.5"
                            y="1.5"
                            width="9"
                            height="9"
                            rx="4.5"
                            fill="white"
                            stroke="#FEC64F"
                            strokeWidth="3"
                          />
                        </svg>
                        Last Month
                      </li>
                      <li>
                        <svg
                          className="me-2"
                          width="12"
                          height="12"
                          viewBox="0 0 12 12"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <rect
                            x="1.5"
                            y="1.5"
                            width="9"
                            height="9"
                            rx="4.5"
                            fill="white"
                            stroke="#4CBC9A"
                            strokeWidth="3"
                          />
                        </svg>
                        Last Month
                      </li>
                    </ul>
                    <div className="d-flex align-items-center">
                      <Dropdown className="select-dropdown me-2">
                        <Dropdown.Toggle
                          as="div"
                          className="i-false dashboard-select  selectBtn btn-dark"
                        >
                          {dropSelect}{" "}
                          <i className="fa-solid fa-angle-down ms-2" />
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          <Dropdown.Item
                            onClick={() => setDropSelect("This Month")}
                          >
                            This Month
                          </Dropdown.Item>
                          <Dropdown.Item
                            onClick={() => setDropSelect("This Weekly")}
                          >
                            This Weekly
                          </Dropdown.Item>
                          <Dropdown.Item
                            onClick={() => setDropSelect("This Day")}
                          >
                            This Day
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </div>
                  <div className="card-body pb-1 custome-tooltip pt-0">
                    <ProfileActivityChart />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* sample videos */}

        <div className="row">
          <div className="row">
            <div className="col-xl-6 col-xl-12">
              <div className="card">
                <div className="card-body">
                  <div className="widget-courses align-items-center d-flex flex-wrap">
                    <div className="d-flex  align-items-center flex-wrap flex-1 justify-content-center">
                      <div>
                        <div
                          style={{
                            display: "flex",
                            borderRadius: "10px",
                            overflow: "hidden",
                          }}
                        >
                          <div
                            className={
                              activeProjectTab === "EDA"
                                ? "btn btn-primary "
                                : "btn bg-dark"
                            }
                            style={{
                              padding: "10px 20px",
                              cursor: "pointer",
                              // backgroundColor: activeTab === 2 ? "#3498db" : "#fff",
                              color: "#fff",
                              borderRadius: "20px 20px 20px 20px",
                              transition: "background-color 0.3s ease",
                              marginRight: "10px",
                            }}
                            onClick={() => handleProjectTab("EDA")}
                          >
                            Mobile Bytes
                          </div>

                          <div
                            className={
                              activeProjectTab === "DL"
                                ? "btn btn-primary "
                                : "btn bg-dark"
                            }
                            style={{
                              padding: "10px 20px",
                              cursor: "pointer",
                              // backgroundColor: activeTab === 2 ? "#3498db" : "#fff",
                              color: "#fff",
                              borderRadius: "20px 20px 20px 20px",
                              transition: "background-color 0.3s ease",
                              marginRight: "10px",
                            }}
                            onClick={() => handleProjectTab("DL")}
                          >
                            Desktop Bytes
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {!props.reduxCurrentState.one.projectLoading && (
            <div
              class="spinner-border"
              style={{
                flex: 1,
                alignItems: "center",
                alignContent: "center",
                justifyContent: "center",
                justifyItems: "center",
              }}
            ></div>
          )}

          {activeProjectTab === "DL" && (
            <>
              {props.reduxCurrentState.one.trainerDVideos &&
                props.reduxCurrentState.one.trainerDVideos.map(
                  (item, index) => (
                    <div className="col-xl-4 col-xxl-6 col-md-6" key={index}>
                      <div className="card instructors-box">
                        <div className="card-body text-center pb-3">
                          {/* Video Player */}
                          <div style={{ position: "relative" }}>
                            <video
                              style={{
                                borderRadius: "10px",
                                width: "100%",
                                height: "auto",
                              }}
                              controls
                              controlsList="nodownload"
                              poster={item.thumbnail}
                              onPlay={(e) => {
                                e.currentTarget.nextSibling.style.display =
                                  "none";
                              }}
                              onPause={(e) => {
                                e.currentTarget.nextSibling.style.display =
                                  "block";
                              }}
                            >
                              <source src={item.video_link} type="video/mp4" />
                              Your browser does not support the video tag.
                            </video>

                            {showPlayIcon && (
                              <div style={videoPlayOverlayStyle}>
                                <span style={videoPlayIconStyle}>
                                  <VideoPlayButton />
                                </span>{" "}
                              </div>
                            )}
                          </div>

                          {/* Instructor Info */}
                          <div className="instructors-media">
                            <div className="instructors-media-info">
                              <h4></h4>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                )}
            </>
          )}

          {activeProjectTab == "EDA" && (
            <>
              {props.reduxCurrentState.one.trainerMVideos &&
                props.reduxCurrentState.one.trainerMVideos.map(
                  (item, index) => (
                    <div className="col-xl-4 col-xxl-4 col-md-4" key={index}>
                      <div className="card instructors-box">
                        <div className="card-body text-center pb-3">
                          <div style={{ position: "relative" }}>
                            <video
                              style={{
                                borderRadius: "10px",
                                width: "100%",
                                height: "auto",
                              }}
                              controls
                              controlsList="nodownload"
                              poster={item.thumbnail}
                              onPlay={(e) => {
                                e.currentTarget.nextSibling.style.display =
                                  "none";
                              }}
                              onPause={(e) => {
                                e.currentTarget.nextSibling.style.display =
                                  "block";
                              }}
                            >
                              <source src={item.video_link} type="video/mp4" />
                              Your browser does not support the video tag.
                            </video>

                            {showPlayIcon && (
                              <div style={videoPlayOverlayStyle}>
                                <span style={videoPlayIconStyle}>
                                  <VideoPlayButton />
                                </span>{" "}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                )}
            </>
          )}
        </div>
      </>
    );
  };

  const underlineStyle1 = {
    textDecoration: "underline",
    cursor: "pointer",
    color: "green",
  };

  const renderInstructors = () => {
    return (
      <>
        <div className="row">
          {instructorsData.map((item, index) => (
            <div className="col-xl-4 col-xxl-6 col-md-6" key={index}>
              <div className="card instructors-box">
                <div className="card-body text-center pb-3">
                  <div className="instructors-media">
                    <img
                      src={item.image}
                      style={{ width: 220, height: 220, borderRadius: "50%" }}
                      alt=""
                    />
                    <br></br>
                    <div className="instructors-media-info">
                      <h4>{item.title}</h4>
                      <ul className="d-flex align-items-center raiting my-0 justify-content-center">
                        <li>
                          <span className="font-w500">{item.rating}</span>
                          <i className="fas fa-star text-orange ms-2"></i>
                        </li>
                      </ul>
                      <div className="custome-badge">
                        <Link to={"#"}>
                          <span className="badge badge-xl">
                            {item.programs[0]}
                          </span>
                        </Link>
                        <Link to={"#"}>
                          <span className="badge badge-xl">
                            {item.programs[1]}
                          </span>
                        </Link>
                      </div>
                      <div className="d-flex justify-content-center my-3">
                        <div className="info-box">
                          <span>
                            <img src={medal} alt="" /> Total Classes
                          </span>
                          <h4>{item.classes}</h4>
                        </div>
                        <div className="info-box">
                          <span>
                            <img src={acheiv} alt="" /> Total Batches
                          </span>
                          <h4>{item.batches}</h4>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card-footer pt-0 border-0">
                  <Link
                    onClick={() => setShowProfile(true)}
                    className="btn btn-secondary  btn-block"
                  >
                    View Profile
                  </Link>
                </div>
              </div>
            </div>
          ))}
        </div>
      </>
    );
  };

  const VideoPlayButton = () => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100"
      height="100"
      viewBox="0 0 100 100"
    >
      <circle
        cx="50"
        cy="50"
        r="40"
        stroke="white"
        strokeWidth="2"
        fill="transparent"
      />
      <polygon points="35,25 35,75 75,50" fill="white" />
    </svg>
  );

  const handleProjectTab = (tabNumber) => {
    setActiveProjectTab(tabNumber);
  };

  const videoPlayOverlayStyle = {
    position: "absolute",
    top: "45%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    pointerEvents: "none",
  };

  const videoPlayIconStyle = {
    fontSize: "40px", // Adjust the size and style as needed
  };

  return (
    <>{showProfile ? <>{renderProfile()}</> : <>{renderInstructors()} </>}</>
  );
};

const mapStateToProps = (state) => {
  return {
    reduxCurrentState: state,
  };
};

export default connect(mapStateToProps)(Profile);
